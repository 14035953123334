#m-a-main-pg-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    background: none;
    border: none;
}
#m-a-payments-pg-container, #m-a-payWait-pg-container, #m-a-depositWait-pg-container, #m-a-buyItem-pg-container, #m-a-cancelItem-pg-container, #m-a-review-pg-container
,#m-a-notice-pg-container, #m-a-faq-pg-container, #m-a-userinfo-pg-container, #m-a-depositSet-pg-container, #m-a-employee-pg-container
, #m-a-salesSt-pg-container, #m-a-user-service-pg-container, #m-a-traffic-pg-container, #m-a-divide-modal-container, #m-a-merge-modal-container, #m-a-reg-modal-container
, #m-a-rv-modi-pg-container, #m-a-noticeDt-pg-container, #m-a-faqDt-pg-container, #m-a-faq-modi-pg-container, #m-a-ordDt-modal-container, #m-a-alarm-pg-container {
    min-height: 100vh;
    max-width: 100vw;
    min-width: 100%;
}
#m-a-Info-modi-pg-container, #m-a-divide-modal-container, #m-a-merge-modal-container, #m-a-reg-modal-container, #m-a-rv-modi-pg-container, #m-a-faq-modi-pg-container
, #m-a-ordDt-modal-container {
    background-color: var(--m-a-000-and-fff-background-color);
}
.m-a-header-container {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--sidebar-background-color);
    z-index: 500;
    border: none;
}
.m-a-header-container,
.m-a-footer-container {
    pointer-events: auto;
}
.m-a-header-in {
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.m-a-header-container .m-a-header-in {
    position: static;
    display: flex;
    height: 50px;
    width: 100%;

    /*기존 로고가 센터*/
    /*text-align: center;*/
}
.m-a-header-container h1 {
    /*display: inline-block;*/
    /*position: relative;*/
    /*vertical-align: top;*/
    /*z-index: 100;*/
}
.m-a-header-burger {
    display: flex;
    justify-content: end;
    width: 15%;
    padding-right: 1px;

    /*기존 로고가 센터*/
    /*width: 20%;*/
    /*border: solid 1px red;*/
}
.m-a-header-in-logo {
    width: 70%;
    padding-left: 15px;

    /*기존 로고가 센터*/
    /*width: 60%;*/
    /*padding-left: 3px;*/
}
.m-a-header-logo {
    width: 121px;
    height: 26px;

    /*기존 로고가 센터*/
    /*width: 18px;*/
    /*height: 27px;*/
}
.m-a-header-profile {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 15%;

    /*기존 로고가 센터*/
    /*justify-content: center;*/
}
.m-a-header-profile-box {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    overflow: hidden;
}
.m-a-header-profile-box-userinfo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}
.m-a-header-profile-box-b {
    border-radius: 50%;
    overflow: hidden;
}
.m-a-header-profile-box-c {
    overflow: hidden;
}
.m-a-header-profile-box-img {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-style: none;
}
.m-a-body-container {
    display: block;
    text-align: center;
    margin-top: 50px;
    padding: 15px;
    height: auto;
    min-height: 100%;
    background-color: var(--m-a-body-container-background-color);
    border: none;
}
.m-a-body-container-noPadding {
    min-height: 100vh;
}
.m-a-footer-container {
    display: block;
    border-top: var(--dark-task-border-top);
    background-color: var(--m-a-000-and-fff-background-color);
    height: 34px;
}
.m-a-card {
    display: flex;
    width: 100%;
    background-color: var(--m-a-card-background-color);
    padding: 13px;
    border: none;
    border-radius: 10px;
    box-shadow: var(--m-a-card-box-shadow);
}
.m-a-card-none-pd {
    display: flex;
    width: 100%;
    background-color: var(--m-a-card-background-color);
    border: none;
    border-radius: 10px;
    box-shadow: var(--m-a-card-box-shadow);
}
.m-a-card-shadow {
    box-shadow: var(--m-a-card-box-shadow);
    border-radius: 10px;
}
.m-a-card:active {
    box-shadow: var(--m-a-card-active-box-shadow);
}
.m-a-top-border-card {
    width: 100%;
    background-color: var(--m-a-card-background-color);
    padding: 20px;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: var(--m-a-card-box-shadow);
}
.m-a-top-border-card:active {
    box-shadow: var(--m-a-card-active-box-shadow);
}
/*햄버거*/
.toggle {
    position: absolute;
    display: block;
    z-index: 10000;
}
.toggle span {
    display: block;
    /*background: #5b5b5b;*/
    background: #fff;
    border-radius: 1px;
    transition: 0.15s margin 0.15s, transform 0.15s;
}
.toggle span:nth-child(1) {
    width: 26px;
    margin-bottom: 6px;

    /*기존 로고가 센터*/
    /*width: 18px;*/
}
.toggle span:nth-child(3) {
    width: 26px;
    margin-top: 6px;

    /*기존 로고가 센터*/
    /*width: 24px;*/
}
.toggle.active span {
    transition: 0.2s margin, 0.2s transform 0.2s;
}
.toggle.active span:nth-child(1) {
    width: 26px;
    margin-top: 10px;
    margin-bottom: -2px;
    transform: rotate(45deg);

    /*기존 로고가 센터*/
    /*width: 30px;*/
}
.toggle.active span:nth-child(2) {
    opacity: 0;
}
.toggle.active span:nth-child(3) {
    width: 26px;
    margin-top: -2px;
    transform: rotate(135deg);

    /*기존 로고가 센터*/
    /*width: 30px;*/
}
/*햄버거*/

/*슬라이드메뉴*/
body.menu-open {
    overflow: hidden;
}
.no-scroll {
    overflow: hidden;
}

.m-a-slider-ctg-container {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--m-a-slider-ctg-container-background-color);
    transition: left 0.33s ease-in-out;
    z-index: 9999;
    /*overflow-y: auto;*/
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 50px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    /*display: none;*/
    /*transition: opacity 0.3s ease;*/
}
.m-a-slider-flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*gap: 10px;*/
}
@keyframes slideIn {
    0% {
        left: 100%;
    }
    60% {
        left: 0;
    }
    80% {
        left: 15px;
    }
    100% {
        left: 0;
    }
}
@keyframes slideOut {
    0% {
        left: 0;
    }
    20% {
        left: 0;
    }
    40% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}
.m-a-slider-ctg-container.open {
    /*display: block;*/
    opacity: 1;
    left: 0;
    animation: slideIn 0.58s ease-in-out forwards;
    pointer-events: auto;
}
.m-a-slider-ctg-container.close {
    /*display: none;*/
    left: 100%;
    opacity: 0;
    transition: opacity 0.66s ease;
    animation: slideOut 0.22s ease-in forwards;
    pointer-events: none;
}
.m-a-slider-ctg-container div {
    color: #fff;
}
.m-a-slider-ctg-container ul {
    width: 40%;
    list-style: none;
    text-align: left;
}
.m-a-slider-ctg-container ul li {
    list-style: none;
    color: #fff;
    padding: 4px 0 4px 0;
}
.m-a-ctg-icon {
    color: #fff;
    font-size: 25px;
    /*position: absolute;*/
    /*left: 48px;*/
    /*margin-top: 10px;*/
    /*padding-top: 5px;*/
}

.m-a-slider-profile-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--m-a-slider-profile-container-background-color);
    transition: left 0.27s ease-in-out;
    z-index: 10022;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
}
.m-a-slider-profile-frame {
    position: relative;
    min-height: 100%;
}
.m-a-slider-profile-frame-1 {
    display: flex;
    flex-direction: column;
}
.m-a-slider-profile-frame-2 {
    /*position: absolute;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*bottom: 0;*/
    padding: 5px;

}
@keyframes slideTop {
    0% {
        top: -120%;
    }
    60% {
        top: 0;
    }
    80% {
        top: -15px;
    }
    100% {
        top: 0;
    }
}
@keyframes slideBottom {
    0% {
        top: 0;
    }
    20% {
        top: 0;
    }
    40% {
        top: 0;
    }
    100% {
        top: -120%;
    }
}
.m-a-slider-profile-container.open {
    top: 0;
    animation: slideTop 0.59s ease-in-out forwards;
}
.m-a-slider-profile-container.close {
    animation: slideBottom 0.25s ease-in forwards;
}
.m-a-close-button {
    float: right;
    padding: 13px 7px 0 0;
    outline: none;
    border: none;
}
/*슬라이드메뉴*/
.m-a-s-profile1, .m-a-s-profile2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 48px;
}
.m-a-s-profile3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.m-a-s-p-profile {
    display: flex;
    flex-direction: row;
}
.m-a-s-p-frame-3 {
    display: flex;
    flex-direction: column;
}
.m-a-s-p-f3-1 {

}
.m-a-s-p-f3-2 {
    /*padding: 5px;*/
}
.m-a-s-p-f3-3 {
    /*padding: 5px;*/
}
.m-a-s-p-f3-2 div, .m-a-s-p-f3-3 div {
    /*text-align: left;*/
}
.m-a-total-chat-frame {
    position: relative;
}
.m-a-total-chat-count {
    position: absolute;
    left: 15px;
    top: 8px;
    background-color: #FF6324;
    color: white;
    height: 15px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding: 0 6px;
}
.m-a-title-frame {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    text-align: left;
    font-weight: bold;
    font-family: LeferiPoint-BlackA, sans-serif;
    font-size: 19px;
    color: var(--contents-color);
}

/*테이블*/
.m-a-tb-frame {
    width: 100%;
    /*height: auto;*/
    overflow-x:auto;
}
.m-a-tb-frame-b {
    width: 100%;
    overflow-x: hidden;
    overflow-y:auto;
}
.m-a-div-tb {
    display: table;
    width: 100%;
    border: var(--dark-task-border);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: none;
}
.m-a-div-tb-head, .m-a-div-tb-head-b {
    display: flex;
    align-items: center;
    width: 100%;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: var(--m-a-div-tb-head-background-color);
    border-bottom: var(--dark-task-border-bottom);
    font-family: LINESeedKR-Bd, sans-serif;
    color: #5b5b5b;
}
.m-a-div-tb-body, .m-a-div-tb-body-b {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: var(--dark-task-border-bottom);
    background-color: var(--m-a-000-and-fff-background-color);
    transition: .25s;
    font-family: Pretendard-Regular, sans-serif;
    color: #5b5b5b;
}

.m-a-div-tb-head div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;

    /*border: solid 1px red;*/
}
.m-a-div-tb-head-b div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    /*border: solid 1px red;*/
}
.m-a-div-tb-body div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    /*border: solid 1px blue;*/
}
.m-a-div-tb-body-b div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    /*border: solid 1px blue;*/
}
.m-a-div-tb-head div p {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}
.m-a-div-tb-body div p {
    font-size: 14px;
    margin: 0 auto;
    color: var(--contents-color);
}
.m-a-div-tb-body div a {
    font-size: 14px;
    margin: 0 auto;
    color: var(--contents-color);
}
.m-a-div-tb-head-b div p {
    /*font-size: 12px;*/
    font-weight: bold;
    margin: 0;
}
.m-a-div-tb-body-b div p {
    /*font-size: 12px;*/
    margin: 0 auto;
    color: var(--contents-color);
}
.m-a-div-tb-body-b div a {
    /*font-size: 12px;*/
    margin: 0 auto;
    color: var(--contents-color);
}
/*테이블*/

.m-a-page-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--m-a-common-popup-container-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}
.m-a-page-popup-frame {
    width: 90%;
    background: var(--m-a-000-and-fff-background-color);
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transform: translateY(-20px);
    transition: transform 0.2s ease, opacity 0.2s ease;
    opacity: 0;
    animation: selectSlideIn 0.3s forwards;
}
@keyframes selectSlideIn {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
.m-a-page-popup-header-frame {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--m-a-common-popup-header-frame-background-color);
}
.m-a-page-popup-body-frame {
    padding: 10px 30px 10px 30px;
    max-height: 380px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.m-a-page-popup-body-frame p{
    margin: 0 auto;
}
.m-a-page-popup-footer-frame {
    padding-top: 5px;
    align-items: center;
    height: 70px;
    display: flex;
    justify-content: center;
}
.m-a-selected-option {
    border: 2px solid #FFAF34;
    border-radius: 10px;
}
.m-a-tabs {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--m-a-card-background-color);
}
.m-a-tab {
    width: 25%;
    height: 40px;
    color: var(--contents-color);
    outline: none;
    background: none;
    text-align: center;
    flex: 1;
    padding: 2px 0;
    cursor: pointer;
    transition: color 0.4s;
}
.m-a-tab.active {
    color: var(--btorage-color);
}
.m-a-tab-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25%;
    height: 2px;
    background-color: #FFAF34;
    transition: left 0.4s;
}
.m-a-deposit-set-frame {
    padding-bottom: 10px;
    border-bottom: var(--dark-task-border-bottom);
    margin-bottom: 45px;
}
.m-a-cp-slider {
    width: 100%;
    overflow: hidden;
    position: relative;
    outline: none;
    background: none;
    /*border-radius: 4px;*/
    border-bottom: var(--dark-task-border-bottom);
}
.m-a-open-bar {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--m-a-card-background-color);
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: var(--m-a-card-box-shadow);
    transition: 0.3s;
}
.m-a-open-bar-pc:hover {
    background-color: #f3f3f3;
}
.m-a-open-bar:active {
    box-shadow: var(--m-a-card-active-box-shadow);
}
.m-a-ord-accordion-icon {
    width: 18px;
    height: 18px;
    background-image: url('https://cdn-icons-png.flaticon.com/512/2626/2626997.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: var(--img-color-filter);
    transition: transform 0.5s;
}
.m-a-text-colored {
    color: #FFAF34;
}
.m-a-reg-box-price-top {
    border-top: var(--dark-task-border-top);
}
.m-a-reg-box-price {
    border-bottom: var(--dark-task-border-bottom);
}
.m-a-reg-box-price > div {
    height: 30px;
    align-items: center;
    /*border: solid 1px red;*/
}
