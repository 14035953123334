/********** top start **********/

.status-card {
    display: flex;
    flex-direction: column;
    min-width: 220px;
    text-align: center;
    padding: 0 30px;
}
.card-img-warp {
    height: 90px;
    padding-top: 15px;
    margin-bottom: 2px;
}
.status-card > div > div > a {
    font-size: 14px;
    cursor: default;
}
.status-card-icon {
    font-size: 70px;
    color: #5b5b5b;
    text-align: center;
}
.status-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 0 5px;
    border-radius: 8px;
    cursor: pointer;
}
.status-cont:hover {
    background-color: #FFF3DF;
}
.status-cont-long {
    margin-bottom: -6px;
}
.dash-header {
    margin-top: 1.5rem;
    margin-bottom: 2.3rem;
}
.status-board-line {
    display: flex;
    justify-content: center;
    vertical-align: middle;
}
/*.parcel-st {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: start;*/
/*    align-items: center;*/
/*    width: 140px;*/
/*    margin: 1rem 1rem 1rem 1rem;*/
/*    padding: 1rem 0.8rem 0.8rem 0.8rem;*/
/*    background-color: #f1f1f5;*/
/*    border-radius: 15px;*/
/*}*/
/*.parcel-st span {*/
/*    margin-top: 15px;*/
/*}*/
/*.parcel-st span b  {*/
/*    margin-left: 7px;*/
/*}*/
/*.parcel-st span b:hover {*/
/*    color: #FF7800;*/
/*}*/
/*.parcel-st p {*/
/*    font-size: 13px;*/
/*    line-height: 6px;*/
/*    cursor: default;*/
/*}*/
/*.parcel-st {*/
/*    cursor: default;*/
/*}*/
/*.parcel-st strong {*/
/*    cursor: default;*/
/*    margin-top: 0.7rem;*/
/*    margin-bottom: 0.1rem;*/
/*    align-items: center;*/
/*    font-size: 14px;*/
/*}*/

.ord-status-img-ord {
    filter: opacity(0.85);
}
.ord-status-img-ship {
    filter: opacity(0.9);
}
.ord-status-img-return {
    filter: opacity(0.9);
}

.admin-status-ord > div > div > b {
    font-size: 15px;
    cursor: default;
    color: var(--btorage-color);
    filter: opacity(0.85);
}
.admin-status-ship > div > div > b {
    font-size: 15px;
    cursor: default;
    /*color: var(--ship-color);*/
    color: var(--btorage-color);
    filter: opacity(0.9);
}
.admin-status-return > div > div > b {
    font-size: 15px;
    cursor: default;
    color: var(--return-color);
    filter: opacity(0.9);
}

.gbn-border {
    border-right: var(--dark-task-border-right);
}
/********** top end **********/


/********** middle start **********/

.dash-cont {
    margin-bottom: 2.3rem;
}
.status-board-work {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*flex-wrap: wrap;*/
    padding: 0.8rem;
}
.service-card {
    border: none;
    border-radius: 10px;
    box-shadow: var(--contents-box-dt-box-shadow);
}

.service-card:hover, .card-wrap-r:hover {
    box-shadow: var(--contents-box-dt-hover-box-shadow);
}
.board-lr {
    display: block;
}
.card-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-wrap-r {
    display: flex;
    flex-direction: column;
    /*width: 350px;*/
    /*height: 390px;*/
    box-sizing: border-box;
    box-shadow: var(--contents-box-dt-box-shadow);
    border: none;
}
.cooper-card-memo::-webkit-scrollbar {
    width: 6px;
}
.cooper-card-memo::-webkit-scrollbar-thumb  {
    background: rgba(171, 171, 171, 0.6);
    /*border-radius: 1px;*/
}
.cooper-card-memo::-webkit-scrollbar-track {
    background: rgb(173, 176, 185, 0.3);
}

.work-card {
    text-align: center;
    border-radius: 11px;
    border: 1px solid #dee2e6;
    background-color: #ffffff;
}
#memo-paper {
    width:341px;
    height:326px;
    resize:none;
    outline: none;
    border: none;
    background-color: #fffef6;
    font-size: 12px;
}
#memo-paper::-webkit-scrollbar {
    width: 8px;
    background: #fffef8;
}
#memo-paper::-webkit-scrollbar-thumb {
    background: #dee2e6;
    border-radius: 2px;
}
#memo-paper::-webkit-scrollbar-thumb:hover {
    background: #c6c7c9;
}
.work-card-order .card-header a {
    font-size: 22px;
}
.card-wrap-work .card-header a {
    font-size: 16px;
}
.work-card-notice .card-header a {
    font-size: 16px;
}
.work-card-task .card-header a {
    font-size: 16px;
}
.work-card-notice .card-header {
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    background-color: #FFAF34;
    padding: 3px 0 3px 0;
    color: #ffffff
}
.work-card-task .card-header {
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    background-color: #FFAF34;
    padding: 3px 0 3px 0;
}
.work-card:hover {
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
    opacity: 0.9;
}
.work-card-order {
    margin: 0.3rem 0 0.3rem 0;
    width: 410px;
    height: 85px;
}
.work-card-work {
    margin: 0.3rem 0 0.3rem 0;
    width: 295px;
    height: 110px;
}
.work-card-notice, .work-card-task {
    margin: 0.3rem 0 0.3rem 0;
    width: 346px;
    height: 369px;
    font-size: 12px;
}
.notice-text {
    width: 223px;
    font-size: 12px;
    height: 15px;
    outline: none;
    border: none
}
.notice-wrap {
    height: 290px;
    display: block;
    position: relative;
    overflow-x: hidden;
    overflow-y: initial;
}
.notice-wrap::-webkit-scrollbar {
    width: 8px;
    background: #fffef8;
}
.notice-wrap::-webkit-scrollbar-thumb {
    background: #dee2e6;
    border-radius: 2px;
}
.notice-wrap::-webkit-scrollbar-thumb:hover {
    background: #c6c7c9;
}
.notice-wrap-cont {
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: 3px;
    margin-bottom: 4px;
    border-bottom: 1px solid #dee2e6;
}
.notice-wrap-cont p {
    margin-bottom: -3px;
}
.cont-chk {
    margin-right: 10px;
}
.work-card-task {
    background-color: #fffefa;
}
.card-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding-top: 8px;
}
.card-body a {
    font-size: 14px;
    padding:0 0.4rem 0 0.4rem;
}
.card-body .sec-a {
    position: relative;
    border-left: 1px solid #dee2e6;
}
.card-body-work {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
}
.work-icon  {
    color: #3c3f49;
    margin-left: 77px;
}
.work-count {
    margin-right: 77px;
}
.work-count a {
    font-size: 38px;
    text-decoration: none;
    color: #1cc441 !important;
}
.badge-dash {
    display: inline-block;
    position: relative;
    margin-left: 5px;
    width: auto;
    height: 18px;
    border-radius: 0.25rem;
    background-color: var(--cooper-badge-background-color);
    bottom: 3px;
    padding: 0 3px;
}
.badge-dash p {
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
}

.real-text {
    animation-duration: 1.7s;
    animation-name: realtextcursor;
    animation-iteration-count: infinite;
}
@keyframes realtextcursor {
    0% { color: #ececec; }
    25% { color: #bcc7d2; }
    50% { color: #7193b9; }
    75% { color: #5283b9; }
    100% { color: #276ab7; }
}

/*@keyframes realtextcursor {*/
/*    0% { color: #fff9f3; }*/
/*    25% { color: #ffe4c0; }*/
/*    50% { color: #ffcd86; }*/
/*    75% { color: #ffb846; }*/
/*    100% { color: #FFAF34; }*/
/*}*/

/*@keyframes realtextcursor {*/
/*    0% { color: #c0c0c0; }*/
/*    25% { color: #a5bea7; }*/
/*    50% { color: #78c07d; }*/
/*    75% { color: #46be4c; }*/
/*    100% { color: #09bd12; }*/
/*}*/

.main-td-hover:hover {
    background: var(--main-td-hover-background);
}
/********** middle end **********/


/********** bottom start **********/
.status-board-order {
    margin: 1rem;
    /*padding: 0.8rem 0.8rem 0.8rem 0.8rem;*/
    background-color: #ffffff;
    border-radius: 5px;
}
.order-tab {
    width: 100%;
    /*margin-top: 0.3rem;*/
}
.tab {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #dcdcdc;
    border-top: none;
    padding-bottom: 7px;
}
.tab-wrap {
    display: block;
    position: relative;
}
.tab-st {
    display: flex;
    flex-direction: column;
    padding: 10px 15px 5px 10px;
    margin-bottom: 13px;
}
/*.st-menu {*/
/*    display: block;*/
/*    flex-direction: row;*/
/*    justify-content: start;*/
/*}*/
.st-menu-top span {
    margin-right: 10px;
}
.st-menu-top a {
    text-align: center;
}
.btn-ip1 {
    width: 50px;
}
.btn-ip2 {
    width: 105px;
}
.btn-ip3 {
    width: 120px;
}
.st-ctg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.st-ctg select {
    height: 30px;
}
.tg-0lax input:hover {
    background: #e5e7ea;
}
.tg-0lax .st-ctg {
    text-align: center;
}
.tab-ord-list {
    /*padding: 0 10px 0 10px;*/
}
.tb-order {
    display: flex;
    flex-direction: column;
}
.tb-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}
.tb-head th {
    border-top: 0.1rem solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 0.0856rem solid;
    background-color: #f3f3f3;
}
.order-col-btn {
    width: 35px;
    font-size: 11px;
    margin: 0 3px 0 3px;
    box-shadow: 3px 5px 8px 1px rgba(200, 200, 200, 0.1);
    background: #f6f6f6;
    border: 1px solid #dee2e6;
    border-radius: 3px;
}
.order-col-btn-r {
    width: 70px;
    font-size: 12px;
    margin: 5px 0 5px 3px;
    box-shadow: 3px 5px 8px 1px rgba(200, 200, 200, 0.1);
    background: #f6f6f6;
    border: 1px solid #dee2e6;
    border-radius: 3px;
}
.price-btn {
    background-color: #e7eff5;
}
.tb-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.tb-body td {
    border-radius: 2px;
    border: 1px solid #9d9d9d;
}
.tb-body a {
    font-size: 13px;
}
.ser-box {
    border-radius: 2px;
    border: 1px solid #9d9d9d;
}
.ser-txt {
    height: 1.74rem;
    font-size: 14px;
    outline: none;
    border: none;
}
.ser-inp {
    display: inline-block;
    border: none;
}
.btn-return {
    margin-left: 5px;
    border-style: none;
    border-radius: 50%;
    font-size: 0.6rem;
    background-color: #FFAF34;
}
.order-price a {
    font-weight: bold;
}

.tb-order-dt {
    /*display: flex;*/
    /*flex-direction: column;*/
    width: 1400px;
}
.tb-head-dt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    border-top: 2px solid;
    border-bottom: 0.118rem solid;
    background-color: #f3f3f3;
}
.tb-body-dt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    border-top: 0.09rem solid;
    border-bottom: 0.1rem solid;
    padding: 5px;
}
.tb-body-dt a {
    font-size: 13px;
}
.tb-body-dt td {
    padding: 0 5px 0 5px;
}
.order-item-img {
    width: 55px;
    height: auto;
    margin: auto;
}
.cont-left {
    text-align: left;
}
.url-cont {
    position: relative;
}

.dark-br-top {
    border-top: var(--ad-footer-border-top);
}
.cooper-title {
    background-color: var(--cooper-title-background-color);
}


/********** bottom end **********/

.main-value-badge {
    width: 35px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--m-a-value-badge-background-color);
    /*background-color: #eeeeee;*/
    color: #FFF;
    font-size: 13px;
    font-weight: bold;
}





.user-dt-info-container {
    position: absolute;
    min-width: 170px;
    max-width: 450px;
    width: auto;
    height: auto;
    max-height: 380px;
    border-radius: 4px;
    border: solid 1px #EAEAEA;
    background: #fff;
    z-index: 30;
    top: 20px;
    left: 100px;
    box-shadow: 2px 2px 10px -4px;
}
