.a-common-set-modal {
    z-index: 3000;
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.6);
}
.a-c-s-m-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    align-items: center;
    border-radius: 8px;
    width: 480px;
    height: 620px;
    transform: translate(-50%, -50%);
}
.a-c-s-modi-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    align-items: center;
    border-radius: 8px;
    width: 820px;
    height: 620px;
    transform: translate(-50%, -50%);
}
.sm-f-body {
    overflow-y: auto;
    overflow-x: hidden;
}

