.m-a-custom-info-pg-container {
    box-sizing: border-box;
    padding: 0 24px 20px 24px;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.m-a-custom-info-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    align-items: center;
    gap: 8px;
}

.m-a-custom-info-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-custom-info-sub-title-text {
    font-size: 12px;
}

.m-a-custom-info-sub-title-text.black {
    color: rgba(0, 0, 0, .8);
    font-weight: 700;
    font-size: 12px;
    margin: 8px 0;
}

.m-a-custom-info-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}

.m-a-custom-info-frame {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.m-a-custom-info-frame-grid {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 8px;
}

.m-a-custom-info-frame-country-box {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    font-size: 12px;
    text-align: start;
    position: relative;
}

.m-a-custom-info-frame-country-icon {
    width: 8px;
    opacity: .4;
}

.m-a-custom-info-frame-country-icon.reversed {
    transform: rotate(180deg);
}

.m-a-custom-info-frame-country-select {
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
    background: white;
    z-index: 30;
    max-height: 300px;
    overflow-y: scroll;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.m-a-custom-info-frame-country-select-item {
    width: 100%;
    padding: 8px 12px;
    box-sizing: border-box;
}

.m-a-custom-info-frame-grid-label {
    background-color: var(--pale-skyblue);
    grid-column: span 1;
    font-size: 10px;
    color: rgba(0, 0, 0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: normal;
    word-break: keep-all;
    text-align: center;
}

.m-a-custom-info-frame-grid-value {
    grid-column: span 1;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
}

.custom-info-col-3 {
    grid-column: span 3;
}


.m-a-custom-info-description {
    font-size: 11px;
    width: 100%;
    text-align: start;
    line-height: 1.2;
    margin: 12px 0;
}

.m-a-custom-info-description.background {
    background-color: var(--pale-skyblue);
    box-sizing: border-box;
    padding: 12px;
}

.m-a-custom-info-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-custom-info-row-box {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 8px 0;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.m-a-custom-info-row-box.selected {
    border: 1px solid var(--primary);
}

.custom-info-border {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.custom-info-margin-bottom {
    margin-bottom: 8px;
}
