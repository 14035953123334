.pg-set-tabs {
    display: flex;
}

.pg-set-tabs div {
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    width: 120px;
    height: 40px;
    text-align: center;
    align-content: center;
}

.pg-set-active-tab {
    color: #ffffff;
    font-weight: bold;
    background-color: #FFAF34;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.pg-set-categories div {
    width: 120px;
    text-align: center;
    align-content: center;
    cursor: pointer;
    padding: 3px 13px;
}

.pg-set-active-category {
    color: #FFAF34;
    font-weight: bold;
    border-bottom: 2px solid #FFAF34;
}

.pg-set-tabs, .pg-set-categories {
    display: flex;
}

.pg-set-tabs div, .pg-set-categories div {
    cursor: pointer;
}

.pg-set-tab-content {
    border: var(--dark-task-border);
    border-radius: 4px;
    height: 100%;
}

.drag-layout-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*position: relative;*/
}

.draggable-item {
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    background-color: #d9d9d9;
    /*border: solid 1px #dee2e6;*/
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.layout-header {
    border: solid 1px #dee2e6;
    background-color: #cbcbcb;
    border-radius: 8px;
    width: 500px;
    height: 60px;
    cursor: move;
}

.layout-mBanner {
    border: solid 1px #dee2e6;
    background-color: #cbcbcb;
    border-radius: 8px;
    width: 500px;
    height: 100px;
    cursor: move;
}

.layout-sBanner {
    border: solid 1px #dee2e6;
    background-color: #cbcbcb;
    border-radius: 8px;
    width: 80px;
    height: 200px;
    cursor: move;
}

.layout-review {
    border: solid 1px #dee2e6;
    background-color: #cbcbcb;
    border-radius: 8px;
    width: 500px;
    height: 100px;
    cursor: move;
}

.layout-board {
    border: solid 1px #dee2e6;
    background-color: #cbcbcb;
    border-radius: 8px;
    width: 500px;
    height: 150px;
    cursor: move;
}

.layout-footer {
    border: solid 1px #dee2e6;
    background-color: #cbcbcb;
    border-radius: 8px;
    width: 500px;
    height: 60px;
    cursor: move;
}
