.m-a-usage-guide-pg-container {
    box-sizing: border-box;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 20px 24px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    z-index: 2;
}

.m-a-usage-guide-grid-frame {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 4px;
    row-gap: 8px;
}

.m-a-usage-guide-item-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
    gap: 4px;
}

.m-a-usage-guide-item-img-box {
    min-width: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .03);
    border-radius: 4px;

}

.m-a-usage-guide-item-img {
    width: 40px;
}

.m-a-usage-guide-item-title {
    font-size: 12px;
    color: black;
}


.m-a-usage-guide-item-box.selected {
    box-shadow: 0 0 0 2px var(--primary);
    border-radius: 8px;
}


.m-a-usage-guide-guideline-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-usage-guide-guideline-title-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 24px;
}

.m-a-usage-guide-guideline-title {
    font-size: 16px;
    font-weight: 600;
}

.m-a-usage-guide-guideline-title-img {
    width: 20px;
    cursor: pointer;
}


.m-a-usage-guide-description {
    font-size: 12px;
    font-weight: 500;
    color: var(--pale);
    margin-top: -8px;
}

.m-a-usage-guide-guideline-grid {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 4px;
    row-gap: 24px;
}

.m-a-usage-guide-guideline-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.m-a-usage-guide-guideline-item-content {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    opacity: .6;
}

.m-a-usage-guide-guideline-item-number {
    background-color: #d2d2d2;
    color: white;
    width: 18px;
    height: 18px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
    align-content: center;
}

.m-a-usage-guide-guideline-item-img {
    width: 100%;
    max-width: 52px;
}


.m-a-usage-guide-guideline-item-title {
    font-size: 12px;
    font-weight: 400;
    padding-left: 18px;
}


.m-a-usage-guide-guideline-item.selected, .m-a-usage-guide-guideline-item-content {
    opacity: 1;
}


.m-a-usage-guide-detail-frame {
    width: 100%;
    display: flex;
    border-top: solid 1px #EAEAEA;
    border-bottom: solid 1px #EAEAEA;
    /*flex-direction: column;*/
    align-items: flex-start;
    /*gap: 12px;*/
}

.m-a-usage-guide-detail-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
}

.m-a-usage-guide-detail-index {
    width: 24px;
    filter: saturate(0);
    opacity: .6;
}

.m-a-usage-guide-detail-title-index {
    width: 20px;
    height: 20px;
    background-color: var(--pale);
    align-content: center;
    color: white;
    font-weight: 600;
    border-radius: 4px;
}

.m-a-usage-guide-detail-title-text {
    font-size: 14px;
    font-weight: 700;
}


.m-a-usage-guide-detail-content-box {
    width: 100%;
    box-shadow: 0 0 0 2px var(--background);
    border-radius: 8px;
}

.m-a-usage-guide-detail-content-top {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    text-align: start;
}

.m-a-usage-guide-detail-content-comment-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 12px;
    gap: 8px;
}

.m-a-usage-guide-detail-comment-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-usage-guide-detail-comment-icon {
    width: 20px;
    height: 20px;
}

.m-a-usage-guide-detail-comment-title {
    font-size: 14px;
    font-weight: 500;
}


.m-a-usage-guide-detail-comment-description {
    font-size: 12px;
    line-height: 1.4;
}


.m-a-usage-guide-divider {
    width: 100%;
    min-height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}

.bold {
    min-height: 2px;
    margin: 0;
}

.m-a-usage-guide-pop-up-text-box {
    width: 100%;
    text-align: start;
    line-height: 1.6;
}

.m-a-userGuideContFrame {
    min-height: 100%;
    /*min-width: 260px;*/
    width: 100%;
}
