.m-a-order-pg-container {
    box-sizing: border-box;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 60px 24px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}


.m-a-order-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    position: relative;
}


.m-a-order-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-order-sub-title-expand {
    position: absolute;
    right: 0;
    transform: rotate(90deg);
}

.m-a-order-sub-title-expand.expanded {
    transform: rotate(270deg);
}

.m-a-order-sub-title-text {
    font-size: 14px;
}

.m-a-order-select-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 24px;
}


.m-a-order-select-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}


.m-a-order-select-option-icon {
    width: 16px;
    height: 16px;
}

.m-a-order-select-option-text {
    font-size: 12px;
    font-weight: 400;
}

.m-a-order-delivery-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;
}

.m-a-order-rec-input:focus {
    border-color: #FFAF34;
}
/*.m-a-order-delivery-input-new:focus {*/
/*    border: #FFAF34 solid 2px;*/
/*}*/
.m-a-order-delivery-input-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    font-size: 13px;
    height: 45px;
    box-sizing: border-box;
    /*padding: 1px 0;*/
}


.m-a-order-delivery-input-text {
    font-size: 13px;
    display: inline-block;
}

.m-a-order-delivery-input-new {
    height: 100%;
    border: none;
    outline: none;
    font-size: 13px;
    width: 83%;
}
.m-a-order-delivery-input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 13px;

&:focus {
     outline: none;
 }
}

.m-a-order-delivery-input.highlight {
    font-size: 12px;
    color: var(--red);
    display: inline-block;
    margin: 0 4px;
}


.m-a-order-delivery-address {
    padding: 8px 16px;
    font-size: 12px;
    border: 1px solid var(--primary);
    border-radius: 4px;
    color: var(--primary);
}

.m-a-order-section-title {
    width: 100%;
    height: 36px;
    background-color: var(--primary);
    color: white;
    font-size: 12px;
    text-align: start;
    padding: 0 16px;
    /*align-content: center;*/
    padding-top: 9px;
    opacity: .8;
    margin-bottom: -12px;
}

.m-a-order-section-frame {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.m-a-order-section-frame-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.m-a-order-section-checklist-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}


.m-a-order-section-checklist {
    width: 100%;
    /*font-size: 15px;*/
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    padding-bottom: 5px;
    padding-top: 5px;
    gap: 10px;
}

.m-a-order-invoice-attachment {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    min-height: 64px;
    max-height: 64px;
    gap: 8px;
    box-sizing: border-box;
    padding: 12px;
    box-shadow: 0 0 1px rgba(0, 0, 0, .5);
    margin-bottom: 12px;
}


.m-a-order-invoice-attachment-file {
    flex: 1;
    height: 100%;
    width: 75%;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 12px;
    color: var(--text);
    border-radius: 4px;
}

.m-a-order-invoice-attachment-button {
    height: 100%;
    width: 25%;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 11px 8px;
    font-size: 11px;
    border-radius: 4px;
}

.m-a-order-invoice-attachment-button-input {
    visibility: hidden;
}

.m-a-order-delivery-option {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}


.m-a-order-expand-icon {
    width: 8px;
    opacity: .4;
}

.m-a-order-expanded-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    left: 0;
    z-index: 30;
    max-height: 200px;
    overflow-y: scroll;
}


.m-a-order-expanded-item {
    /*font-size: 12px;*/
    padding: 0 12px;
    min-height: 35px;
    align-content: center;
}

.m-a-order-section-payment-title {
    width: 100%;
    text-align: start;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 3px;
    /*padding-top: 4px;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.m-a-order-section-payment-title-right {
    font-size: 16px;
    color: black;
}

.m-a-order-section-payment-title-highlight {
    color: var(--primary);
    display: inline-block;
}

.m-a-order-section-payment-discount {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.m-a-order-section-payment-discount-label {
    font-size: 12px;
}


.m-a-order-section {
    display: inline-block;
    font-size: 12px;
}

.m-a-order-section.highlight {
    color: var(--primary);
}

.m-a-order-section-payment-discount-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-order-section-payment-discount-input {
    width: 80px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, .1);
    font-size: 12px;
    box-sizing: border-box;
    padding: 0 12px;

&:focus {
     outline: none;
 }
}

.m-a-order-section-payment-discount-button {
    padding: 0 8px;
    border: 1px solid var(--primary);
    height: 32px;
    align-content: center;
    color: var(--primary);
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
}

.m-a-order-agreement-box {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}


.m-a-order-agreement-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-order-agreement-row-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}


.m-a-order-agreement-text {
    display: inline-block;
    font-size: 14px;
}

.m-a-order-agreement-text.primary {
    color: var(--primary);
    font-weight: 600;
}

.m-a-order-agreement-text.strong {
    font-weight: 700;
    color: black;
}

.m-a-order-agreement-row-right {
    width: 16px;
    height: 16px;
    opacity: .4;
}

.m-a-order-section-payment-method-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 12px;
}


.m-a-order-section-payment-method {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 4px;
}


.m-a-order-section-payment-method-text {
    font-size: 12px;
    text-align: center;
    font-family: Pretendard-Regular, sans-serif;
    color: var(--contents-color);
    font-weight: 500;
}

.m-a-order-section-payment-method.focused {
    border: 2px solid;
    border-color: darkorange;
    background-color: white;
}


.m-a-order-payment-detail-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 8px;
}


.m-a-order-payment-detail-box-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.m-a-order-payment-detail-box-left {
    font-size: 14px;
    font-weight: 600;
    color: black;
}

.m-a-order-payment-detail-box-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: var(--primary);
    font-weight: 600;
    gap: 4px;
}

.m-a-order-payment-detail-box-dropdown {
    font-size: 12px;
    height: 28px;
    padding: 4px 12px;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 4px;
    color: var(--text);
    font-weight: 400;
    position: relative;
}


.m-a-order-payment-detail-dropdown-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 30;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    position: absolute;
    top: 28px;
    left: 0;
    max-height: 120px;
    overflow-y: scroll;
}

.m-a-order-payment-detail-dropdown-item {
    min-height: 28px;
    align-content: center;
}

.m-a-order-payment-detail-box-dropdown.no-border {
    border: none;
}

.m-a-order-payment-detail-box-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    gap: 4px;
}

.m-a-order-payment-detail-box-desc {
    width: 100%;
    font-size: 12px;
    text-align: start;
}


.m-a-order-pg-bold {
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
}

.m-a-order-payment-detail-box-img {
    width: 60%;
}

.m-a-order-divider {
    width: 100%;
    min-height: 1px;
    background-color: rgba(0, 0, 0, .1);
}

.m-a-order-input-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.m-a-order-input-title {
    position: absolute;
    top: -8px;
    left: 12px;
    background-color: white;
    font-size: 12px;
    padding: 0 4px;
}

.m-a-order-input {
    width: 100%;
    height: 40px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 0 12px;

&:focus {
     outline: none;
     border: 1px solid rgba(0, 0, 0, .3);
 }
}

.m-a-order-input-password {
    position: absolute;
    right: 12px;
    top: 6px;
    z-index: 20;
}

.m-a-user-order-input-pw-icon {
    width: 16px;
}

.m-a-order-section-invoice-attachment {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    font-size: 14px;
}

.m-a-order-section-payment-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.m-a-order-section-payment-row-text {
    font-size: 12px;
}

.m-a-order-pg-bottom-navigation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 50;
    padding: 8px 20px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    gap: 12px;
}


.m-a-order-pg-bottom-navigation-bar-button-save {
    padding: 12px 32px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    color: white;
    background: var(--pale);
}

.m-a-order-pg-bottom-navigation-bar-button-pay.disabled {
    cursor: default;
    opacity: .4;
}

.m-a-order-button-empty {
    margin: 0 auto;
    font-size: 12px;
    padding: 8px 24px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.m-a-order-error-message-box {
    width: 100%;
    font-size: 12px;
    color: var(--red);
    text-align: start;
    padding-left: 12px;
}

.m-a-order-error-message-box.margin-left {
    margin-left: 12px;
}
