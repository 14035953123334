.m-a-addr-book-add-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.m-a-addr-book-add-container {
    width: calc(100% - 24px);
    max-height: 72vh;
    overflow-y: scroll;
    max-width: 320px;
    border-radius: 8px;;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    gap: 4px;
    z-index: 60;
}

.m-a-addr-book-add-header {
    box-sizing: border-box;
    width: 100%;
    min-height: 40px;
    position: relative;
    padding: 14px 14px;
}
.m-a-addr-book-add-header-back {
    width: 26px;
    height: 26px;
    opacity: .7;
    position: absolute;
    left: 14px;
    top: 17px;
}

.m-a-addr-book-add-title {
    font-size: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 24px;
}

.m-a-addr-book-add-close-icon {
    width: 24px;
    height: 24px;
    opacity: .6;
    position: absolute;
    right: 20px;
}


.m-a-addr-book-add-error {
    width: 100%;
    font-size: 12px;
    color: var(--red);
    text-align: start;
    margin-top: -8px;
}

.m-a-addr-book-add-content {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;
    padding: 12px 20px 72px 20px;
}

.m-a-addr-book-add-country-select {
    width: 100%;
    padding: 8px 0;
    font-size: 12px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    position: relative;
}

.m-a-addr-book-add-country-list {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow-y: scroll;
    background: white;
    z-index: 70;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.m-a-addr-book-add-country-item {
    min-height: 32px;
    align-content: center;
}

.m-a-addr-book-add-default {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 12px;
}

.m-a-addr-book-add-pg-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    height: 60px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 8px;
}

.m-a-addr-book-add-pg-button {
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    border-radius: 4px;
    color: white;
    text-align: center;
    align-content: center;
    font-size: 14px;
}

.m-a-addr-book-add-divider {
    width: 100%;
    min-height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-top: -3px;
}

.m-a-addr-add-input-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    /*padding: 0 7px;*/
    border-radius: 4px;
}

.m-a-addr-add-input-title {
    position: absolute;
    top: -8px;
    left: 12px;
    background-color: white;
    font-size: 12px;
    padding: 0 4px;
    display: flex;
    flex-direction: row;
}

.m-a-addr-add-input-essential {
    font-size: 12px;
    color: var(--red);
    align-content: center;
}

.m-a-addr-add-input {
    flex: 1;
    height: 40px;
    font-size: 12px;
    border-radius: 4px;
    box-sizing: border-box;
    border: none;
}

.m-a-addr-add-input-text {
    align-content: center;
    font-size: 12px;
}

.m-a-addr-add-input-text.highlight {
    color: var(--red);
    opacity: .6;
    display: inline-block;
    margin-right: 3px;
}
