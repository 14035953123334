/*.m-a-login-pg-container {*/
/*    box-sizing: border-box;*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    padding: 0 10% 0 10%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    margin: 0;*/
/*    justify-content: center;*/
/*    overflow: hidden;*/
/*}*/

.m-a-login-pg-container {
    box-sizing: border-box;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100); /* 변경된 부분 */
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    justify-content: center;
    overflow: hidden;
}

.m-a-login-pg-logo {
    width: 173px;
    /*margin: 12px 0;*/
}

.m-a-login-pg-error-message {
    width: 100%;
    font-size: 12px;
    color: var(--red);
    text-align: start;
}

.m-a-login-pg-text-button-frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}

.m-a-login-pg-text-button {
    font-size: 12px;
    cursor: pointer;
}

.m-a-login-pg-vertical-divider {
    width: 1px;
    height: 14px;
    background-color: rgba(0, 0, 0, .6);
}

.m-a-login-pg-button {
    background-color: #ff8913;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 14px;
    color: white;
}
.m-a-login-pg-button-B {
    border: 1px solid #ff8913;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    color: #ff8913;
}
.m-a-login-pg-button.disabled {
    border: none;
    opacity: .5;
}

.m-a-login-pg-sns-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
}

.m-a-login-pg-footer {
    margin-top: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}


.m-a-login-pg-footer-text {
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}
