.ReviewContainer {
    display: block;
    margin-top: 20px;
    height: 1700px;
}
.ReviewPageContainer {
    display: inline-block;
    width: 100%;
    margin-top: 100px;
    height: 100px;
}
.ReviewWriteButton {
    display: inline-block;
}

.ReviewPageNumber {
    margin: 0 10px;
}
.ReviewPageContainer a:hover {
    color: rgb(255,120,0,0.78);
    font-size: 18px;
}
.ReviewPageContainer a:focus {
    color: rgb(255,120,0,0.78);
    font-size: 18px;
}
.ReviewLeftCon1 {
    font-size: 20px;
    margin-right: 5px;
}
.ReviewLeftCon2 {
    font-size: 20px;
    margin-right: 20px;
}
.ReviewRightCon1 {
    font-size: 20px;
    margin-left: 20px;
}
.ReviewRightCon2 {
    font-size: 20px;
    margin-left: 5px;
}