.AdminNewRegModal {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
    background-color: #f3f3f3;
}

.an-header-frame {
    top: 0;
    left: 0;
    right: 0;
    flex-shrink: 0;
    position: fixed;
    z-index: 1;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    height: 250px;
}

.an-header-title {
    height: 50px;
    background-color: #3c3f49;
}

.an-ord-info-frame {
    padding: 7px 20px 20px 20px;
    background-color: #FFF;
}

.an-cont-frame {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-top: 250px;
}

.an-footer-frame {
    height: 50px;
    padding: 20px;
    flex-shrink: 0;
    position: sticky;
    bottom: 0;
    background-color: #FFF;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
