.UserLoginPg {
    display: block;
    box-sizing: border-box;
}
.LoginMain {
    height: 700px;
    margin-top: 90px;
}
.LoginFrame {
    border: solid 1px #EAEAEA;
    border-radius: 8px;
    width: 500px;
    height: 100%;
    margin: auto;
    padding: 40px;
}
.LoginPgIdPwContainer {
    margin-top: 20px;
}
.LoginContainer {
    border: solid 1px #EAEAEA;
    height: 60px;
    border-radius: 7px;
    display: flex;
    margin-bottom: 5px;
}
.LoginIdInput {
    font-size: 16px;
    padding: 17px 15px;
    box-sizing:border-box;
    outline: none;
    border: none;
    width: 99%;
}
.LoginIdInput:not(:placeholder-shown).LoginIdInput:not(:focus) + .JoinLabel {
    transform: scale(.75) translateY(-65px) translateX(-10px);
    color: dimgrey;
}
.LoginIdInput:focus {
    border-color: #FFAF34;
}
.LoginIdInput:focus + .JoinLabel {
    transform: scale(.75) translateY(-65px) translateX(-10px);
    color: #FFAF34;
    background-color: white;
}
.autoLoginContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.acc-ckbox-frame {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.acc-ckbox-frame label {
    width: 100%;
    cursor: pointer;
    margin-left: 5px;
    color: dimgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}
.acc-ckbox-frame input {
    cursor: pointer;
    width: 20px;
    height: 20px;
    accent-color: rgba(255,95,0,0.91);
    margin-bottom: 9px;
}
.FindJoin ul {
    overflow: hidden;
    padding:0;
    display: flex;
    flex-direction: row;
}
.LoginPgLoginButton {
    height: 50px;
    margin-top: 15px;
}
.LoginLanguageSection {
    height: 70px;
    display: flex;
    justify-content: center;
    position: fixed;
    /*bottom: 120px;*/
}
