.m-a-user-review-detail-pg-container {
    box-sizing: border-box;
    padding: 0 24px;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.m-a-user-review-detail-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-review-detail-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-user-review-detail-sub-title-text {
    font-size: 14px;
}

.m-a-user-review-detail-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
}

.m-a-user-review-detail-review-info-frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.m-a-user-review-detail-review-info-profile {
    width: 60px;
    height: 60px;
    border-radius: 50px;
}

.m-a-user-review-detail-review-user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.m-a-user-review-detail-review-user-name {
    font-size: 12px;
}

.m-a-user-review-detail-review-sub {
    font-size: 11px;
    color: rgba(0, 0, 0, .4);
}

.m-a-user-review-detail-review-title {
    font-size: 14px;
    color: black;
}

.m-a-user-review-detail-content {
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 1.4;
}

.m-a-user-review-detail-img {
    width: 100%;
    max-height: 350px;
}

.m-a-user-review-detail-comment-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.m-a-user-review-detail-comment-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.m-a-user-review-detail-comment-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-user-review-detail-comment-title-text {
    font-size: 14px;
}

.m-a-user-review-detail-comment-title-length {
    font-size: 14px;
    color: var(--primary);
}

.m-a-user-review-detail-comment-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.m-a-user-review-detail-comment-user {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
}

.m-a-user-review-detail-comment-user-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-review-detail-comment-user-profile {
    width: 24px;
    height: 24px;
    border-radius: 18px;
}

.m-a-user-review-detail-comment-user-name {
    font-size: 12px;
}

.m-a-user-review-detail-comment-user-right {
    font-size: 12px;
    color: rgba(0, 0, 0, .4);
}

.m-a-user-review-detail-comment-content {
    width: 100%;
    display: flex;
    text-align: start;
    line-height: 1.4;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    padding-bottom: 8px;
    font-size: 12px;
}

.m-a-user-review-comment-box-write {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
}

.m-a-user-review-comment-box-input {
    flex: 1;
    height: 40px;
    max-height: 40px;
    overflow-y: scroll;
    font-size: 12px;
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    outline: none;

&:focus {
     outline: none;
     border: 1px solid rgba(0, 0, 0, .3);
 }
}

.m-a-user-review-comment-box-button {
    height: 40px;
    background-color: var(--primary);
    color: white;
    padding: 0 20px;
    align-content: center;
    border-radius: 4px;
    font-size: 12px;
}
