#m-a-login-pg-container {
    height: 100%;
}
.m-a-logo-icon {
    width: 30px;
    height: 30px;
}
.m-a-idpw-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m-a-join-findPw-container {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 50px;
}
.m-a-join-text {
    font-size: 14px;
    color: dimgrey;
}
.m-a-findPw-text {
    font-size: 14px;
    color: dimgrey;
}
