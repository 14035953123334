#m-a-order-chat-pg-container {
    height: 100vh;
}
#m-a-order-chat-pg-pd-container {
    display: block;
    text-align: center;
    padding: 15px;
    height: auto;
    min-height: 100%;
    background-color: #f2f2f6;
    border: none;
}

.m-a-chatTab-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-a-headerTab {
    background-color: #fff;
    padding: 13px;
    border: none;
    box-shadow: rgba(63, 71, 77, 0.2) 0.03rem 0.05rem 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.m-a-headerFocusTab {
    background-color: #FFAF34;
    padding: 13px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: white;
}
.m-a-chatTtCt {
    font-size: 14px;
    margin-left: 5px;
}
.m-a-chat-search-container {
    max-width: 45%;
}
/*주문서채팅 프로필*/
.m-a-chat-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
}
.m-a-profile-frame {
    width: 12%;
    height: 100%;
}
.m-a-profile-frame-img {
    width: 100%;
    height: 95%;
    border-radius: 100%;
}
.m-a-chat-content-frame {
    width: 88%;
    height: 100%;
    padding: 0 10px;
}
.m-a-chat-content {
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.m-a-chat-userNm {
    width: 80%;
    text-align: left;
    font-weight: bold;
}
.m-a-chat-unreadCount {
    text-align: right;
    background-color: #FF6324;
    color: white;
    height: 18px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 0 6px;
}
.m-a-chat-chatKey {
    width: 80%;
    text-align: left;
    font-size: 14px;
    color: dimgrey;
}
.m-a-chat-chatKey-text-frame {
    width: 80%;
    text-align: left;
    font-size: 14px;
    color: dimgrey;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.m-a-chat-commingTime {
    font-size: 12px;
    color: dimgrey;
}

/*주문서채팅 리스트*/
.m-a-chat-ordList-container {
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.m-a-chat-ordList-frame {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 6px 0;
}
.m-a-chat-ordList-frame:active {
    background-color: #FFF2DA;
    border-radius: 6px;
}
.m-a-profile-ordList-frame {
    width: 12%;
    height: 100%;
}
.m-a-profile-ordList-frame-img {
    width: 100%;
    height: 90%;
    border-radius: 100%;
    opacity: 0.5;
}
.m-a-chat-ordList-content-frame {
    width: 88%;
    height: 100%;
    padding: 0 10px;
}
.m-a-chat-ordList-content {
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.m-a-chat-ordNum {
    width: 80%;
    text-align: left;
    font-weight: bold;
}
.m-a-chat-ordList-unreadCount {
    text-align: right;
    background-color: #FF6324;
    color: white;
    height: 18px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 0 6px;
}
.m-a-chat-ordList-chatKey {
    width: 80%;
    text-align: left;
    font-size: 14px;
    color: dimgrey;
}
.m-a-chat-ordList-commingTime {
    font-size: 12px;
    color: dimgrey;
}
.m-a-chatMsg-container {
    height: 100vh;
    background-color: white;
}
.m-a-chat-blank-space {
    height: 45px;
}
.m-a-chat-header-container {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    padding: 0 10px;
    border-bottom: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
}
.m-a-chat-header-frame {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.m-a-chat-header-img {
    width: 25px;
    height: 25px;
}
.m-a-chat-header-text {
    margin-left: 10px;
}
.m-a-chat-header-close-img {
    width: 30px;
    height: 30px;
    opacity: 0.7;
}
.m-a-chat-body-container {
    height: 510px;
    overflow-y: scroll;
    padding: 10px;
}
.m-a-chat-first-time-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}
.m-a-chat-first-time {
    font-size: 12px;
}
.m-a-chat-userProfile-frame2 {
    display: flex;
    padding: 10px 0;
    width: 100%;
}
.m-a-chat-userProfile-frame {
    object-fit: contain;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
}
.m-a-chat-userProfile-img {
    border-radius: 100%;
}
.m-a-chat-nameCont-frame {
    margin-left: 10px;
}
.m-a-chat-msg-content-frame {
    border: solid 1px dimgray;
    border-radius: 0 10px 10px 10px;
    padding: 8px;
    max-width: 260px;
}
.m-a-chat-msg-content {
    width: fit-content;
    word-break: break-word;
    white-space: pre-wrap;
}
.m-a-chat-content-time-frame {
    display: flex;
    flex-direction: row;
}
.m-a-chat-msg-time-frame {
    margin-left: 5px;
    display: flex;
    align-items: flex-end;
}
.m-a-chat-msg-time {
}
.m-a-chat-adminProfile-frame1 {
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-chat-adminProfile-frame2 {
    display: flex;
    width: 100%;
}
.m-a-chat-admin-unreadCount {
    color: darkorange;
    font-weight: bold;
}
.m-a-chat-edit-remove-time-frame {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.m-a-chat-edit-remove-frame {
    display: flex;
    justify-content: center;
}
.m-a-chat-edit {
    color: CornflowerBlue;
}
.m-a-chat-remove {
    color: darkorange;
}
.m-a-chat-msg-admin-content-frame {
    padding: 8px;
    background-color: #FFAF34;
    border-radius: 8px;
    margin-left: 5px;
    max-width: 270px;
}
.m-a-chat-msg-admin-content {
    color: white;
    width: fit-content;
    word-break: break-word;
    white-space: pre-wrap;
}

.m-a-chat-msgInput-container {
    background-color: #F6F6F6;
    padding: 10px;
}
.m-a-chat-send-frame {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.m-a-chat-emoji-photo-frame {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.m-a-chat-show-emoji {
}
.m-a-chat-show-emoji-icon {
    width: 25px;
    height: 25px;
}
.m-a-chat-show-emoji-popup-frame1 {
    position: relative;
}
.m-a-chat-show-emoji-popup-frame2 {
    position: absolute;
    bottom: 40px;
    left: -40px;
    border:solid;
    border-width: thin 1px;
    border-color: dimgray;
    border-radius: 6px;
}
.m-a-chat-fileUpload-frame {
    width: 30px;
    height: 30px;
}
.m-a-chat-fileUpload-button {
    width: 30px;
    height: 30px;
    border:none;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}
.m-a-chat-textarea-frame {
    width: 77%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-chat-textarea {
    height: 42px;
    max-height: 400px;
}
.m-a-chat-send-bt-frame {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-chat-send-bt-img {
    width: 25px;
    height: 25px;
}
.m-a-chatKey-img {
    border-radius: 6px;
    max-width: 220px;
    max-height: 350px;
}
.m-a-chatKey-file-expiration-img {
    width: 40px;
    height: 40px;
    opacity: 0.5;
}
.m-a-chatKey-file-expiration-text {
    font-size: 11px;
    margin-top: 5px;
    color: darkgrey;
}
.m-a-chatKey-file-frame2 {
    width: 160px;
    height: 120px;
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    border-radius: 6px;
}
.m-a-chatKey-file-img-frame {
    height: 65%;
}
.m-a-chatKey-file-img {
    width: 60px;
    height: 60px;
}
.m-a-chatKey-fileNm-text-frame {
    height: 18%;
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}
.m-a-chatKey-fileNm-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.m-a-chatKey-expiration-date {
    height: 17%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    color: dimgray;
}


.popup-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.m-a-chat-admin-macro-popup-frame {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* 팝업을 최상단에 표시 */
}

.m-a-chat-admin-macro-popup {
    width: 300px;
    height: 500px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.m-a-chat-admin-macro-header-frame {
    width: 100%;
    height: 10%;
}
.m-a-chat-admin-macro-body-frame1 {
    width: 100%;
    height: 75%;
    padding: 10px;
    overflow-y: auto;
}
.m-a-chat-admin-macro-body-frame2 {
    width: 100%;
    padding: 3px 0;
}
.m-a-chat-admin-macro-body-parent {
    width: 100%;
    height: 45px;
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    border-radius: 10px;
    font-weight: lighter;
    font-size: 16px;
    color: dimgrey;
}
.m-a-chat-admin-macro-body-child-frame {
    border-radius: 6px;
    background-color: #EAEAEA;
    padding: 20px 20px 15px 20px;
    margin: 10px 0;
}
.m-a-chat-admin-macro-body-child {
    width: 100%;
    height: 45px;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 5px;
    color: dimgray;
    font-size: 15px;


}
.m-a-chat-admin-macro-footer-frame {
    width: 100%;
    height: 15%;
}
