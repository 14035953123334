.headerGoBack {
    font-size: 25px;
    cursor: pointer;
    color: dimgrey;
    position: absolute;
    left: 20px;
    padding-bottom: 5px;
}

.modiCardFrame {
    width: 100%;
    background-color: white;
    padding: 30px 20px 20px 20px;
}
.modiCardName {
    width: 100%;
    height: 12.5%;
}
.modiCardAddr1 {
    width: 100%;
    height: 12.5%;
}
.modiCardAddr2 {
    width: 100%;
    height: 12.5%;
}
.modiCardAddr3 {
    width: 100%;
    height: 12.5%;
}
.modiCardCity {
    width: 100%;
    height: 12.5%;
}
.modiCardZipcode {
    width: 100%;
    height: 12.5%;
}
.modiCardTelnum {
    width: 100%;
    height: 12.5%;
}
.defaultAddr {
    width: 100%;
    height: auto;
}
