.orderDeleteBt {
    color: white;
    background-color: lightgrey;
    border-style: none;
    border-radius: 6px;
}
.orderDeleteBt:hover {
    background-color: darkgrey;
}
.orderDeleteBt:focus {
    background-color: darkgrey;
}
.productImageFrame img{
    filter: opacity(0.4) drop-shadow(0 0 0 lightgrey);
}

.chatListBt:hover {
    background-color: rgba(255,155,5,0.13);
}
.chatListBt:focus {
    background-color: rgba(255,155,5,0.3);
}

.OrdChat img{
    filter: opacity(0.4) drop-shadow(0 0 0 lightgrey);
}

.OrdChatSend img{
    filter: opacity(0.4) drop-shadow(0 0 0 #FFAF34);
    color: rgba(211,213,205,0.25);
}

.ordChatInputContainer textarea:focus{
    outline: none !important;
    border:1px solid #FFAF34 !important;
    color: rgba(255,171,77,0.24);
}

.chatStartImg img{
    filter: opacity(0.15) drop-shadow(0 0 0 lightgrey);
}




