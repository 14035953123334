.m-a-dep-charg-history-pg-container {
    box-sizing: border-box;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 20px 24px;
    width: 100vw;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.m-a-dep-charg-history-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-dep-charg-history-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-dep-charg-history-sub-title-text {
    font-size: 14px;
}

.m-a-dep-charg-history-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}


.m-a-dep-charg-history-table-container {
    width: 100%;
    overflow-x: scroll;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    min-height: 200px;
}

.m-a-dep-charg-history-table {
    min-width: 800px;
    height: 36px;
    display: grid;
    grid-template-columns: repeat(25, 1fr);
}


.m-a-dep-charg-history-table-item {
    grid-column: span 4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
}

.m-a-dep-charg-history-table-item.sm {
    grid-column: span 2;
}

.m-a-dep-charg-history-table-item.lg {
    grid-column: span 5;
}

.m-a-dep-charg-history-table-item-button {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-a-dep-charg-history-table-item-button-icon {
    width: 20px;
    height: 20px;
}

.m-a-dep-charg-history-table.label {
    background-color: var(--background);
    height: 40px;
}
