.scan-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.scan-msg {
    width: 250px;
    padding-top: 7px;
    margin-left: 45px;
}
.scan-msg b {
    font-size: 40px;
}
.scan-waybill {
    width: 700px;
    height: 80px;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    margin-left: 45px;
}
.scan-waybill:focus {
    box-shadow: 0 0 12px #FFAF34;
}
.combo-plus {
    width: 150px;
    height: 80px;
    font-size: 20px;
    padding-left: 13px;
    background: url("/src/assets/img/ComboArrow_dob.png") no-repeat 90% 55%/15px auto #ffffff;
}
.scan-msg .scan-select-text {
    color: var(--cancel-color);
    animation-duration: 1s;
    animation-name: scanshiptext;
    animation-iteration-count: infinite;
}
@keyframes scanshiptext {
    0% { color: var(--cancel-color); }
    15% { color: #ff4d00; }
    30% { color: #ff8000; }
    45% { color: #ffa600; }
    60% { color: #ffae00; }
    75% { color: #ff8000; }
    90% { color: #ff4d00; }
    100% { color: var(--cancel-color); }
}
