.m-a-user-dt-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: solid 1px #EAEAEA;
    background-color: var(--m-a-user-dt-frame-background-color);
}
.m-a-u-d-1 {
    display: flex;
    flex-direction: row;
    justify-content: start;
}
