.accordion {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 16px 0;
}

.accordion-header {
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    background-color: #FAFAFA;
}

.accordion-header:hover {
    background-color: #F2F2F2;
}

.accordion-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background-image: url('https://cdn-icons-png.flaticon.com/512/2626/2626997.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.4s;
    transform: rotate(90deg); /* 기본적으로 아래쪽을 향하게 */
}

.accordion-header.expanded .accordion-icon {
    transform: rotate(0deg);
}

.accordion-body {
    display: none;
    border: solid 1px #EAEAEA;
    border-top: none;
    border-radius: 4px;
}

.accordion-body.expanded {
    display: block;
}


.recInfoRowContainer {
    display: flex;
}
.recInfoRowTitle {
    width: 25%;
    /*margin: auto;*/
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    font-size: 15px;
    background-color: #FAFAFA;
}
.recInfoRowContent {
    width: 75%;
    margin: auto;
    color: rgba(255,121,2,0.12);
}

.radioButton {
    vertical-align: middle;
    border: max(2px, 0.1em) solid gray;
    border-radius: 50%;
    width: 1.17em;
    height: 1.17em;
    transition: border 0.5s ease-in-out;
}
.radioButton:checked {
    border: 0.4em solid tomato;
}
.radioButton:focus-visible {
    outline-offset: max(2px, 0.1em);
    outline: max(2px, 0.1em) dotted tomato;
}

.orderChoiceSelect{
    width: 200px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 13px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: grey;
    color: rgba(52,52,50,0.82);
}

.orderChoiceSelect:focus{
    border: 1px solid #FFAF34;
    box-sizing: border-box;
    border-radius: 10px;
    outline: 3px solid orange;
}
.orderPayMethodContainer {
    border: solid 1px #EAEAEA;
    border-radius: 6px;
    height: 110px;
    width: 33.3%;
    margin: 4px;
    cursor: pointer;
}
.orderPayMethodFocusContainer {
    border: solid 2px #FFAF34;
    border-radius: 6px;
    height: 110px;
    width: 33.3%;
    margin: 4px;
    cursor: pointer;
}

#orderPaySticky {
    position: sticky;
    top: 0;
    left: 34%;
}
.usePointInput {
    border: none;
    border: solid 1px #EAEAEA;
}
.usePointInput:focus-within {
    outline: none !important;
    border-color: darkorange;
    box-shadow: 0 0 2px 1px darkorange;
}
/*.orderItemHandleBt {*/
/*    border: none;*/
/*    background: #FFAF34;*/
/*    color: white;*/
/*    height: 38px;*/
/*    width: 75px;*/
/*    font-weight: bold;*/
/*    margin-left: 5px;*/
/*    font-size: 14px;*/
/*    border-radius: 4px;*/
/*}*/
.orderItemHandleBt {
    border: none;
    background: #FFAF34;
    color: white;
    padding: 8px 15px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 4px;
}
.orderItemHandleBt:hover {
    background: #FF9924;
}
.orderItemHandleBt:focus-within {
    background: #FF9924;
}
.orderFormInput {
    border: solid 1px #EAEAEA;
    width: 100%;
    height: 35px;
    border-radius: 4px;
    padding: 0 6px 0 6px;
    font-size: 14px;
    color: dimgray;
}
.orderFormInput:focus-within {
    outline: none !important;
    border-color: darkorange;
    box-shadow: 0 0 2px 1px darkorange;
}
.warningRequestMsg {
    display: none;
}
.warningRequest:hover + .warningRequestMsg {
    display: block;
}
.orderPgServiceHeader {
    border-bottom: 1px solid #EAEAEA;
    display: flex;
    height: 60px;
}

.fileUploadFrame input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip:rect(0,0,0,0);
    border: 0;
}

.fileUploadFrame label {
    display: inline-block;
    cursor: pointer;
    margin-left: 4px;
}

.fileUploadFrame .fileUploadName {
    display: inline-block;
    -webkit-appearance: none; /* 네이티브 외형 감추기 */
    -moz-appearance: none;
    appearance: none;
}
.fileUploadName:focus-within {
    outline: none !important;
    border-color: darkorange;
    box-shadow: 0 0 2px 1px darkorange;
}

.dropdown-options li:focus {
    outline: 2px solid orange;
}
.orderSelectContainer {
    /*border: none;*/
    border: solid 1px #EAEAEA;
    color: darkgray;
    cursor: pointer;
}
.orderSelectContainer:focus-within {
    outline: orange 3px solid;
    border: none;
}

.orderSaveBt {
    color: dimgrey;
    background-color: white;
    border: solid 1px #E6E6E6;
    border-radius: 6px;
}
.orderSaveBt:hover {
    color: white;
    background-color: lightgray;
}
.orderSaveBt:focus {
    color: white;
    background-color: lightgray;
}




