/*팝업*/

/* region PC*/

.custom-popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
}
.custom-popup-frame {
    z-index: 1001;
    background-color: #fff;
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
.popup-frame-s {
    width: 360px;
    height: 480px;
    border: none;
    box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
    -webkit-box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
    -moz-box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
}
.popup-frame-m {
    width: 480px;
    height: 640px;
    border: none;
    box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
    -webkit-box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
    -moz-box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
}
.popup-frame-l {
    width: 600px;
    height: 800px;
    border: none;
    box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
    -webkit-box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
    -moz-box-shadow: 0 0 5px 3px rgba(0,0,0,0.11);
}
.popup-s-frame {
    width: 360px;
    height: 480px;
    border: none;
    position: fixed;
    top: 180px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 9px 6px rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: 0 0 9px 6px rgba(255, 255, 255, 0.2);
    -moz-box-shadow: 0 0 9px 6px rgba(255, 255, 255, 0.2);
}
.popup-m-frame {
    width: 480px;
    height: 640px;
    border: none;
    position: fixed;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 9px 6px rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: 0 0 9px 6px rgba(255, 255, 255, 0.2);
    -moz-box-shadow: 0 0 9px 6px rgba(255, 255, 255, 0.2);
}
.popup-l-frame {
    width: 600px;
    height: 800px;
    border: none;
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 11px 8px rgba(255, 255, 255, 0.23);
    -webkit-box-shadow: 0 0 11px 8px rgba(255, 255, 255, 0.23);
    -moz-box-shadow: 0 0 11px 8px rgba(255, 255, 255, 0.23);
}
.popup-s-frame:hover, .popup-m-frame:hover, .popup-l-frame:hover, .p-f-m-popup:hover {
    box-shadow: 0 0 15px 10px rgba(255, 255, 255, 0.28);
    -webkit-box-shadow: 0 0 15px 10px rgba(255, 255, 255, 0.28);
    -moz-box-shadow: 0 0 15px 10px rgba(255, 255, 255, 0.28);
}
.popup-frame-s:hover, .popup-frame-m:hover, .popup-frame-l:hover {
    box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
    -webkit-box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
    -moz-box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
}
.pop-title-input {
    width: 340px;
    height: 40px;
    font-size: 16px;
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    padding-left: 5px;
}
.pop-title-input:focus-within {
    border: 2px solid orange;
}

.pop-url-input {
    width: 340px;
    height: 40px;
    font-size: 16px;
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    padding-left: 5px;
}
.pop-url-input:focus-within {
    border: 2px solid orange;
}

.popup-frame-mobile {
    min-width: 390px;
    width: 420px;
    border-radius: 12px;
    border: none;
    box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
    -webkit-box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
    -moz-box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
}
.pop-btn {
    border: 1px solid #EAEAEA;
    background-color: #FFF;
    transition: 0.3s;
}
.pop-btn:hover {
    background-color: #ececec;
    border: 1px solid #ececec;
}

/* endregionPC*/

/* region Mobile*/

.p-f-m-popup-s {
    width: 360px;
    height: 480px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.13);
    -webkit-box-shadow: 0 0 5px 5px rgba(0,0,0,0.13);
    -moz-box-shadow: 0 0 5px 5px rgba(0,0,0,0.13);
}
.m-a-custom-popup-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    z-index: 1000;
}
.m-popup-s-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /*position: fixed;*/
    /*top: 15%;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    box-shadow: 0 0 5px 3px rgba(255, 255, 255, 0.13);
    -webkit-box-shadow: 0 0 5px 3px rgba(255, 255, 255, 0.13);
    -moz-box-shadow: 0 0 5px 3px rgba(255, 255, 255, 0.13);
}
.m-popup-s-frame-img { /*3:4비욜*/
    width: 75vw;
    height: calc(75vw * (4 / 3));
    max-width: 360px;
    max-height: 480px;
    border: none;
}
.popup-custom-pagination {
    position: absolute;
    top: -26px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    z-index: 10;
}

/* endregion Mobile*/



