.m-a-addr-book-pg-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-a-addr-book-pg-container {
    width: calc(100% - 24px);
    max-height: 72vh;
    overflow-y: scroll;
    max-width: 320px;
    border-radius: 8px;;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    z-index: 50;
}

.m-a-addr-book-pg-header {
    box-sizing: border-box;
    width: 100%;
    min-height: 40px;
    position: relative;
    padding: 14px 14px;
}

.m-a-addr-book-pg-title {
    font-size: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.m-a-addr-book-pg-close-icon {
    width: 30px;
    height: 30px;
    opacity: .7;
    position: absolute;
    right: 14px;
    top: 10px;
}


.m-a-addr-book-pg-content {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background: var(--background);
    box-sizing: border-box;
    padding: 12px 20px 80px 20px;
}

.m-a-addr-book-frame {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px;
    gap: 4px;
}

.m-a-addr-book-frame-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: black;
}

.m-a-addr-book-text-bold {
    font-size: 15px;
    color: black;
}

.m-a-addr-book-text {
    font-size: 13px;
    color: black;
}

.m-a-addr-book-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.m-a-addr-book-buttons-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-addr-book-pg-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    height: 60px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 8px;
}

.m-a-addr-book-pg-button {
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    border-radius: 4px;
    color: white;
    text-align: center;
    align-content: center;
    font-size: 14px;
}

.m-a-addr-book-button {
    color: var(--primary);
    font-size: 12px;
    padding: 8px 12px;
    border: 1px solid var(--primary);
    border-radius: 4px;
}

.m-a-addr-book-button:active {
    background-color: var(--btorage-background-color);
    color: #fff;
}

.m-a-addr-delete-popup {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
    z-index: 60;
}

.m-a-addr-book-pg-popup {
    width: 100%;
    max-width: 300px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    box-sizing: border-box;
    padding: 12px 0;
    border-radius: 4px;
}

.m-a-addr-book-pg-popup-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
}

.m-a-addr-book-pg-popup-title {
    display: flex;
    height: 40px;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 16px;
}

.m-a-addr-book-pg-popup-icon {
    width: 24px;
}

.m-a-addr-book-pg-popup-icon-close {
    width: 24px;
    opacity: .6;
}

.m-a-addr-book-pg-text {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 12px;
    text-align: start;
}

.m-a-addr-book-delete-popup-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 12px;
    padding-right: 20px;
}

.m-a-addr-book-delete-popup-button {
    color: #AAAAAA;
    font-size: 12px;
    padding: 8px 20px;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
}

.m-a-addr-book-delete-popup-button.delete {
    background: var(--primary);
    color: white;
    border: 1px solid var(--primary);
}

.m-a-addr-book-pg-divider {
    width: 100%;
    min-height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin: 12px 0;
}

.m-a-addr-book-button-select {
    color: #fff;
    font-size: 12px;
    padding: 8px 12px;
    background-color: #FDA600FF;
    border-radius: 4px;
}

.m-a-addr-book-button-select:active {
    background-color: #fff;
    color: #FDA600FF;
    border: 1px solid var(--primary);
}
