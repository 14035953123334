.JoinContainer {
    height: auto;
    display: block;
    box-sizing: border-box;
}
.JoinMain {
    margin-top: 50px;
    margin-bottom: 50px;
}
.JoinFrame {
    border-style: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    border-radius: 8px;
    width: 500px;
    margin: auto;
    padding: 40px;
}
.joinMailForm {
    border-style: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    height: 60px;
    border-radius: 7px;
    width: 50%;
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
}
.joinMailAlpha {
    color: dimgrey;
    width: 5%;
    display: flex;
    align-items: center;
}
.JoinForm {
    border-style: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    height: 60px;
    border-radius: 7px;
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
}
.JoinInput {
    font-size: 16px;
    padding: 17px 15px;
    box-sizing:border-box;
    outline: none;
    border: none;
    width: 99%;
}
.JoinInput:not(:placeholder-shown).JoinInput:not(:focus) + .JoinLabel {
    transform: scale(.75) translateY(-65px) translateX(5px);
    color: dimgrey;
}
.JoinInput:focus {
    border-color: #FFAF34;
}
.JoinInput:focus + .JoinLabel {
    transform: scale(.75) translateY(-65px) translateX(5px);
    color: #FFAF34;
    background-color: white;
}

.JoinSelectForm {
    border-style: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    height: 60px;
    border-radius: 7px;
    display: flex;
}
.JoinSelectIcon {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.JoinSelectIcon i {
    font-size: 28px;
    padding-left: 5px;
}
.SelectListIcon i {
    font-size: 20px;
    color: dimgrey;
}
.JoinSelectInputForm {
    position: relative;
    width: 75%;
    font-size: 14px;
}
.JoinSelectLanguage {
    width: 97%;
    height: 100%;
    border: none;
    border-radius: 0;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #999;
    padding-left: 18px;
}
.JoinSelectLanguage:focus {
    border-style: none;
}
.JoinSelectLanguage option {
    font-size: 16px;
    color: dimgrey;
    background: #fffefa;
}
.JoinSelectInputForm option:hover {
    color: red;
}
.JoinLangForm {
    border-style: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    height: 60px;
    border-radius: 7px;
    display: flex;
    margin: 10px 0;
}
.SwitchToSelectIcon i {
    font-size: 25px;
    padding-left: 5px;
    margin-top: 7px;
    color: orangered;
}
.SwitchToSelectIcon i:hover {
    color: #ffb8c1;
    transition: 0.1s;
    cursor: pointer;
}
.SwitchToSelectIcon i:active {
    color: #ffb8c1;
    font-size: 28px;
    transition: 0.1s;
}
.LangInputForm {
    position: relative;
    width: 75%;
    margin-left: 2px;
}
.JoinErrorMsg {
    /*height: 30px;*/
    color: #FF8339;
}
.JoinErrMsg {
    color: #FF8339;
}
.AgreeContainer {
    margin-top: 40px;
    border-style: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    border-radius: 7px;
    padding: 0 30px 20px 30px;
}
.AllAgree {
    height: 70px;
    width: 100%;
    display: flex;
    padding: 13px 0;
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 10px;
}
.AllAgree label {
    cursor: pointer;
    margin-left: 10px;
    color: darkgray;
    width: 100%;
    height: 30px;
    margin-top: 8px;
    display: inline-block;
}
.AllAgree input {
    border-style: dotted;
    cursor: pointer;
    width: 20px;
    height: 35px;
    accent-color: #FF9924;
    margin-top: 7px;
}
.AllAgree span {
    font-size: 18px;
    color: black;
}
.MailReceptionText {
    font-size: 13px;
    color: grey;
}
.MailUnCheck{
    color: #FF9924;
    font-size: 14px;
    display: inline-block;
}
.JoinButton {
    height: 50px;
}

.dropdown-options {
    color: black;
}
.dropdown-options li.focused {
    background-color: #FF9924;
    color: white;
}
.dropdown-options li:hover {
    background-color: #FF9924;
    color: white;
}
