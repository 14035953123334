.UserModiContainer {
    position: relative;
    box-sizing: border-box;
}
.modiMain {
    margin: 110px;
}
.modiFrame {
    border-style: solid;
    border-width: thin 1px;
    border-color: lightgrey;
    border-radius: 8px;
    width: 900px;
    height: 100%;
    margin: auto;
    padding: 40px;
}
.modiProfileContainer {
    height: 200px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modiProfileFrame {
    height: 170px;
    width: 180px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.photoUpAndDelete {
    font-size: 14px;
    background-color: #FFAF34;
    border: #FFAF34;
    border-radius: 6px;
    color: white;
    /*margin-top: 10px;*/
    /*margin-left: 305px;*/
}
.photoUpAndDelete:hover {
    background-color: #FF9924;
    color: white;
}
.photoUpAndDelete:focus {
    background-color: #FF9924;
    color: white;
    border-color: white;
    box-shadow: 0 0 0 1px #FFB124;
    --bs-btn-focus-shadow-rgb: 255, 120, 88;
}
.modiForm {
    color: rgba(79,76,74,0.75);
    padding-left: 50px;
    height: 80px;
    font-size: 18px;
    width: 30%;
    padding-top: 25px;
}

/*회원수정 메일수신 on/off*/
.toggleSwitch {
    width: 60px;
    height: 30px;
    display: block;
    position: relative;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0 0 16px 3px rgba(0 0 0 / 8%);
    cursor: pointer;
    margin-top: 3px;
}

.toggleSwitch .toggleButton {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #FFB124;
}

input[type="checkbox"]:checked + .toggleSwitch {
    background: #FFB124;
}

input[type="checkbox"]:checked + .toggleSwitch .toggleButton {
    left: calc(100% - 24px);
    background: #fff;
}

.toggleSwitch, .toggleButton {
    transition: all 0.2s ease-in;
}
