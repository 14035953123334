.reg-tb {
    font-size: 13px;
}
.reg-tb > tbody > tr > th {
    width: 160px;
    vertical-align: middle;
    background-color: rgba(60, 63, 73, 0.1);
    font-size: 13px;
}
.reg-tb > tbody > tr:hover {
    background-color: rgba(223, 223, 227, 0.5);
}
.reg-tb-B-type {
    font-size: 13px;
}
.reg-tb-B-type > tbody > tr > th {
    width: 150px;
    vertical-align: middle;
    background-color: rgba(60, 63, 73, 0.1);
}
.reg-tb-B-type > tbody > tr > td {
    width: 350px;
    vertical-align: middle;
}
.reg-tb-B-type > tbody > tr:hover {
    background-color: rgba(223, 223, 227, 0.5);
}
