.FirstContainer {
    /*border-style: solid;*/
}
.SecondContainer {
    /*height: 100%;*/
    /*border-style: solid;*/
}
.ReviewText {
    font-size: 20px;
    box-sizing: border-box;
    border-right-width: 0;
    border-left: lightpink 12px solid;
    line-height: 1.5;
    margin: 20px 0;
    padding: 0 0 0 15px;
}
.ReviewExplanation {
    height: 50px;
    font-size: 14px;
}
.ReviewSearch {
    text-align: center;
    margin-top: 80px;
    height: 100px;
    display: flex;
    /*display: block;*/
    justify-content: center;
    /*border-style: dotted;*/
}
.ReviewFilter1 {
    height: 50px;
    margin: 0 10px;
}
.ReviewFilter2 {
    height: 50px;
    margin: 0 10px;
}
.ReviewFilter3 {
    height: 50px;
    margin: 0 10px;
}
