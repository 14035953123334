.m-a-side-quick-menu-container {
    position: fixed;
    right: 24px;
    bottom: 80px;
    z-index: 30;
}


.m-a-side-quick-menu-default {
    position: fixed;
    right: 24px;
    bottom: 80px;
}

.m-a-side-quick-menu-img {
    width: 48px;

}

.m-a-side-quick-menu-open {
    position: fixed;
    right: 24px;
    bottom: 132px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.m-a-side-quick-menu-item-img {
    width: 48px;
    height: 48px;
}
