.bt-toolbar-frame {
    position: sticky;
    top: 0;
    background-color: white;
    /*z-index: 50;*/
}

.bt-toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #b9b9b9;
    height: 50px;
}

.bt-t-button {
    border: none;
    outline: none;
    transition: 0.3s;
}

.bt-t-select {
    /*padding: 8px;*/
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .07);
    border-radius: 4px;
    outline: none;
}

.g-file-upload-new {

}

.g-img-upload-new {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .07);
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: transform 0.4s cubic-bezier(.55,1.6,.35,1);
}
.g-img-upload-new:hover, .bt-t-select:hover {
    background-color: #f6f6f6;
}

.options-menu-new {
    position: absolute;
    width: 120px;
    top: 55px;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.options-menu-new div {
    display: block;
    padding: 7px 0;
    width: 100%;
    cursor: pointer;
}
.options-menu-new div:hover {
    background-color: #f6f6f6;
}
