.banner-slide-frame {
    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 16 / 9;
}

.scroll-container {
    display: flex;
    transition: transform 0.5s ease;
    height: 100%;
}

.image-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* 16:9 비율 */
    box-sizing: border-box;
    overflow: hidden;
}

.banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*.image-container {*/
/*    !*flex-shrink: 0;*!*/
/*    min-width: 100%;*/
/*    box-sizing: border-box;*/
/*    height: 100%;*/
/*}*/

/*.banner-image {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*    border: solid 2px red;*/
/*}*/

.swiper-pagination-fraction {
    position: absolute;
    bottom: 11px;
    right: 11px;
    z-index: 10;
    color: #eaeaea;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 2px 8px;
    border-radius: 4px;
}

.swiper-pagination-fraction .swiper-pagination-current,
.swiper-pagination-fraction .swiper-pagination-total {
    color: #eaeaea;
}

/*.banner-index-text {*/
/*    position: absolute;*/
/*    bottom: 10px;*/
/*    right: 10px;*/
/*    font-size: 12px;*/
/*    background-color: rgba(0, 0, 0, 0.4);*/
/*    color: #eeeeee;*/
/*    padding: 2px 8px;*/
/*    border-radius: 4px;*/
/*}*/

.m-a-user-main-pg-container {
    box-sizing: border-box;
    padding-bottom: 60px;
    /*padding: 0 20px 80px 20px;*/
    /*margin-top: 24px;*/
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.m-a-user-main-pg-review-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-user-main-pg-review-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 20px;
}
.m-a-user-main-pg-review-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}
.m-a-user-main-pg-review-img {
    width: 100%;
    height: 150px;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
}
@media (min-width: 768px) {
    .m-a-user-main-pg-review-img {
        width: 100%;
        height: 350px;
        background-position: center;
        background-size: cover;
        border-radius: 4px;
    }
}
.m-a-user-main-pg-review-sub {
    font-size: 12px;
}

.m-a-user-main-pg-review-title {
    font-size: 12px;
    /*color: #2d2d2d;*/
}

.m-a-user-main-pg-review-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.m-a-user-main-pg-review-date {
    font-size: 11px;
    color: var(--pale);
}

.m-a-user-main-pg-review-new {
    font-size: 12px;
    color: var(--primary);
}

.m-a-user-main-pg-review-no-data-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-user-main-pg-no-data-img {
    width: 120px;
}

.m-a-user-main-pg-no-data-text {
    font-size: 12px;
    color: var(--pale);
}

.m-a-user-main-pg-notice-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-user-main-pg-notice-list {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.m-a-user-main-pg-notice-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.m-a-user-main-pg-notice-item-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.m-a-user-main-pg-notice-sub {
    font-size: 12px;
    color: var(--pale);
}

.m-a-user-main-pg-notice-new {
    font-size: 11px;
    color: var(--primary);
}

.m-a-user-main-pg-notice-date {
    font-size: 12px;
    color: var(--pale);
}

.m-a-user-main-pg-bottom-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.m-a-user-main-pg-bottom-img-box {
    width: 100%;
    box-sizing: border-box;
    padding: 4px 4px 4px 10px;
    background-color: var(--chat-background);
    margin-bottom: 8px;
}

.m-a-user-main-pg-bottom-img {
    width: 120px;
}

.m-a-user-main-pg-bottom-title {
    font-size: 16px;
}

.m-a-user-main-pg-bottom-text {
    font-size: 12px;
    text-align: start;
    margin: 8px 0;
    line-height: 1.4;
    height: fit-content;
}

.primary {
    display: inline-block;
    color: var(--primary);
}

.bold {
    font-size: 12px;
}

.m-a-user-main-pg-search-frame {
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin: 8px 0;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}
.m-a-user-main-pg-search-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 12px;
}

.m-a-user-main-pg-search-input::placeholder {
    color: var(--pale);
    font-size: 12px;
}

.m-a-user-main-pg-search-icon {
    width: 24px;
    height: 24px;
    opacity: .3;
}

.m-a-user-main-pg-ship-co-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
}
.m-a-user-main-pg-ship-item {
    width: 100%;
    padding: 12px;
    text-align: start;
    font-size: 12px;
}

.m-a-user-main-pg-img-list-container {
    width: 100%;
    padding: 0 20px;
}

.m-a-user-main-pg-img-list-frame {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

}

.m-a-user-main-pg-img {
    display: flex;
    object-fit: contain;
    padding: 20px;
}

.m-a-user-main-pg-text-box {
    width: 100%;
    text-align: start;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
}
.m-a-user-main-pg-text {
    width: 100%;
    text-align: start;
}

.m-a-user-main-pg-button {
    background: var(--primary);
    padding: 8px 20px;
    font-size: 12px;
    color: white;
    border-radius: 4px;
}


.delivery {
    gap: 32px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.m-a-user-main-pg-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.m-a-user-main-pg-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-user-main-pg-sub-title-text {
    font-size: 15px;
}

.m-a-user-main-pg-bold-divider {
    width: 100%;
    height: 4px;
    background-color: #F6F6F6;
}

.m-a-user-main-pg-more {
    width: 80px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 8px 0;
    margin: 0 auto;
    font-size: 12px;
    color: var(--pale);
    text-align: center;
}

.m-a-user-main-pg-button-transparent {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
}

.m-a-user-floating-button {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: var(--pink);
    position: fixed;
    bottom: 80px;
    right: 24px;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}

.m-a-user-floating-button-img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.m-a-user-floating-button-frame {
    position: fixed;
    bottom: 140px;
    right: 24px;
    display: flex;
    flex-direction: column;
    z-index: 20;
    gap: 8px;
}
.m-a-user-floating-button-img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
}

.m-a-user-main-pg-menu-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
}

.m-a-user-main-pg-menu-box {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}

.m-a-user-main-pg-menu-icon-box {
    width: 70px;
    height: 60px;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.m-a-user-main-pg-menu-box-sub {

}

.m-a-user-main-pg-menu-icon {
    object-fit: contain;
    width: 32px;
    height: 32px;
}

.m-a-user-main-pg-menu-title {
    font-size: 12px;
    white-space: normal;
    text-align: center;
}

.m-u-app-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    z-index: 1000;
}

.m-u-app-popup-cont {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    z-index: 1001;
    text-align: center;
    padding: 30px 0;
    gap: 15px;
}

.m-u-app-popup-header-frame {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 80px;
    justify-content: center;
    align-content: center;
}

.m-u-app-popup-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 80px;
    text-align: center;
}

/* 텍스트만 따로 감싸 중앙 배치 */
.m-u-app-popup-header-text-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    word-break: break-word;
    white-space: normal;
    line-height: 1.4;
    font-size: 20px;
    max-width: 60%;
    text-align: left;
}

.m-u-app-popup-header-text {
    color: #2c2c2c;
    margin: 0;
}

/* 혜택 제목 + 밑줄 세트 */
.benefits-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
}

.benefits-title {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 4px;
    border-bottom: solid;
    border-color: darkorange;
}

.m-u-app-popup-boon-content-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    line-height: 28px;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
}

.m-a-app-p-bt {
    outline: none;
    border: none;
    /*background-color: #FFAF34;*/
    background-color: #FF6A13;
    border-radius: 10px;
}

.m-a-app-p-bt-text {
    color: white;
    font-size: 18px;
}

.m-a-app-p-bt:active {
    background-color: #ffa20f;
}

.m-u-app-icon-frame {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: solid 1px #c4c4c4;*/
    box-shadow: 0 1px 5px rgba(44, 44, 44, 0.2);
    border-radius: 15px;
    background-color: #FFF;
}

.m-u-app-icon-container {
    width: 37px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.m-u-app-icon-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

/*.m-u-app-icon-frame-B {*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    box-shadow: 0 1px 5px rgba(44, 44, 44, 0.2);*/
/*    border-radius: 11px;*/
/*    background-color: #FFF;*/
/*}*/

/*.m-u-app-icon-container-B {*/
/*    width: 22px;*/
/*    height: 32px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.m-u-app-icon-container-B img {*/
/*    max-width: 100%;*/
/*    max-height: 100%;*/
/*    object-fit: cover;*/
/*}*/




.m-a-user-main-pg-footer-new {
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
}
.m-a-user-main-pg-footer-button-new {
    width: 50%;
    background-color: #d3d3d3;
    padding: 12px 0;
    border-radius: 8px;
    font-size: 13px;
    text-align: center;
}

.m-a-user-main-pg-footer-guide-new {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.m-a-user-main-pg-footer-guide-row-new {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.m-a-user-main-pg-footer-guide-text-new {
    font-size: 13px;
}

.m-a-user-main-pg-footer-company-info-new {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.m-a-user-main-pg-footer-company-info-logo-new {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.m-a-user-main-pg-footer-company-info-logo-img-new {
    width: 14px;
}
.m-a-user-main-pg-footer-company-info-text-new {
    font-size: 17px;
}
.m-a-user-main-pg-footer-text-md-new {
    width: 100%;
    font-size: 13px;
    text-align: start;
    line-height: 1.4;
}
