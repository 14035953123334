.SideQuickMenu {
    position: fixed;
    bottom: 0;
    right: 10px;
}

.quickMenu ul {
    transition: transform 0.3s ease-in-out;
}

.quickMenu.closed ul {
    transform: translateY(100%);
}

.quickMenu ul li {
    width: 74px;
    height: 74px;
    /*margin-bottom: 7px;*/
    margin: 7px 4px 7px 0;
}
.quickMenu ul li .side-ctg {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 15px;
    border-radius: 50%;
    box-shadow: 2px 2px 4px 1px  rgba(20, 20, 20, 0.15);
    position: relative;
}
.quickMenu ul li i.icon {
    display: Block;
    width: 100%;
    height: 43px;
    position: relative;
    text-align: center;
}
.quickMenu ul li i.icon img {
    position: relative;
    border: 0 none;
    vertical-align: top;
}
.quickMenu ul li span {
    display: block;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
}

/*아이콘 회전효과*/
.i-logo {
    position: relative;
}
.quickMenu ul li .side-ctg:hover .i-logo {
    animation-name: rotate;
    animation-duration: .4s;
    animation-timing-function: linear;
}
@keyframes rotate {
    from {transform: rotateY(0deg);}
    to {transform: rotateY(360deg);}
}

/*색상 변환효과*/
.quickMenu ul li:nth-child(1) .side-ctg {
    /*background: rgba(234, 128, 131, .7);*/
    background: rgb(255, 120, 0, .6);
}
.quickMenu ul li:nth-child(2) .side-ctg {
    background: rgba(80, 179, 85, .7);
}
.quickMenu ul li:nth-child(3) .side-ctg {
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url("/src/assets/img/instabg.png") center center no-repeat;
    background-size: cover;
    border: 0.15rem solid transparent;
    background-origin: border-box;
    background-clip: border-box;
}
.quickMenu ul li:nth-child(3) .side-ctg >* .insta-logo-img {
    display: none;
}
.quickMenu ul li:nth-child(3) .side-ctg >* .insta-logo-icon {
    display: inline-block;
}
.quickMenu ul li:nth-child(4) .side-ctg {
    background: rgba(255, 230, 90, .7);
}
.quickMenu ul li:nth-child(5) .side-ctg {
    /*background: rgba(117, 117, 117, .7);*/
    background: rgba(7, 203, 106, 0.7);
    /*background: #07C160; !*공식색상*!*/
}
.quickMenu ul li:nth-child(6) .side-ctg {
    background: rgba(77, 108, 187, .7);
}
.quickMenu ul li:nth-child(7) .side-ctg {
    background: rgba(255, 103, 103, 0.7);
}

.quickMenu ul li:nth-child(1) .side-ctg:hover {
    background: rgb(255, 120, 0);
}
.quickMenu ul li:nth-child(2) .side-ctg:hover {
    background: #00C300; /*공식색상*/
}
.quickMenu ul li:nth-child(3) .side-ctg:hover {
    /*background: url("../assets/img/instabgb.png") center center no-repeat;*/
    /*background-size: cover;*/

    border: 0.15rem solid transparent;
    background-image: url("/src/assets/img/insta_logo_font.png"), linear-gradient(#f5f5f5, #f5f5f5), url("/src/assets/img/instabgb.png");
    background-size: 58px, 30px, 100px 80px;
    background-position: 50% 74%, center center;
    background-repeat:no-repeat, round;
    background-origin: border-box;
    background-clip: content-box, content-box, border-box;
}
.quickMenu ul li:nth-child(3) .side-ctg:hover >* .insta-logo-icon {
    display: none;
}
.quickMenu ul li:nth-child(3) .side-ctg:hover >* .insta-logo-img {
    display: inline-block;
}
.quickMenu ul li:nth-child(3) .side-ctg:hover > span {
    display: none;
}
.quickMenu ul li:nth-child(4) .side-ctg:hover {
    background: #FEE500; /*공식색상*/
}
.quickMenu ul li:nth-child(5) .side-ctg:hover {
    /*background: rgba(117, 117, 117, 1);*/
    background: rgba(7, 193, 96, 1); /*공식색상*/
}
.quickMenu ul li:nth-child(6) .side-ctg:hover {
    background: rgb(77, 108, 187);
}
.quickMenu ul li:nth-child(7) .side-ctg:hover {
    background: rgb(255, 103, 103);
}

/*말풍선 효과*/
.quickMenu .Balloon {
    position: absolute;
    text-align: center;
    width: 120px;
    top: -42%;
    padding: 1px;
    border-radius: 3px;
    right: 35%;
    /*transform: translateX(-50%);*/
    border: 1px solid #a2a5a4;
    opacity: 0;
    transition: .6s ease-in-out;
    visibility: hidden;
    box-sizing: border-box;
}
.quickMenu ul li .side-ctg .Balloon:after {
    content: '';
    position: absolute;
    border-top: 1px solid #a2a5a4;
    border-right: 1px solid #a2a5a4;
    width: 8px;
    height: 8px;
    transform: rotate(45deg) translateX(16%);
    right: -0.25rem;
    top: 45%;
}
.quickMenu ul li .side-ctg:hover .Balloon {
    opacity: 1;
    visibility: visible;
}
.quickMenu ul li .side-ctg .Balloon p {
    margin: 0;
    padding-bottom: 5px;
    text-align: center;
    font-size: 13px;
    color: #a2a5a4;
}
.quickMenu ul li .Balloon img {
    vertical-align: top;
    display: block;
    max-width: 100%;
    border:0 none;
}
.quickMenu ul li .side-ctg:hover .Balloon {
    display: block;
}
.quickMenu ul .chat-list {
    overflow: hidden;
    max-height: 0;
    transform: translateY(0%);
    transition: max-height 0.25s ease-out, transform 0.25s ease-out;
}

.quickMenu ul .chat-list.open {
    max-height: 500px;
    transform: translateY(0%);
    transition: max-height 0.4s ease-in, transform 0.4s ease-in;
}

.quickMenu ul li.main-chat {
    order: 1; /* mainChat을 항상 마지막에 위치 */
    margin-top: -2px;
}



textarea::selection {
    background-color: #FFCE76; /* 원하는 색으로 변경 */
}

.autoResBt {
    margin: 4px;
    height: 30px;
    display: flex;
    border-radius: 4px;
    background-color: white;
    justify-content: center;
    align-items: center;
    color: dimgrey;
    cursor: pointer;
    padding: 0 8px;
}

.autoResBt:hover {
    background-color: #FFAF34;
    color: white;
}

textarea::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
textarea {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.chat-data-deadline-notice-container {
    padding: 10px 20px;
}
.chat-data-deadline-notice-border-frame {
    padding: 2px;
    border-radius: 10px;
    opacity: 0.8;
    background: linear-gradient(to right, orange 33.33%, yellow 66.66%, green 100%);
    position: relative;
}
.chat-data-deadline-notice-message-frame {
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    position: relative;
    text-align: center;
    z-index: 1;
}
.chat-unread-count-balloon {
    position: absolute;
    top: 0;
    right: -5px;
    margin: 0;
    border-radius: 100%;
    background-color: orangered;
    color: white;
    font-size: 14px;
}