.m-a-delivery-price-detail-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.m-a-delivery-price-detail-container {
    width: calc(100% - 24px);
    max-height: 72vh;
    overflow-y: scroll;
    max-width: 350px;
    border-radius: 8px;;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    gap: 4px;
    box-sizing: border-box;
    z-index: 60;
}

.m-a-delivery-price-detail-header {
    width: 100%;
    position: relative;
    min-height: 44px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-delivery-price-detail-header-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
    top: 12px;
}

.m-a-delivery-price-detail-header-icon {
    position: absolute;
    width: 30px;
    right: 20px;
    top: 10px;
    opacity: 0.7;
}

.m-a-delivery-price-detail-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
}

.m-a-delivery-price-detail-table-title {
    width: 100%;
    height: 40px;
    background: var(--background);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0 12px;
}

.m-a-delivery-price-detail-table-icon {
    width: 20px;
    opacity: .6;
}

.m-a-delivery-price-req {
    width: 100%;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    height: 36px;
}

.m-a-delivery-price-req-label {
    font-size: 12px;
    width: 30%;
    height: 100%;
    /*align-content: center;*/
    text-align: center;
    padding-top: 9px;
    /*border-bottom: 1px solid rgba(0, 0, 0, .1);*/
    /*border-right: 1px solid rgba(0, 0, 0, .1);*/
    /*border-left: 1px solid rgba(0, 0, 0, .1);*/
}

.m-a-delivery-price-req-value {
    font-size: 12px;
    width: 70%;
    height: 100%;
    /*align-content: center;*/
    /*text-align: center;*/
    padding-top: 9px;
    /*border-bottom: 1px solid rgba(0, 0, 0, .1);*/
    /*border-right: 1px solid rgba(0, 0, 0, .1);*/
}

.m-a-delivery-price-add-packing-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
}

.m-a-delivery-price-add-packing-table-row {
    width: 400px;
    height: 40px;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    padding-top: 10px;
}

.m-a-delivery-price-add-packing-table-row-item {
    height: 100%;
    align-content: center;
    font-size: 12px;
    width: 80px;
    min-width: 80px;
    text-align: center;
}

.m-a-delivery-price-add-packing-table-row-item.sm {
    min-width: 40px;
    width: 40px;
}

.m-a-delivery-price-add-packing-table-row-item.lg {
    width: 160px;
    min-width: 160px;
}

.m-a-delivery-price-input-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    /*padding-left: 12px;*/
    border-radius: 4px;
}

.m-a-delivery-price-input-title {
    position: absolute;
    top: -8px;
    left: 12px;
    background-color: white;
    font-size: 11px;
    padding: 0 4px;
    display: flex;
    flex-direction: row;
}

.m-a-delivery-price-input-essential {
    font-size: 12px;
    color: var(--red);
    align-content: center;
}

.m-a-delivery-price-input {
    flex: 1;
    height: 40px;
    font-size: 12px;
    box-sizing: border-box;
    border: none;
    padding-left: 11px;
}
.m-a-delivery-price-input:disabled {
    background-color: #ffffff;
    cursor: not-allowed;
    opacity: 1;
}
.m-a-delivery-price-input-text {
    align-content: center;
    font-size: 12px;
    /*padding-right: 3px;*/
}

.m-a-delivery-price-input-text.highlight {
    color: var(--red);
    opacity: .6;
    display: inline-block;
    margin-right: 3px;
}

.m-a-delivery-price-add-content {
    width: 100%;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.m-a-delivery-price-delivery-agency-frame {
    width: 50px;
    height: 40px;
}

.m-a-delivery-price-delivery-agency {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.m-a-delivery-price-add-country-select-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 8px;
}

.m-a-delivery-price-add-country-button {
    height: 36px;
    width: 80px;
    font-size: 12px;
    outline: none;
    border: 1px solid var(--primary);
    border-radius: 4px;
    color: var(--primary);
}

.m-a-delivery-price-add-country-select {
    width: 100%;
    padding: 8px 0;
    font-size: 12px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    position: relative;
}

.m-a-delivery-price-add-country-list {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow-y: scroll;
    background: white;
    z-index: 70;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    border-radius: 4px;
}


.m-a-delivery-price-add-country-item {
    min-height: 32px;
    align-content: center;
}

.m-a-delivery-price-add-default {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 12px;
}

.m-a-delivery-price-row-space-between {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-delivery-price-row-space-between-item {
    font-size: 12px;
}

.m-a-delivery-price-row-space-between-item.primary {
    color: var(--primary);
}

.dt-price-flex-start {
    align-items: flex-start;
}

.m-a-delivery-price-detail-button {
    font-size: 12px;
    padding: 6px 20px;
    background: var(--primary);
    border-radius: 4px;
    color: white;
    border: none;
    margin-top: 12px;
}

.m-a-delivery-price-sub-title {
    font-size: 14px;

}

.m-a-delivery-price-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
}


.m-a-delivery-price-row-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 12px;
}

.m-a-delivery-price-add-pay-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.m-a-delivery-price-delivery-sub-title {
    font-size: 13px;
    margin-top: 8px;
}

.m-a-delivery-price-button {
    width: calc(100% - 48px);
    padding: 8px 0;
    font-size: 14px;
    color: white;
    background: var(--primary);
    border-radius: 4px;
    margin: 12px auto;
    text-align: center;
}

.m-a-delivery-price-divider {
    width: 100%;
    min-height: 1px;
    background: rgba(0, 0, 0, .1);
    margin: 8px 0;
}

.dt-price-primary {
    color: var(--primary);
}
