.pc-bt-main-header {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 18;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
}
.pc-bt-main-logo-frame {
    min-width: 143px;
}
.pc-bt-main-logo {
    width: 142px;
    height: 35px;
    opacity: 0.8;
}
.img-hover-opc:hover {
    opacity: 1;
}



.HeaderLogoCt {
    height: 65%;
    display: flex;
    width: 1280px;
    margin: auto;
}
.HeaderLogo {
    width: 150px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.HeaderLogo img {
    width: 80%;
}
/*여기가 헤더 언어 메뉴*/
.HeaderLangCt{
    position : relative;
    display : inline-block;
    float: right;
    margin-top: 6px;
    width: 80px;
}
.HeaderLangFrame {
    position: relative;
    list-style: none;
    border: solid 1px lightgrey;
    display: flex;
    border-radius: 7px;
}
.HeaderLangBtn {
    border: none;
    cursor : pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    overflow: hidden;
}
.HeaderLangBtnIcon {
    display: flex;
    font-size: 23px;
    padding-left: 7px;
    overflow: hidden;
    justify-content: center;
}
.HeaderLangBtn:focus {
    background: rgba(255,163,0,0.55);
    border-radius: 6px;
}
.HdLangList {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    z-index: 1;
    text-align: center;
}
.HeaderLangFrame img {
    width: 40px;
}
.HdLangList.drop {
    display: block;
}
.HeaderLangFrame ul{
    overflow: hidden;
    margin:0;
    padding:0;
    width :100%;
}
.HdLangList li {
    color: #4d4d4d;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.2s;
}
.HdLangList li:hover {
    background: rgba(255,163,0,0.55);
}
.HdLangList li:focus-within {
    background: rgba(255,163,0,0.55);
}

/*여기서부터 한국기준 시간*/
.HeaderTimeCt {
    height: 55px;
    display: flex;
    font-size: 14px;
}
.TimeIcon {
    width: 30%;
    height: 100%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    padding: 10px 5px 10px 20px;
}
.TimeIcon img{
    height:33px;
    width:33px;
    margin-top:2px;
    filter: opacity(0.5) drop-shadow(0 0 0 #4f4f4f);
}
.YY-MM-DD {
    height: 50%;
    display: flex;
    overflow: hidden;
    justify-content: center;
}
.Time {
    height: 50%;
    display: flex;
    overflow: hidden;
}
/*여기서부터 메인메뉴*/
.HeaderMenuAllCt-plus {
    /*margin-top: 30px;*/
    -webkit-box-shadow: 0 0 0 3px rgb(255,255,255), 0 5px 4px lightgrey;    /* 길게 */
}
.HeaderMenuAllCt {
    /*-webkit-box-shadow: 0 0 0 3px rgb(255,255,255), 0 5px 4px lightgrey;  !*1280px *!*/
    height: 60px;
    display: flex;
    width: 1280px;
    margin: auto;
    /*border-style: solid;*/
}
.HeaderMenuCt {
    height: 100%;
    width: 85%;
}
.HeaderBtnCt{
    position : relative;
    display : inline-block;
    height: 100%;
}
.HeaderDropBtn{
    border: none;
    color : black;
    height: 100%;
    width :120px;
    text-align: center;
    cursor : pointer;
    font-size : 18px;
    font-family: 'GangwonEdu_OTFBoldA', serif bold;
}
.HeaderDropBtn:focus:after {
    color: #FFAF34;
    border-bottom: 3px solid #FFAF34;
    width: 4px;
}
.HeaderDropBtn:focus {
    color: #FFAF34;
}

.HeaderDropBtn:hover {
    color: #FFAF34;
}
.HeaderBtnCt-content{
    position : absolute;
    /*z-index : 2; !*다른 요소들보다 앞에 배치*!*/
    font-size: 20px;
    margin-top: 12px;
    border-bottom: 1px solid lightgrey;
    height: 40px;
    left: 0; /*길게*/
    /*width: 1280px; !*짧게*!*/
    width: 100%; /*길게*/
}
.HeaderBtnCt-content-short {
    width: 1280px;
    margin: auto;
}
.HeaderBtnCt-content a {
    margin-left: 22px;
    font-size: 15px;
}

.header-join {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 40px;
}
.join-bar {
    display: flex;
    margin: 0 10px 0 10px;
}
.join-tag1 {
    font-size: 14px;
    line-height: 18px;
    color: black;
    padding: 0 14px;
}
.join-tag2 {
    font-size: 14px;
    line-height: 18px;
    color: black;
    padding: 0 14px;
    border-left: 1px solid lightgrey;
}
/*프로필/마이페이지 시작*/

.profileImg {
    position: relative;
    display: inline-block;
}

.headerPropileContainer {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.MyPageBtn {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0,18,33,0.89);
    display: flex;
    align-items: center;
    padding-left: 15px;
}
.MyPageBtn:hover {
    color: #FFAF34;
    cursor : pointer;
    transition: 0.6s;
}
.profile-dropdown-content {
    background-color: white;
    width: 170px;
}
.dropdown-btnContainer {
    width: 100%;
}
.dropdown-btn {
    border-style: solid;
    border-width: thin 1px;
    border-color: orangered;
    color: orangered;
    border-radius: 8px;
    width: 48.4%;
    font-size: 13px;
    height: 40px;
    margin: 1px;
    font-weight: lighter;
}
.dropdown-btn:hover {
    background-color: orangered;
    color: white;
}
.dropdown-btn:focus-within {
    background-color: orangered;
    color: white;
}
/*프로필/마이페이지 종료*/

.headerSmallMenu:hover {
    color: darkorange;
    transition: 0.5s;
}

.profileBtn {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    cursor : pointer;
    border: none;
}
.colorFullBt {
    color: white;
    background-color: #FFAF34;
    border-style: none;
    border-radius: 6px;
}
.colorFullBt:hover {
    background-color: #FF9924;
}
.colorFullBt:focus {
    background-color: #ff9924;
}

.headerLoginBt {
    width: 80px;
    height: 35px;
    border: solid 1px lightgray;
    border-radius: 6px;
}
.headerLoginBt:hover {
    border: solid;
    border-color: #FF9924;
}

.headerJoinBt {
    width: 80px;
    height: 35px;
    border: none;
    background-color: #FFAF34;
    border-radius: 6px;
}
.headerJoinBt:hover {
    background-color: darkorange;
    transition: 0.3s;
}
