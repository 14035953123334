.m-a-mail-inquiry-pg-container {
    box-sizing: border-box;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.m-a-mail-inquiry-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-mail-inquiry-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-mail-inquiry-sub-title-text {
    font-size: 12px;
    font-weight: 500;
}

.m-a-mail-inquiry-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}

.m-a-mail-inquiry-input-button {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    position: relative;
}

.m-a-mail-inquiry-input-expand-icon {
    width: 8px;
    opacity: .3;
}

.m-a-mail-inquiry-type-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    background: white;
    top: 44px;
    left: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    border: 1px solid rgba(0, 0, 0, .1);

}


.m-a-mail-inquiry-type-item {
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 12px;
    align-content: center;

&:hover {
     background: var(--primary);
     color: white;
 }
}

.m-a-mail-inquiry-error {
    width: 100%;
    text-align: start;
    font-size: 12px;
    color: var(--red);
    margin-top: -8px;
    margin-left: 20px;
}

.m-a-mail-inquiry-input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    font-size: 12px;
    text-align: start;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: -4px;

&:focus {
     border: 1px solid rgba(0, 0, 0, .3);
     outline: none;
 }
}

.m-a-mail-inquiry-input::placeholder {
    font-size: 12px;
    font-family: 'Pretendard', sans-serif;
    color: var(--text);
}

.m-a-mail-inquiry-button {
    font-size: 12px;
    color: white;
    padding: 12px 20px;
    background-color: var(--primary);
    border-radius: 4px;
    min-width: 140px;
    text-align: center;
}

.mail-inquiry-content {
    height: 160px;
}
