:root { /*light*/
    --m-a-slider-profile-container-background-color: #fff;
    --m-a-body-container-background-color: #f2f2f6;
    --m-a-card-background-color: #fff;
    --m-a-card-box-shadow: rgba(63, 71, 77, 0.2) 0.03rem 0.05rem 6px;
    --m-a-card-active-box-shadow: rgba(63, 71, 77, 0.55) 0.05rem 0.07rem 7px;
    --m-a-value-badge-background-color: #d0d0d0;
    --m-a-000-and-fff-background-color: #fff;
    --m-a-backIcon-filter: none;
    --m-a-common-popup-container-background-color: rgba(0, 0, 0, 0.5);
    --m-a-common-popup-header-frame-background-color: #f2f2f6;
    --m-a-slider-ctg-container-background-color: rgba(0, 0, 0, 0.85);
    --m-a-div-tb-head-background-color: #f2f2f6;
    --m-a-p-t-border-border: solid 2px #fff;
    --m-a-user-dt-frame-background-color: #f2f2f6;
    --m-a-indicator-background-color: #e5e5e5;
    --m-a-indicator-active-background-color: #737373;
    --ad-navbar-border-bottom: 1px solid #dee2e6;
    --ad-navbar-background-color: #FFFFFF;
    --ad-navbar-z-index: 19;
    --ad-navbar-height: 55px;
    --main-contents-height: 100%;
    --main-contents-background-color: #f1f1f5;
    --main-contents-min-height: calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px));
    --ad-footer-background-color: #fff;
    --ad-footer-border-top: 1px solid #dee2e6;
    --ad-footer-color: #707277;
    --ad-footer-padding: 1rem;
    --ad-footer-height: 60px;
    --contents-color: #5b5b5b;
    --contents-b-color: #5b5b5b;
    --contents-box-background-color: #ffffff;
    --contents-box-dt-background-color: #ffffff;
    /*--double-border-bottom : double rgba(63, 119, 224, 0.8);*/
    --double-border-bottom : double rgba(194, 194, 194, 0.8);
    --btorage-background-color: #FFAF34;
    --btorage-color-border: solid 1px #FFAF34;
    --btorage-color: #FFAF34;
    --btorage-w-color: #FFF;
    --progress-color: #276ab7;
    --progress-color-b: #7C9EDC;
    --cancel-color: #ff0000;
    --accent-color: #ff7575;
    --success-color: #09bd12;
    --cancel-background-color: rgba(255, 0, 0, 0.67);
    --prog-background-color: #adadad;
    --contents-box-dt-box-shadow: rgba(63, 71, 77, 0.15) 0 2px 6px;
    --contents-box-dt-hover-box-shadow: rgba(63, 59, 59, 0.3) 0 2px 12px;
    --table-title-background-color: #f1f1f5;
    --div-tb-head-background-color: rgb(241, 241, 241);
    /*--div-tb-head-background-color: #dee2e6;*/
    --cooper-title-background-color: rgba(222, 226, 230, 0.5);
    --cooper-badge-background-color: #dbdde1;
    --main-td-hover-background: #f8f8f8;
    --table-color-border: solid 1px #dee2e6;
    --realTimeMsg-background-color: rgba(208, 208, 208, 0.4);
    --dark-task-padding: 15px;
    --dark-task-border-left: solid 1px #dee2e6;
    --dark-task-border-right: solid 1px #dee2e6;
    --dark-task-border-bottom: solid 1px #dee2e6;
    --dark-task-2px-border-bottom: solid 2px #dee2e6;
    --dark-task-border-top: solid 1px #dee2e6;
    --dark-task-border: solid 1px #dee2e6;
    --dark-task-chat-border: solid 1px #dee2e6;
    --dark-task-border-bottom-left-radius: 7px;
    --dark-task-border-bottom-right-radius: 7px;
    --fs-font-size: 13px;
    --lh-line-height: 24px;
    --tab-color: #FFAF34;
    --tab-border-bottom: none;
    --tab-border-left: 1px solid #dee2e6;
    --tab-border-right: 1px solid #dee2e6;
    --tab-border-top: 1px solid #dee2e6;
    --sidebar-background-color: #3c3f41;
    --ord-chat-background-color: #f5f5f5;
    --ord-chat-f-background-color: #e3e3e3;
    --font-b-font-weight: bold;
    --font-c-white-color: #5b5b5b;
    --alertFrame-background-color: #ffffff;
    --alertFrame-box-shadow: 0 0 10px 5px lightgrey;
    --admin-login-background-color: #ffffff;
    --admin-login-color: black;
    --ad-join-i-con-color: #393939;
    --JoinErrMsg-color: #FF8339;
    --admin-combo-background: url("../assets/img/ComboArrow_dob.png") no-repeat 90% 53%/11px auto #ffffff;
    --input-date-background: url("../assets/img/calender_clock_dark.png") no-repeat 91% 49%/14px auto #ffffff;;
    --admin-combo-color: #5b5b5b;
    --blurContainer-background-color: rgba(0, 0, 0, 0.3);
    --ad-ymd-input-color: #5b5b5b;
    --ck-buttonck-on-background-color : rgb(255, 183, 121);
    --ck-buttonck-on-hover-background-color : #e8e8e8;
    --ck-box-shadow: 2px 2px 3px rgba(0,0,0,.15) inset;
    --img-color-filter: invert(36%) sepia(20%) saturate(22%) hue-rotate(359deg) brightness(92%) contrast(93%);
    --back-black-and-white-background-color: 255, 255, 255;
    --back-black-and-white-nonrgb-background-color: white;
    --imoji-rgb-color: 91, 91, 91;
    --return-color: #707070;
    --ship-color: #0060ff;
    --admin-chat-rocket-img-filter: opacity(0.45) drop-shadow(0 0 0 #dee2e6);;
}
[data-theme="dark"] {
    --m-a-slider-profile-container-background-color: #000;
    --m-a-body-container-background-color: #000;
    --m-a-card-background-color: #2B2B2C;
    /*--m-a-card-box-shadow: rgba(255, 255, 255, 0.7) 0.03rem 0.02rem 6px;*/
    --m-a-card-box-shadow: none;
    --m-a-card-active-box-shadow: rgba(255, 255, 255, 0.9) 0.03rem 0.02rem 8px;
    --m-a-value-badge-background-color: #000;
    --m-a-000-and-fff-background-color: #000;
    --m-a-backIcon-filter: invert(90%);
    --m-a-common-popup-container-background-color: rgba(255, 255, 255, 0.5);
    --m-a-common-popup-header-frame-background-color: #2B2B2C;
    --m-a-slider-ctg-container-background-color: #000;
    --m-a-div-tb-head-background-color: #a8a8ab;
    --m-a-p-t-border-border: solid 2px #2B2B2C;
    --m-a-user-dt-frame-background-color: #2B2B2C;
    --m-a-indicator-background-color: #000000;
    --m-a-indicator-active-background-color: #e7e7e7;
    --ad-navbar-border-bottom: solid 1px #8d9191;
    --ad-navbar-background-color: #3c3f41;
    --main-contents-background-color: #3c3f41;
    --ad-footer-background-color: #3c3f41;
    --ad-footer-border-top: 1px solid #8d9191;
    --contents-color: #dee2e6;
    --contents-b-color: #dee2e6;
    --contents-box-background-color: #2b2b2c;
    --contents-box-dt-background-color: #4a4d56;
    /*--double-border-bottom : double rgb(75, 154, 232);*/
    --double-border-bottom : double rgba(152, 152, 152, 0.8);
    --btorage-background-color: #ffb327;
    --btorage-color-border: solid 1px #ffb327;
    --btorage-color: #FFAF1C;
    --btorage-w-color: #FFAF1C;
    --progress-color: #4c93e5;
    --progress-color-b: #94b9f8;
    --cancel-color: #ee5757;
    --accent-color: #efb8b8;
    --success-color: #48dc50;
    --cancel-background-color: #ee5757;
    --prog-background-color: #707070;
    --contents-box-dt-box-shadow: rgba(255, 255, 255, 0.4) 0 1px 8px;
    --contents-box-dt-hover-box-shadow: rgba(255, 255, 255, 0.5) 0 2px 15px;
    --table-title-background-color: #333641;
    --div-tb-head-background-color: #333641;
    --cooper-title-background-color: #333641;
    --cooper-badge-background-color: #4a4d56;
    --main-td-hover-background: #2b2b2c;
    --table-color-border: solid 1px #8d9191;
    --realTimeMsg-background-color: rgba(171, 176, 189, 0.4);
    --tab-background-color: #4a4d56;
    --dark-task-padding: 15px;
    --dark-task-border-left: solid 1px #8d9191;
    --dark-task-border-right: solid 1px #8d9191;
    --dark-task-border-bottom: solid 1px #8d9191;
    --dark-task-border-top: solid 1px #8d9191;
    --dark-task-border: solid 1px #8d9191;
    --dark-task-chat-border: solid 1px lightgray;
    --dark-task-border-bottom-left-radius: 7px;
    --dark-task-border-bottom-right-radius: 7px;
    --fs-font-size: 13px;
    --lh-line-height: 24px;
    --tab-color: #FFAF34;
    --tab-border-bottom: none;
    --tab-border-left: 1px solid #8d9191;
    --tab-border-right: 1px solid #8d9191;
    --tab-border-top: 1px solid #8d9191;
    --sidebar-background-color: #2b2b2c;
    --ord-chat-background-color: #4a4d56;
    --ord-chat-f-background-color: #535660;
    --font-b-font-weight: bold;
    --font-c-white-color: #ffffff;
    --alertFrame-background-color: #303235;
    --alertFrame-box-shadow: 0 0 10px 5px #e3ccbc;
    --admin-login-background-color: black;
    --admin-login-color: #ffffff;
    --ad-join-i-con-color: #ffffff;
    --JoinErrMsg-color: #FF8339;
    --admin-combo-background: url("../assets/img/ComboArrow_dob_negative.png") no-repeat 90% 53%/11px auto #2b2b2c;
    --input-date-background: url("../assets/img/calender_clock_light.png") no-repeat 91% 49%/14px auto #000;
    --admin-combo-color: #dee2e6;
    --blurContainer-background-color: rgba(255, 255, 255, 0.3);
    --ad-ymd-input-color: #dee2e6;
    --ck-buttonck-on-background-color : #FFAF34;
    --ck-buttonck-on-hover-background-color : #000000;
    --ck-box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.15) inset;
    --img-color-filter: invert(95%) sepia(10%) saturate(89%) hue-rotate(169deg) brightness(96%) contrast(89%);
    --back-black-and-white-background-color: 0, 0, 0;
    --back-black-and-white-nonrgb-background-color: black;
    --imoji-rgb-color: 222, 226, 230;
    --return-color: #bbbbbb;
    --ship-color: #0d7de5;
    --admin-chat-rocket-img-filter: invert(95%) sepia(10%) saturate(89%) hue-rotate(169deg) brightness(96%) contrast(89%);
}

.ad-navbar > * a, .ad-navbar > * b,.ad-navbar > * li,.ad-navbar > * label,.ad-navbar .icon-chg,.ad-navbar .ser-bt,
.main-contents > * a, .main-contents > * h4, .main-contents > * b, .main-contents .status-cont, .main-contents > * p, .main-contents > * label,
.main-contents > * strong,.ad-footer > * b,.wrapper .dark-icon,.wrapper .admin-work-title,.main-contents .main-td-hover td a,
.main-contents .admin-tb-body a, .main-contents .admin-tb-body-B a, .admin-tb-body-B a, .admin-btn-wrap-l, .wrapper .coupon-tr th,
.AdMain .real-time-icon, .AdMain .RealTimeMsg-usermsg > div > p, .RealTimeMsg-usermsg > div > b, .wrapper .ord-main-tb-head b,
.wrapper .ord-main-tb-body a, .nav-tabs >* .nav-link, .wrapper .ul.pagination li a, .wrapper .ul.pagination li:hover a, .route-wrap, .dark-font, .alertMessage
.alertFrame, .ad-ymd-input, .admin-search, .admin-search-input {
    color: var(--contents-color);
}
.dark-font-b {
    color: var(--contents-b-color);
}
.wrapper .msgbell-page-wrap, .wrapper .profile-page-wrap, .wrapper .admin-wrap-card, .admin-btn-wrap-r, .wrapper .status-board-order,
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, #ex-btn, .admin-chat-wrap, .ad-ymd-input, .admin-search, .admin-search-input, .dark-bg {
    background-color: var(--contents-box-background-color);
}
.wrapper .service-card-state .font-c-progress, .wrapper .ord-main-tb .font-c-progress {
    color: var(--progress-color);
}
.wrapper .ord-main-tb .font-c-cancel {
    color: var(--cancel-color);
}
.wrapper .ord-main-tb .font-c-success {
    color: var(--success-color);
}
.font-c-white {
    color: var(--font-c-white-color);
}
/*.main-contents .service-card, .main-contents .service-card-state, .main-contents .card-wrap-r,*/
.main-contents .admin-tb-body, .wrapper .ord-main-tb-body, .coupon-tr td, .tb-head-cont-dt, .admin-tb-body-B {
    background-color: var(--contents-box-dt-background-color);
}
.wrapper .admin-tb-head, .wrapper .wrapper .coupon-tr th, .wrapper, .tb-head-td-title, .admin-tb-head-B {
    background-color: var(--table-title-background-color);
}
.dark-title-body {
    background-color: var(--main-contents-background-color);
}
.main-contents .main-td-hover:hover {
    background: var(--main-td-hover-background);
}
.wrapper .admin-tb-body td, .wrapper .admin-tb-head th, .wrapper .ord-main-tb
, .wrapper .AdminOrdList, .dark-border, .admin-combo, .ad-ymd-input, .admin-search, .admin-input-line, .admin-dark-border {
    border: var(--table-color-border);
}
.AdMain .RealTimeMsg {
    background-color: var(--realTimeMsg-background-color);
}
.wrapper .tab-task, .wrapper .tab-temp {
    padding: var(--dark-task-padding);
    border-left: var(--dark-task-border-left);
    border-right: var(--dark-task-border-right);
    border-bottom: var(--dark-task-border-bottom);
    border-bottom-left-radius: var(--dark-task-border-bottom-left-radius);
    border-bottom-right-radius: var(--dark-task-border-bottom-right-radius);
}
.wrapper .ord-main-tb-head b {
    font-size: var(--fs-font-size);
    line-height: var(--lh-line-height);
}
.wrapper .ord-tab, .mandm-tab, .admin-Tabs, .gbn-border-b {
    border-bottom: var(--dark-task-border-bottom);
}
.dark-border-top {
    border-top: var(--dark-task-border-top);
}
.sidebar {
    background-color: var(--sidebar-background-color);
}
.admin-login, .wrap-login100, .AdminJoinPg, .wrap-join {
    background-color: var(--admin-login-background-color);
}
.admin-login > * div, .admin-login > * span, .admin-login #admin-join-bt, .AdminJoinPg > * div, .AdminJoinPg > * span {
    color: var(--admin-login-color);
}
.ad-join-i-con, .i-con-view, .admin-login > * input, .admin-login .ad-join-bt, .AdminJoinPg > * input {
    color: var(--ad-join-i-con-color);
}
.admin-combo {
    color: var(--admin-combo-color);
    background: var(--admin-combo-background);
}
.btorage-border {
    border: var(--btorage-color-border);
}
