.m-a-bottom-navigation-bar-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    height: 60px;
    background-color: var(--pale-background);
    display: flex;
    flex-direction: row;
    z-index: 30;
}

.m-a-bottom-navigation-bar-item {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.m-a-bottom-navigation-bar-icon {
    width: 24px;
    height: 24px;
    opacity: .3;
}

.m-a-bottom-navigation-bar-mask {
    width: 24px;
    height: 24px;
    background-color: var(--primary);
}

.m-a-bottom-navigation-bar-mask.home {
    mask-image: url("../../assets/img/mainHomeIcon.svg")
}

.m-a-bottom-navigation-bar-mask.lang {
    mask-image: url("../../assets/img/mainLangIcon.svg")
}

.m-a-bottom-navigation-bar-mask.order {
    mask-image: url("../../assets/img/mainHomeOrder.svg")
}

.m-a-bottom-navigation-bar-mask.mypage {
    mask-image: url("../../assets/img/mainHomeUser.svg")
}

.m-a-bottom-navigation-bar-text {
    font-size: 12px;
    position: relative;
}

.m-a-bottom-navigation-bar-new {
    position: absolute;
    padding: 0 4px;
    border-radius: 6px;
    background-color: var(--primary);
    font-size: 12px;
    top: -28px;
    right: -4px;
    color: white;
    font-weight: 300;
}

.m-a-bottom-navigation-bar-text.selected {
    color: var(--primary);
}

.m-a-bottom-navigation-pop-up-background {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, .3);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 40;
}

.m-a-bottom-navigation-pop-up-container {
    box-sizing: border-box;
    padding: 24px;
    width: 80%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    z-index: 50;
}

.m-a-bottom-navigation-pop-up-title {
    font-size: 16px;
    margin-bottom: 20px;
}

.m-a-bottom-navigation-pop-up-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px 20px;
    margin-bottom: 8px;
}

.m-a-bottom-navigation-pop-up-item-img {
    width: 24px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.m-a-bottom-navigation-pop-up-item-text {
    font-size: 14px;
}

.m-a-bottom-navigation-pop-up-item.raw {
    font-size: 14px;
    justify-content: center;
}

.m-a-bottom-navigation-pop-up-button {
    padding: 12px 24px;
    background-color: var(--primary);
    border-radius: 20px;
    color: white;
    font-size: 14px;
    margin-top: 8px;
}

.m-a-bottom-navigation-bar-container-new {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    height: 60px;
    background-color: var(--pale-background);
    display: flex;
    flex-direction: row;
    z-index: 30;
}

.m-a-bottom-navigation-bar-item-new {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.m-a-bottom-navigation-bar-icon-new {
    width: 24px;
    height: 24px;
    opacity: .3;
}

.m-a-bottom-navigation-bar-mask-new {
    width: 24px;
    height: 24px;
    background-color: var(--primary);
}

.home-new {
    mask-image: url("../../assets/img/mainHomeIcon.svg")
}

.lang-new {
    mask-image: url("../../assets/img/mainLangIcon.svg")
}

.order-new {
    mask-image: url("../../assets/img/mainHomeOrder.svg")
}

.mypage-new {
    mask-image: url("../../assets/img/mainHomeUser.svg")
}

.m-a-bottom-navigation-bar-text-new {
    font-size: 12px;
    font-weight: 400;
    position: relative;
}

.m-a-bottom-navigation-bar-new-new {
    position: absolute;
    padding: 0 4px;
    border-radius: 6px;
    background-color: var(--primary);
    font-size: 12px;
    top: -28px;
    right: -4px;
    color: white;
    font-weight: 300;
}

.selected-new {
    color: var(--primary);
}

.mYpg-Alarm {
    width: 14px;
    height: 16px;
    border-radius: 4px;
    border: none;
    background-color: #FFAF34;
    color: white;
    font-size: 10px;
    padding-top: 1px;
}
