.m-a-order-dt-container {
    box-sizing: border-box;
    width: 100vw;
    height: calc(100vh - 50px);
    padding: 50px 15px 50px 15px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-order-detail-section-frame-new {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.m-a-order-detail-section-frame-box-new {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.m-a-order-detail-select {
    width: 100%;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 40px;
    font-size: 12px;
    border-radius: 4px;
}

.m-a-order-detail-select-list-new {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    top: 40px;
    left: 0;
    z-index: 30;
    position: absolute;
    max-height: 200px;
    overflow-y: scroll;
}

.m-a-order-detail-select-item-new {
    width: 100%;
    font-size: 12px;
    padding: 8px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
}


.m-a-order-detail-section-checklist-box-new {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.m-a-order-detail-section-checklist-new {
    width: 100%;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    gap: 12px;
}


.m-a-order-dt-chat-container {
    width: 100%;
    height: 630px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .3);
    padding-top: 2px;
}

.m-a-order-dt-input-frame {
    width: 100%;
    background-color: white;
    min-height: 25%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


/*.m-a-order-detail-order-table {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/

/*.m-a-order-detail-order-table-row {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    height: 40px;*/
/*    gap: 8px;*/

/*.m-a-order-detail-order-table-label {*/
/*    width: 30%;*/
/*    height: 100%;*/
/*    font-size: 12px;*/
/*    background-color: var(--table-label);*/
/*    text-align: center;*/
/*    align-content: center;*/
/*}*/

/*.m-a-order-detail-order-table-value {*/
/*    font-size: 12px;*/
/*    flex: 1;*/
/*    height: 36px;*/
/*    border: 1px solid rgba(0, 0, 0, .1);*/
/*    box-sizing: border-box;*/
/*    padding: 0 12px;*/
/*    align-content: center;*/

/*.m-a-order-detail-order-table-value-inside {*/
/*    width: 100%;*/
/*    padding: 0;*/
/*    height: 32px;*/
/*    border: none;*/
/*    outline: none;*/
/*}*/
/*}*/


/*.m-a-order-detail-order-table-value.flex-row {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    gap: 8px;*/
/*}*/

/*.m-a-order-detail-order-table-value.no-border {*/
/*    border: none;*/
/*    padding: 0;*/
/*    font-weight: 600;*/
/*}*/
/*}*/
/*}*/
