.m-a-coupon-use-price-detail-background {
    width: 100vw;
    height: 100vh;
    z-index: 40;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
}

.m-a-coupon-use-price-detail-container {
    width: calc(100% - 48px);
    max-height: 80%;
    overflow-y: scroll;
    background: white;
    z-index: 50;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-coupon-use-price-detail-header {
    width: 100%;
    position: relative;
    min-height: 44px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-coupon-use-price-detail-header-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: black;
    top: 12px;
}

.m-a-coupon-use-price-detail-header-icon {
    position: absolute;
    width: 24px;
    right: 20px;
    top: 10px;
    opacity: .4;
}

.m-a-coupon-use-price-detail-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
}

.m-a-coupon-use-price-detail-table-title {
    width: 100%;
    height: 40px;
    background: var(--background);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: black;
    box-sizing: border-box;
    padding: 0 12px;
}

.m-a-coupon-use-price-detail-table-icon {
    width: 20px;
    opacity: .6;
}

.m-a-coupon-use-price-detail-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: scroll;
    border: 1px solid rgba(0, 0, 0, .1);

}
.m-a-coupon-use-price-detail-table-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-coupon-use-price-detail-table-row.label {
    height: 40px;
    font-size: 12px;
    color: black;
    align-content: center;
}

.m-a-coupon-use-price-detail-table-item {
    width: 30%;
    min-width: 30%;
    height: 36px;
    min-height: 36px;
    align-content: center;
    font-size: 12px;
    text-align: center;
}

.m-a-coupon-use-price-detail-table-item.sm {
    width: 10%;
    min-width: 10%;
    height: 40px;
}


.m-a-coupon-use-price-detail-divider {
    width: 100%;
    min-height: 1px;
    background: rgba(0, 0, 0, .1);
}

.m-a-coupon-use-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 8px;
    justify-content: center;
    margin-top: 20px;
}

.m-a-coupon-use-button {
    font-size: 12px;
    background: var(--primary);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
}

.m-a-coupon-use-button.outline {
    background: white;
    border: 1px solid var(--primary);
    color: var(--primary);
}
