.FindContainer {
    display: block;
    box-sizing: border-box;
}
.FindMain {
    height: 700px;
    margin-top: 90px;
}
.FindFrame {
    border-style: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    border-radius: 8px;
    width: 500px;
    margin: auto;
    padding: 40px;
    position: relative;
}
.FindFramePgLogo {
    width: 45%;
    margin: auto;
    height: 70px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    padding: 18px 23px;
}
.FindPgIdPwContainer {
    margin-top: 50px;
    height: 500px;
}
.FindHandleButton {
    border-style: solid;
    border-radius: 8px;
    border-color: rgba(255,120,0,0.78);
    font-size: 20px;
    color: rgba(255,120,0,0.78);
    padding: 15px;
    margin: 0 10px 30px 6px;
    width: 190px;
}
.FindHandleButton:hover {
    color: white;
    background: rgba(255,120,0,0.78);
    border-color: white;
}
.FindHandleButton:focus {
    background: rgba(255,120,0,0.78);
    border-color: white;
    --bs-btn-focus-shadow-rgb: 255, 120, 88;
}
.FindHandleButton:active {
    color: white;
    background: rgba(255,120,0,0.78);
    border-color: white;
    box-shadow: inset 0 3px 5px #FFAF34;
}
.FindPgButton {
    height: 50px;
    margin-top: 30px;
}





