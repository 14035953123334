:root {
    --primary: #FFAF34;
    --secondary: #FFBE48;
    --strong: #E38800;
    --text: #5b5b5b;
    --background: #EEEEEE;
    --pink: #F99A9C;
    --pale: #AAAAAA;
    --pale-background: #FEF9F3;
    --pale-skyblue: #F5F8FE;
    --table-label: #F7F1F1;
    --red: #FF4B4B;
    --chat-background: #FEF5E4;
    --chat-header: #FFE4B5;
    --text-blue: #A1AED0;
    color-scheme: light only;
}

@font-face {
    font-family: 'LeferiPoint-BlackA';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/LeferiPoint-BlackA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'LINESeedKR-Bd';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Bd.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

a, b {
    text-decoration: none;
    color: var(--contents-color);
}

a {
    /*line-height: 1.7;*/
    /*margin: 0;*/
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    -moz-transition: all 0.1s;
}

a:hover, strong:hover, Link:hover, h4:hover, h5:hover {
    text-decoration: none;
    color: #FFAF34;
}

a:focus {
    outline: none !important;
}
p {
    margin: 0;
}
h1, h2, h3, h4, h5, strong {
    font-family: Noto Sans KR, sans-serif;
}

strong, h1, h2, b {
    margin: 0;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
}

button {
    outline: none !important;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

input {
    accent-color: rgb(255, 97, 0); /*체크박스, 라디오버튼 색상*/
    outline: none;
}
