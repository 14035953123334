.m-a-compare-ship-pg-container {
    box-sizing: border-box;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 20px 24px;
    overflow-y: scroll;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-compare-ship-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-compare-ship-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-compare-ship-sub-title-text {
    font-size: 14px;
}

.m-a-compare-ship-divider {
    width: 100%;
    min-height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}

.m-a-compare-ship-weight-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.m-a-compare-ship-weight-frame-grid {
    width: 100%;
    height: 44px;
    /*display: grid;*/
    align-items: center;
    /*grid-template-columns: repeat(4, 1fr);*/
    /*grid-template-rows: repeat(1, 1fr);*/
    gap: 8px;
}

.m-a-compare-ship-weight-frame-grid-label {
    /*min-width: 96px;*/
    width: 30%;
    box-sizing: border-box;
    /*padding: 0 6px;*/
    height: 45px;
    font-size: 13px;
    text-align: start;
    align-content: center;
    color: var(--text);
    /*background: var(--table-label);*/
}

.m-a-compare-ship-weight-frame-grid-value {
    display: flex;
    box-sizing: border-box;
    padding: 0 8px;
    height: 45px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    grid-column: span 3;

&:focus {
     border: 1px solid var(--primary);
 }
}

.m-a-compare-ship-weight-frame-grid-value.tripple {
    width: 20%;
}

.m-a-compare-ship-weight-frame-grid-value.country {
    grid-column: span 3;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.m-a-compare-ship-weight-frame-grid-expand-icon {
    width: 8px;
    opacity: .6;
}

.m-a-compare-ship-weight-frame-country-list {
    width: 100%;
    position: absolute;
    left: 0;
    top: 40px;
    background: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 240px;
    overflow-y: scroll;
    z-index: 30;
}

.m-a-compare-ship-weight-frame-country-item {
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    padding: 8px 20px;
}

.m-a-compare-ship-weight-frame-country-item.selected {
    background: var(--primary);
    color: white;
}

.m-a-compare-ship-weight-frame-grid.volume {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.m-a-compare-ship-button {
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    font-size: 12px;
    font-weight: 500;
    color: var(--primary);
    margin-top: 12px;
    margin-bottom: 12px;
}

.m-a-compare-ship-delivery-fee-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.m-a-compare-ship-delivery-fee-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-compare-ship-delivery-fee-row-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.m-a-compare-ship-delivery-fee-agency {
    font-size: 14px;
    color: black;
}

.m-a-compare-ship-delivery-fee-weight {
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.m-a-compare-ship-delivery-fee-weight-strong {
    display: inline-block;
    color: var(--primary);
}

.m-a-compare-ship-delivery-fee-row-right {
    font-size: 14px;
    color: var(--primary);
}

.m-a-compare-ship-delivery-fee-row-right.normal {
    color: black;
}

.m-a-compare-ship-description {
    width: 100%;
    text-align: start;
    font-size: 12px;
    margin-top: 12px;
}
