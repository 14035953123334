.guide-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.guide-frame {
    min-width: 1280px;
    width: 1280px;
}

.guide-frame-m {
    min-width: 390px;
    width: 420px;
    border-radius: 12px;
    border: none;
    box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
    -webkit-box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
    -moz-box-shadow: 0 0 7px 5px rgba(0,0,0,0.18);
    padding: 0 12px;
}
.guide-frame-m:hover {
    box-shadow: 0 0 9px 5px rgba(0,0,0,0.34);
    -webkit-box-shadow: 0 0 9px 5px rgba(0,0,0,0.34);
    -moz-box-shadow: 0 0 9px 5px rgba(0,0,0,0.34);
}

.gf-gbn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.gf-title {
    width: 100%;
}

.gf-title-input {
    width: 100%;
    height: 50px;
    font-size: 22px;
    border: none;
    border-bottom: 1px solid #b9b9b9;
    padding-left: 5px;
}

.gf-cont {
    width: 100%;
    padding-top: 15px;
}

.gf-cont-form {
    width: 100%;
    border: none;
    outline: none;
    min-height: 700px;
    overflow-y: auto;
    padding-left: 5px;
    border-bottom: 1px solid #b9b9b9;
}

.g-file-upload {
    position: fixed;
    top: 420px;
    left: 380px;
    transform: translateY(-50%);
    z-index: 1000;
}
.g-file-upload-m {
    position: fixed;
    top: 420px;
    left: 740px;
    transform: translateY(-50%);
    z-index: 1000;
}

.g-img-upload {
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .07);
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.4s cubic-bezier(.55,1.6,.35,1);
}

.g-img-upload:hover {
    background-color: #f6f6f6;
}

.options-menu {
    position: absolute;
    width: 120px;
    top: 43px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.options-menu div {
    display: block;
    padding: 7px 0;
    width: 100%;
    cursor: pointer;
}
.options-menu div:hover {
    background-color: #f6f6f6;
}
