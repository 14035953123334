.tab-menu {
    font-family: Arial, sans-serif;
    width: 100%;
    margin-top: 100px;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #EAEAEA;
    padding: 0;
}

.tab {
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 15px;
    color: dimgrey;
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}



.tab.active {
    background-color: #FFC762;
    color: white;
    width: 240px;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.1);
}

.content {
    padding: 6px 12px;
    background-color: white;
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    border-top: none;
    height: auto;
}

.mypgFilterBt {
    width: 100%;
    height: 50px;
    border: none;
    color: dimgrey;
}
.mypgFilterBt:hover {
    background-color: #FF9924;
    color: white;
}
.mypgFilterBt:focus-within {
    background-color: darkorange;
    color: white;
}
.mypgSerchInput:focus-within {
    outline-color: rgba(255,158,33,0.45);
}
.mypgSerchInput::placeholder {
    color: #c1bdbc;
}
.mypgContainer img{
    filter: opacity(0.6) drop-shadow(0 0 0 lightgrey);
}
.mypgOrderCr div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    /*color: rgba(112,112,109,0.67);*/
}
#myPgSticky {
    position: sticky;
    top: 0;
    left: 34%;
}

