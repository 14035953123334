@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4000;
}

.circle-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    height: 53px;
}

.center-logo {
    width: 20px;
    height: auto;
    position: absolute;
}

.progress-ring__circle-bg, .progress-ring__circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.progress-ring__circle {
    animation: circle-animation 2s linear infinite;
    stroke-dasharray: 32 125.6;
    stroke-dashoffset: 0;
    stroke-linecap: round;
}

@keyframes circle-animation {
    0% {
        stroke-dashoffset: 125.6;
    }
    100% {
        stroke-dashoffset: -32;
    }
}
