.m-a-etc-price-detail-background {
    width: 100vw;
    height: 100vh;
    z-index: 40;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
}

.m-a-etc-price-detail-container {
    width: calc(100% - 48px);
    max-height: 88vh;
    overflow-y: scroll;
    background: white;
    z-index: 50;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-etc-price-detail-header {
    width: 100%;
    position: relative;
    min-height: 44px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-etc-price-detail-header-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: 600;
    color: black;
    top: 12px;
}

.m-a-etc-price-detail-header-icon {
    position: absolute;
    width: 24px;
    right: 20px;
    top: 10px;
    opacity: .4;
}

.m-a-etc-price-detail-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
}

.m-a-etc-price-detail-table-title {
    width: 100%;
    height: 40px;
    background: var(--background);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: black;
    box-sizing: border-box;
    padding: 0 12px;
}

.m-a-etc-price-detail-table-icon {
    width: 20px;
    opacity: .6;
}

.m-a-etc-price-detail-info-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: scroll;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 12px;
    gap: 8px;
}

.m-a-etc-price-detail-info-icon {
    width: 32px;
    margin-top: 12px;
}

.m-a-etc-price-detail-info-row {
    width: 100%;
    align-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-etc-price-detail-info-text {
    font-size: 12px;
}

.m-a-etc-price-detail-info-text.bold {
    font-size: 14px;
    color: black;
}

.m-a-etc-price-detail-info-text.primary {
    color: var(--primary);
}

.m-a-etc-price-detail-info-row.margin-top {
    margin-top: 12px;
}

.m-a-etc-price-detail-divider {
    width: 100%;
    min-height: 1px;
    background: rgba(0, 0, 0, .1);
}

.m-a-etc-price-detail-divider-bold {
    width: 100%;
    min-height: 4px;
    background: rgba(0, 0, 0, .1);
    margin: 32px 0;
}
