.m-a-file-content-modifyFile-frame {
    height: 100%;
}
.m-a-file-content-modifyFile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}
.m-a-file-content-modifyFile-img {
    max-width: 220px;
    max-height: 220px;
}
.m-a-file-content-exchange-button {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.m-a-file-content-exchange-button-img {
    width: 40px;
    height: 40px;
}
