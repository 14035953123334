.m-a-user-chat-alarm-pg-container {
    box-sizing: border-box;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 20px 24px;
    overflow-y: scroll;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}


.m-a-chat-alarm-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}


.m-a-chat-alarm-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-chat-alarm-sub-title-text {
    font-size: 14px;
}

.m-a-chat-alarm-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}

.m-a-chat-alarm-frame {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.m-a-chat-alarm-frame:hover {
    background-color: #f5f5f5;
}

.m-a-chat-alarm-frame-img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
}

.m-a-chat-alarm-frame-length {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: var(--primary);
    border-radius: 50%;
    font-size: 10px;
    color: white;
    top: 3px;
    left: 55px;
    z-index: 10;
    text-align: center;
    align-content: center;
}

.m-a-chat-alarm-frame-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.m-a-chat-alarm-frame-info-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 49%;
    width: 100%;
    align-items: end;
}

.m-a-chat-alarm-frame-info-content {
    font-size: 14px;
    width: 100%;
    height: 51%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
