.m-a-dep-charg-pg-container {
    box-sizing: border-box;
    padding: 0 24px;
    margin-bottom: 60px;
    width: 100vw;
    min-height: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    /*margin-top: 40px;*/
}

.m-a-dep-charg-sub-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
}


.m-a-dep-charg-sub-title-icon {
    width: 24px;
}

.m-a-dep-charg-sub-title-text {
    font-size: 14px;
    font-weight: 500;
}

.m-a-dep-charg-pg-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.m-a-dep-charg-pg-top-right {
    font-size: 12px;
    font-weight: 600;
    color: var(--text-blue);
}

.m-a-dep-charg-pg-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.m-a-dep-charg-pg-button-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-dep-charg-pg-button {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 12px 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
}

.m-a-dep-charg-pg-button.selected {
    border: 2px solid var(--primary);
}

.m-a-dep-charg-pg-bottom-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 12px;
}

.m-a-dep-charg-pg-binary-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.m-a-dep-charg-pg-binary-selector-item {
    width: 80px;
    padding-bottom: 8px;
    font-size: 12px;
    text-align: center;
    border-bottom: 2px solid transparent;
}

.m-a-dep-charg-pg-binary-selector-item.selected {
    border-bottom: 2px solid var(--primary);
}
.m-a-dep-charg-pg-notice {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.m-a-dep-charg-pg-notice-text-box {
    width: 100%;
    text-align: start;
    font-size: 12px;
    line-height: 1.4;
}

.m-a-dep-charg-pg-notice-text.highlight {
    display: inline-block;
    color: var(--primary);
}

.m-a-dep-charg-pg-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
}

.m-a-dep-charg-pg-button-outline {
    width: 80px;
    padding: 8px 0;
    box-shadow: 0 0 0 1px var(--primary);
    border-radius: 4px;
    color: var(--primary);
    font-size: 12px;
    text-align: center;
    font-weight: 600;
}

.m-a-dep-charg-pg-button-fill {
    width: 80px;
    padding: 8px 0;
    border-radius: 4px;
    background-color: var(--primary);
    color: white;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
}

.m-a-dep-charg-pg-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}

.m-a-dep-charg-pg-table-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin: 12px 0;
}

.m-a-dep-charg-pg-table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.m-a-dep-charg-pg-table-row {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-dep-charg-pg-table-item {
    width: 60%;
    height: 100%;
    text-align: start;
    font-size: 12px;
    box-sizing: border-box;
    padding: 12px 0;
}

.m-a-dep-charg-pg-table-input {
    width: 60%;
    height: 36px;
    box-sizing: border-box;
    padding: 12px;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, .1);

&:focus {
     outline: none;
     border: 1px solid rgba(0, 0, 0, .3);
 }
}

.m-a-dep-charg-pg-table-item.label {
    padding: 12px;
    width: 40%;
    background-color: var(--chat-background);
    color: var(--text);
}

.m-a-dep-charg-pg-table-item-unit {
    display: inline-block;
    padding: 2px 6px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    margin-left: 4px;
    font-size: 11px;
    position: relative;
}

.m-a-dep-charg-pg-table-dollar-list {
    width: 100%;
    position: absolute;
    left: 0;
    top: 24px;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    border-radius: 4px;
    z-index: 30;
}

.m-a-dep-charg-pg-table-dollar-item {
    width: 100%;
    height: 28px;
    font-size: 11px;
    text-align: center;
    align-content: center;
}

.m-a-dep-charg-pg-table-dollar-item.selected {
    background: var(--primary);
    color: white;
}

.m-a-dep-charg-pg-unit-popup-frame {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .3);
    width: 100vw;
    height: 100vh;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-a-dep-charg-pg-unit-popup {
    width: 60%;
    max-height: 300px;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    opacity: .8;
}


.m-a-dep-charg-pg-unit-items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    margin-bottom: 56px;
}


.m-a-dep-charg-pg-unit-item {
    width: 100%;
    min-height: 48px;
    background-color: white;
    align-content: center;
    font-size: 20px;
    border-radius: 4px;
    z-index: 30;
}

.m-a-dep-charg-pg-unit-popup-button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 48px;
    background-color: var(--primary);
    color: white;
    font-size: 18px;
    align-content: center;
    z-index: 100;
}

