.btcorpad {
    margin: 0;
    width: 100%;
    min-width: 1720px;
    max-width: 1920px;
    /*height : 100vh;*/
}
.wrapper {
    height: 100vh;
    position: relative;
}

/********** nav start **********/
.AdNavbar {
    /*width: 100%;*/
    margin-left: 250px;
}
.ad-navbar {
    background-color: var(--ad-navbar-background-color);
    border-bottom: var(--ad-navbar-border-bottom);
    z-index: var(--ad-navbar-z-index);
    height: var(--ad-navbar-height);
}
.navbar {
    position: relative;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    vertical-align: middle;
    padding: 0.5rem 0.5rem;
}
.navbar-ctg .item-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
}
.item-link {
    cursor: pointer;
    display: block;
}
.item-pro {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
}
.profile {
    position: relative;
    line-height: normal;
    border-radius: 50%;
}
.item-link-sub {
    border-radius: 50%;
}

.ling-pro {
    border-radius: 50%;
    height: auto;
    width: 30px;
    display: inline-block;
    border-style: none;
}
.default-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-style: none;
}
.pro-img {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-style: none;
}
.pro-img:hover {
    border: solid 1px #efe5e5;
}
.profile-page-wrap {
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: absolute;
    top: 6px;
    right: -5px;
    width: 200px;
    height: 135px;
    box-shadow: 0 0 3px rgba(134, 134, 134, 0.7);
    border-radius: 5px;
    background-color: #ffffff;
    z-index: 10;
    justify-content: space-around;
}
.msgbell-page-wrap {
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: absolute;
    top: 8px;
    right: -13px;
    width: 220px;
    box-shadow: 0 0 3px rgba(134, 134, 134, 0.7);
    border-radius: 5px;
    background-color: #ffffff;
    z-index: 10;
    justify-content: space-around;
}
.profile-modi {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 5px 5px 5px 10px;
}
.msgbell-chat {
    display: flex;
    height: 52px;
    flex-direction: row;
    border-radius: 5px;
    padding: 6px 3px 3px 3px;
}
.admin-chat-profile-mini {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.admin-chat-msg-mini {
    margin-left: 10px;
    margin-top: 4px;
}
.pro-img-mini {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-style: none;
}
.link-font {
    font-size: 15px;
}
.link-font-msg {
    font-size: 13px;
}
.profile-modi:hover, .msgbell-chat:hover {
    background-color: rgba(246, 246, 246, 0.7);
}
.item-ham {
    margin-right: 40px;
}
.link-item {
    width: 35px;
    left : 0;
}
.item-user {
    cursor: default;
}
.link-icon {
    font-size: 16px;
}
.icon-user {
    font-size: 28px;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
}
.drop-icon-sub {
    color: #5b5b5b;
}
.ad-navbar .item-link {
    position: relative;
    margin: 0;
}
.on-off {
    /*margin-left: 10px;*/
    margin-right: -20px;
    margin-top: 6px;
}
.switch-main * {
    transition: 0.15s ease-out;
}
.switch-main label {
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 18px;
    cursor: pointer;
}
.switch {
    width: 50px;
    height: 19px;
}
.switch-input {
    position: absolute;
    transform: translate3d(5px, 5px, 0);
    outline: none;
}
.switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 11px;
    font-weight: 300;
    background: #e53030;
    border-radius: inherit;
}
.switch-label:before, .switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -0.6em;
    line-height: 1.1;
}
.switch-label:before {
    content: attr(data-off);
    color: #ffffff;
    outline: none;
}
.switch-label:after {
    content: attr(data-on);
    color: #ffffff;
    opacity: 0;
    outline: none;
}
.switch-label:before {
    right: 11px;
    outline: none;
}
.switch-label:after {
    left: 8px;
    outline: none;
}
.switch-input:checked ~ .switch-label {
    background: #1cc441;
}
.switch-input:checked ~ .switch-label:before {
    opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
    opacity: 1;
}
.switch-handle {
    position: absolute !important;
    top: 0.212rem;
    left: 3px;
    background: white;
    border-radius: 20px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.switch-handle {
    width: 13px;
    height: 13px;
}
.switch-handle:before {
    content: "";
    top: 50%;
    left: 50%;
    position: absolute !important;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
}
.switch-label:active ~ .switch-handle, .switch-handle:active {
    width: 30px;
}
.switch-input:checked ~ .switch-handle {
    left: unset;
    right: 3px;
}
.user-home {
    margin-left: 20px;
}
.icon-chg:hover {
    animation-duration: .8s;
    animation-name: rainbowLink;
    animation-iteration-count: infinite;
    /*transform: rotate(1turn);*/
    transition: 2s;
    transform: translateY(-0.7rem) scale(1.1, 1.1);
}
@keyframes rainbowLink {
    /*0% { color: #ff2a2a; }*/
    /*15% { color: #ff7a2a; }*/
    /*30% { color: #ffc52a }*/
    /*45% { color: #43ff2a; }*/
    /*60% { color: #2a89ff; }*/
    /*75% { color: #202082; }*/
    /*90% { color: #6b2aff; }*/
    /*100% { color: #e82aff; }*/
    0% { color: #ffd300; }
    15% { color: #ffbf14; }
    30% { color: #ffa51f; }
    45% { color: #ff9529; }
    60% { color: #ff9b54; }
    75% { color: #ff7800; }
    90% { color: #ff6420; }
    100% { color: #ff3d14; }
}

input[id=trigger] {
    display: none;
    transform : scale(1.6);
}
label[for=trigger] {
    width: 14px;
    height: 12px;
    display: block;
    position: relative;
}
label[for=trigger] span {
    position: absolute;
    height: 2px;
    background-color: #7a7777;
    border-radius: 2px;
    width: 100%;
    left: 0;
    transition: .1s linear;
}
label[for=trigger] span:nth-child(1) {
    width: 12px;
    height: 12px;
    top: 0;
    transform : translate(0, 0) rotate(45deg);
    border: 2px solid;
    background: none;
    border-top: none;
    border-right: none;
}
label[for=trigger] span:nth-child(2) {
    width: 12px;
    height: 12px;
    left: -10%;
    transform : translate(9px, 0) rotate(45deg);
    border: 2px solid;
    background: none;
    border-top: none;
    border-right: none;
}
label[for=trigger] span:nth-child(3) {
    width: 12px;
    height: 12px;
    left: -20%;
    transform : translate(15px, 0) rotate(45deg);
    border: 2px solid;
    background: none;
    border-top: none;
    border-right: none;
    opacity: 0;
}
input[id=trigger]:checked + label[for=trigger] span:nth-child(1) {
    width : 20px;
    height: 2px;
    transform : rotate(0deg);
    top: 0;
    left: 0;
}
input[id=trigger]:checked + label[for=trigger] span:nth-child(2) {
    width : 20px;
    height: 2px;
    transform : rotate(0deg);
    left: 0;
    top: 50%;
}
input[id=trigger]:checked + label[for=trigger] span:nth-child(3) {
    width : 20px;
    height: 2px;
    transform : rotate(0deg);
    top: 100%;
    left: 0;
    opacity: 1;
}
.badge {
    display: inline-block;
    padding: 0.3em 0.5em;
    /*font-size: 75%;*/
    /*font-weight: 700;*/
    /*line-height: 1;*/
    /*text-align: center;*/
    /*white-space: nowrap;*/
    /*vertical-align: baseline;*/
    border-radius: 30%;
}
.badge:hover {
    transform: scale(1.2);
    background-color: rgba(229, 48, 48);
    transition: all .5s;
}
.navbar-badge {
    font-size: 10px;
    padding: 2px 4px;
    position: absolute;
    right: 3px;
    top: -4px;
}
.badge-danger {
    color: #fff;
    background-color: rgba(229, 48, 48, 0.7);
}
.msg-chk {
    position: absolute;
    transform: translate3d(3px, 7px, 0);
    display: none;
}
.ser-item-wrap {
    display: inline-block;
    position: relative;
    /*margin-bottom: 2px;*/
}
input[id="search_submit"] {
    cursor: pointer;
    display: block;
    width: 35px;
    height: 28px;
    float: left;
    text-indent: -10000px;
    border: none;
    position: absolute;
    /*top: 6px;*/
    /*right: 0;*/
    z-index: 2;
    transition: opacity .3s ease;
    background: none;
}
.ctg-drop {
    position: relative;
}
/********** nav end **********/



/********** side start **********/
/*.btcorpad .sidebar {*/
/*    bottom: 0;*/
/*    float: none;*/
/*    position: fixed;*/
/*    top: 0;*/
/*    transition: .1s ease-in-out,width .6s ease-in-out;*/
/*}*/
.sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    /*bottom: 0;*/
    float: none;
    position: fixed;
    top: 0;
    transition: .1s ease-in-out,width .6s ease-in-out;
    overflow-y: hidden;
    z-index: 19;
    background-color: var(--sidebar-background-color);
    box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.3);
}
.sidebar:hover {
    left: 0;
    transition: .4s ease-in;
}

.fade-in {
    opacity: 1;
    left: -180px;
    animation: 0.4s ease-in-out forwards;
}

.fade-out {
    opacity: 1;
    left: 0;
    animation: 0.4s ease-in-out forwards;
}
.cont-left {
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out;
}

.cont-right {
    margin-left: -180px;
    transition: margin-left 0.3s ease-in-out;
}

.show-sidebar {
    transform: translate(0);
}
.sidebar a:-moz-focusring {
    border: 0;
    outline: none;
}
.sidebar, .sidebar::before {
    width: 250px;
}
.navbar-ctg {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    margin-right: 12px;
    list-style: none;
    justify-content: center;
    align-items: center;
}
.sidebar .navbar-ctg {
    -ms-flex-direction: row;
    flex-direction: row;
}
.side-logo-wrap {
    display: flex;
    flex-direction: row;
    height: 55px;
    justify-content: center;
    border-bottom: 1px solid #8d9191;
}
.logo-tag {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
.ad-main-logo {
    /*filter: invert(100%) sepia(5%) saturate(18%) hue-rotate(113deg) brightness(106%) contrast(104%);*/
    /*vertical-align: middle;*/
    /*margin-right: 8px;*/
    margin-top: 10px;
    /*height: 38px;*/
    /*color: #FF7800;*/
}
.ad-main-text {
    vertical-align: middle;
    font-size: 30px;
    /*color: #dee2e6;*/
    color: #FFAF34;
}
.side-main {
    /*height: calc(90%);*/
    height: calc(90% - (1rem + 1px));
    overflow-x: hidden;
    overflow-y: initial;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.side-main a {
    font-size: 17px;
    color: #dee2e6;
    padding-left: 20px;
}
.side-main li {
    color: #dee2e6;
    /*color: #ffffff;*/
}
.side-main::-webkit-scrollbar {
    width: 8px;
    background: #3c3f49;
}
.side-main::-webkit-scrollbar-thumb {
    background: #646772;
    border-radius: 2px;
}
.side-main::-webkit-scrollbar-thumb:hover {
    background: #dee2e6;
}
.side-main .nav-aco:hover {
    border-radius: 0.3rem;
    background-color: rgba(222, 226, 230, 0.1);
}
.side-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.nav-menu {
    display: flex;
    flex-direction: column;
}
.nav-footer {
    display: flex;
    flex-direction: row;
    height: 60px;
}
.nav-footer span {
    margin: auto;
}
.footer-bt {
    color: #dee2e6;
}
.menu-ctg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2rem 0.6rem 0.5rem 0.2rem;
    margin-left: 15px;
    cursor: pointer;
}
.icon-l-wrap, .icon-r-wrap {
    /*height: 41px;*/
    /*margin-left: -0.3rem;*/
}
.icon-r-wrap {
    /*margin-left: 15px;*/
    /*margin-right: 1.5rem;*/
}
.icon-m-wrap {
    margin-right: 30px;
}
.side-icon-l {
    font-size: 20px;
}
.side-icon-r {
    font-size: 18px;
}
.side-icon-m {
    font-size: 17px;
}
.ctg-sum {
    font-size: 19px;
    list-style: none;
    width: 140px;
    padding-top: 2px;
    padding-right: 8px;
    text-align: left;
}
.ctg-dt {
    /*font-size: 17px;*/
    list-style: none;
    text-align: left;
    margin-left: 2px;
}
.nav-top-item {
    margin-top: 3px;
}
.light-moon:hover {
    color: #ffc200;
}
.dark-sun:hover {
    color: #ff5c35;
}
/********** side end **********/



/********** contents start **********/
.AdMain {
    margin-left: 250px;
}
.main-contents {
    height: var(--main-contents-height);
    background-color: var(--main-contents-background-color);
    min-height: var(--main-contents-min-height);
}
.btcorpad .main-contents {
    padding-top: 0 !important;
}
.wrapper .main-contents {
    /*height: 100%;*/
    /*min-height: calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px));*/
}
/********** contents end **********/


/********** footer start **********/
.AdFooter {
    margin-left: 250px;
}
.ad-footer {
    background-color: var(--ad-footer-background-color);
    border-top: var(--ad-footer-border-top);
    color: var(--ad-footer-color);
    padding: var(--ad-footer-padding);
    height: var(--ad-footer-height);
}
.footer-cv  {
    position: relative;
    display: flex;
    justify-content: space-between;
}
/********** footer end **********/



/********** sleep-shelter **********/
.sleep > .sleep-modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background-color: rgba(31, 31, 31, 0.95);
}
.sleep > .sleep-modal.open-sleep {
    display: flex;
    align-items: center;
    animation: modal-bg-show .5s;
}
.sleep-header {
    animation: modal-show 3s;
    position: fixed;
    top: 35px;
    right: 35px;
}
.sleep-main {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 1000px;
    height: 890px;
    border-radius: 15px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid 2px rgba(255, 120, 0);
    animation: modal-show 3s;
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -120px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.ingame-box-score {
    display: block;
    color: #FFAF34;
    height: 50px;
    font-size: 32px;
}
.ingame-box {
    display: block;
    width: 900px;
    height: 790px;
    margin: 0 auto;
    border: none;
    border-radius: 10px;
    background-color: rgb(245, 245, 245);
    padding-top: 26px;
}
.num-cellbox {
    width: 840px;
    height: 730px;
    margin: 0 auto;
    border: double 3px #cccccc;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5,1fr);
    border-radius: 5px;

    /*animation-duration: 2.5s;*/
    /*animation-name: borderraibow;*/
    /*animation-iteration-count: infinite;*/

}
@keyframes borderraibow {
    /*0% { color: #ff0000; }*/
    /*18% { color: #ff6000; }*/
    /*37% { color: #ffd200; }*/
    /*51% { color: #00ff35; }*/
    /*68% { color: #0065ff; }*/
    /*85% { color: #001c3b; }*/
    /*100% { color: #6400ff; }*/

    /*0% { color: #000000; }*/
    /*10% { color: #131313; }*/
    /*30% { color: #2a2a2a; }*/
    /*40% { color: #545454; }*/
    /*50% { color: #818181; }*/
    /*50% { color: #b9b9b9; }*/
    /*100% { color: #F5F5F5FF; }*/
}
.num-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    border: double 3px #cccccc;
    background-color: rgb(245, 245, 245);
    cursor: pointer;
    border-radius: 5px;
}
.num-cell p {
    /*animation: spin 5s infinite linear;*/
    font-size: 38px;
}
@keyframes spin {
    100% {transform: rotateY(-360deg);}
}
.num-cell:hover {
    transform: scale(1.05);
    box-shadow: 0 0 12px #6c6c6c;
}
.ingame-box-btn {
    display: block;
    height: 50px;
}
.start-btn {
    color: #FFAF34;
    font-size: 21px;
    border: solid 2px #FFAF34;
    width: 150px;
    height: 36px;
    border-radius: 5px;
    margin-top: 7px;
}
.close-x {
    font-size: 42px;
    color: #f1f1f1;
    cursor: pointer;
}
.close-x:active {
    transform: scale(0.90);
}
.start-btn:hover {
    background-color: #FFAF34;
    color: white;
}
.start-btn:active {
    transform: scale(0.90);
}
/********** sleep-shelter **********/


/********** main-search **********/
.search-wrapper .input-holder {
    overflow: hidden;
    position: relative;
    transition: all 0.2s ease-in-out;
}
.search-wrapper.active .input-holder {
    width:400px;
    height: 33px;
    border-radius: 5px;
    transition: all .4s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    border: var(--dark-task-border);
}
.search-wrapper .input-holder .search-input {
    width:360px;
    height: 33px;
    opacity: 0;
    position: absolute;
    top:0;
    left:0;
    background: transparent;
    box-sizing: border-box;
    border:none;
    outline:none;
    transform: translate(0, 60px);
    transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.3s;
    font-size: 14px;
}
.search-wrapper.active .input-holder .search-input {
    opacity: 1;
    transform: translate(0, -1px);
    padding-left: 7px;
}
.search-wrapper .input-holder .search-icon {
    border:none;
    outline:none;
    position: relative;
    float:right;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.search-wrapper.active .input-holder .search-icon {
    margin-top: 6px;
}
/********** main-search **********/

