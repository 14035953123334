.user {
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.user-layout {
    display: flex;
    flex: 1;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    flex-direction: column;
    height: auto;
    min-height: calc(100% - 250px);
}
.user-header {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
}
.user-body {
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 0;
    position: relative;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
}
.user-footer {
    position: relative;
    transform: translateY(0%);
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    border: none;
    /*background-color: #F4F4F4;*/
    background-color: #F8F8F8;
}

.u-f-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: translateY(0%);
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    padding: 30px 0;
}
.user-quick-menu {
    position: fixed;
    z-index: 18;
    right: 0;
    top: 320px;
    margin: 10px;
}
