.join-main {
    width: 100%;
    margin: 0 auto;
}
.join-sub {
    width: 100%;
    min-height: 100vh;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 15px 15px 15px;
}
.wrap-join {
    width: 500px;
    border-radius: 5px;
    overflow: hidden;
}
/*---------[ Form ]*/
.join-form {
    width: 100%;
}
.join-form-title {
    display: block;
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 70px;
}
.join-reset {
    display: flex;
    margin: 10px 0 10px 0;
    justify-content: center;
}
/*-------------[ Input ]*/
.wrap-input {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #d9d9d9;
}
.input100 {
    font-size: 15px;
    color: #333333;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 55px;
    background: transparent;
    padding: 0 7px 0 43px;
}
.m-t-45 {
    margin-top: 35px;
}
/*---------------------------------------------*/
.focus-input-view {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 358px;
}
.focus-input-view a {
    cursor: pointer;
}
.focus-input {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}
.focus-input::after {
    content: attr(data-symbol);
    color: #FFAF34;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: calc(100% - 20px);
    bottom: 0;
    left: 0;
    padding-left: 13px;
    padding-top: 3px;
}
.focus-input::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: #ffc89c;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.input100:focus + .focus-input::before {
    width: 100%;
}
.has-val.input100 + .focus-input::before {
    width: 100%;
}
.input100:focus + .focus-input::after {
    color: #76bcfe;
}
.has-val.input100 + .focus-input::after {
    color: #76bcfe;
}
.i-con-view {
    position: relative;
    font-size: 25px;
    margin-top: 15px;
}
.ErrView {
    height: 25px;
    margin-top: 4px;
    text-align: right;
}
.ErrMsg {
    position: relative;
    height: 22px;
    padding: 0 12px 0 12px;
    color: red;
}

/*------------------------------------------------------------------
[ Button ]*/
.join-sub-form-btn {
    margin-top: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.wrap-join-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 7px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
}
.join100-form-bg-btn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #FFAF34;
    background: -webkit-linear-gradient(right, #ffc573, #FFAF34, #ffc573, #FFAF34);
    top: 0;
    left: -100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
.join100-form-btn {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
}
.wrap-join-form-btn:hover .join100-form-bg-btn {
    left: 0;
}

/*---------------------------------------------*/



