.m-a-msgck-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 40;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-msgck-modal-frame {
    width: 350px;
    height: 220px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
.m-a-msgck-modal-hd-frame {
    border-bottom: #EAEAEA 1px solid;
    height: 35%;
    display: flex;
    padding: 10px;
}
.m-a-msgck-icon-container {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-msgck-icon {
    width: 40px;
    height: 40px;
}
.m-a-msgck-head-text {
    height: 100%;
    /*width: 80%;*/
    display: flex;
    align-items: center;
}
.m-a-msgck-head-content {
    height: 30%;
    display: flex;
    align-items: center;
    /*padding: 10px;*/
}
.m-a-msgck-bt-container {
    height: 35%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
}
.m-a-msgck-bt {

}
