.m-a-order-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.m-a-order-box-title {
    width: 100%;
    background-color: var(--primary);
    height: 40px;
    font-size: 14px;
    box-sizing: border-box;
    color: white;
    text-align: start;
    align-content: center;
}

.m-a-order-box-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, .1);
    gap: 12px;
}


.m-a-order-box-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}


.m-a-order-box-option-left {
    font-size: 16px;
}

.m-a-order-box-option-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 8px;
}


.m-a-order-box-option-item {
    font-size: 12px;
    padding: 4px 16px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}
