.m-a-paypal-modal-background {
    width: 100vw;
    height: 100vh;
    z-index: 40;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
}


.m-a-paypal-container {
    width: 250px;
    background: white;
    z-index: 50;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
    padding: 20px;
}


.m-a-paypal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.m-a-paypal-content-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.m-a-paypal-content-label {
    font-size: 12px;
}

.m-a-paypal-content-label.lg {
    font-size: 14px;
}

.m-a-paypal-content-label.bold {

}

.m-a-paypal-content-label.primary {
    color: var(--primary);
}

.m-a-paypal-divider {
    width: 100%;
    min-height: 1px;
    background: rgba(0, 0, 0, .1);
}
