.m-a-notice-dt-page-container {
    box-sizing: border-box;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 20px 24px;
    overflow-y: scroll;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}


.m-a-notice-detail-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}


.m-a-notice-detail-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-notice-detail-sub-title-text {
    font-size: 14px;
}

.m-a-notice-detail-info-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}


.m-a-notice-detail-info-text {
    font-size: 12px;
}

.m-a-notice-detail-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.m-a-notice-detail-content {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    align-content: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}


.m-a-notice-detail-content-text {
    width: 100%;
    font-size: 13px;
    line-height: 1.3;
    text-align: start;
}

.m-a-notice-detail-content-img {
    width: 100%;
}

.m-a-notice-detail-divider {
    width: 100%;
    min-height: 1px;
    background-color: rgba(0, 0, 0, .1);
}
