.m-a-order-detail-pg-bottom-navigation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    padding: 8px 20px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.m-a-order-detail-pg-bottom-navigation-bar-button-save {
    height: 45px;
    padding: 12px 32px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    color: white;
    background-color: lightgray;
}

.m-a-order-detail-pg-bottom-navigation-bar-all-button-save {
    width: 100%;
    height: 45px;
    padding: 12px 32px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    color: white;
    background-color: lightgray;
}

.m-a-order-detail-pg-bottom-navigation-bar-button-pay {
    flex: 1;
    text-align: center;
    align-content: center;
    background-color: var(--primary);
    color: white;
    font-size: 16px;
    border-radius: 4px;
}

.m-a-order-detail-pg-bottom-navigation-bar-button-pay.disabled {
    cursor: default;
    opacity: .4;
}


.m-a-order-detail-container {
    box-sizing: border-box;
    width: 100vw;
    height: calc(100vh - 50px);
    padding: 0 24px 80px 24px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-order-detail-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    position: relative;
}

.m-a-order-detail-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-order-detail-sub-title-expand {
    position: absolute;
    right: 0;
    transform: rotate(90deg);
}

.m-a-order-detail-sub-title-expand.expanded {
    transform: rotate(270deg);
}

.m-a-order-detail-sub-title-text {
    font-size: 16px;
}

.m-a-order-detail-table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.m-a-order-detail-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .1);
    align-items: center;
}

.m-a-order-detail-button-sm {
    padding: 6px 20px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.m-a-order-detail-section-title {
    width: 100%;
    height: 36px;
    background-color: var(--primary);
    color: white;
    font-size: 12px;
    text-align: start;
    padding: 10px 16px 0 16px;
    /*align-content: center;*/
    opacity: .8;
    margin-bottom: -12px;
}

.m-a-order-detail-section-frame {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.m-a-order-detail-section-frame-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.m-a-order-detail-select {
    width: 100%;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 40px;
    font-size: 12px;
    border-radius: 4px;
}

.m-a-order-detail-select-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    top: 40px;
    left: 0;
    z-index: 30;
    position: absolute;
    max-height: 200px;
    overflow-y: scroll;
}

.m-a-order-detail-select-item {
    width: 100%;
    font-size: 12px;
    padding: 8px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
}

/*.m-a-order-detail-section-checklist {*/
/*    width: 100%;*/
/*    font-size: 12px;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-content: center;*/
/*    justify-content: flex-start;*/
/*    gap: 8px;*/
/*}*/

.m-a-order-detail-section-checklist-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

}

.m-a-order-detail-section-checklist {
    width: 100%;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    padding-bottom: 5px;
    padding-top: 5px;
    gap: 10px;
}

.m-a-order-detail-pay-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
}

.m-a-order-detail-pay-row {
    width: 1000px;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-order-detail-pay-row-new {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-order-pay-swipe {
    background-color: rgba(0, 0, 0, 0.1);
}

.m-a-order-detail-pay-item {
    font-size: 13px;
    text-align: center;
}

.m-a-order-detail-pay-item.width-60 {
    width: 60px;
    min-width: 60px;
}

.m-a-order-detail-pay-item.width-100 {
    width: 100px;
    min-width: 100px;
}

.m-a-order-detail-pay-item.width-200 {
    width: 200px;
    min-width: 200px;
}

.m-a-order-detail-pay-item.width-300 {
    width: 300px;
    min-width: 300px;
}

.m-a-order-detail-pay-item.button {
    padding: 4px 12px;
    border-radius: 4px;
    background-color: var(--primary);
    color: white;
    font-size: 12px;
}

.m-a-order-detail-pay-row.label {
    height: 40px;
    align-content: center;
}

.m-a-order-detail-section-payment-title {
    width: 100%;
    text-align: start;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 12px;
    padding-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.m-a-order-detail-section-payment-title-right {
    font-size: 16px;
    color: black;
}

.m-a-order-detail-section-payment-title-highlight {
    color: var(--primary);
    display: inline-block;
}

.m-a-order-detail-section-payment-discount {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-order-detail-section-payment-discount-label {
    font-size: 12px;
    min-width: 100px;
}

.m-a-order-detail-section {
    display: inline-block;
    font-size: 12px;
}

.m-a-order-detail-section.highlight {
    color: var(--primary);
}

.m-a-order-detail-section-payment-discount-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-order-detail-section-payment-discount-input {
    width: 80px;
    height: 35px;
    border: 1px solid rgba(0, 0, 0, .1);
    font-size: 12px;
    box-sizing: border-box;
    padding: 0 12px;
}
.m-a-order-detail-section-payment-discount-input:focus {
    outline: none;
}

.m-a-order-detail-section-payment-discount-button {
    padding: 0 8px;
    border: 1px solid var(--primary);
    height: 32px;
    align-content: center;
    color: var(--primary);
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
}

.m-a-order-detail-section-payment-method-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 12px;
}

.m-a-order-detail-section-payment-method {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #EAEAEA;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 4px;
}

.m-a-order-detail-section-payment-method-text {
    font-size: 12px;
    text-align: center;
    font-family: Pretendard-Regular, sans-serif;
    color: var(--contents-color);
}

.m-a-order-detail-section-payment-method.focused {
    border: 2px solid;
    border-color: darkorange;
    background-color: white;
}

.m-a-order-detail-payment-detail-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 8px;
}

.m-a-order-detail-payment-detail-box-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-order-detail-payment-detail-box-left {
    font-size: 14px;
    color: black;
}

.m-a-order-detail-payment-detail-box-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: var(--primary);
    gap: 4px;
}

.m-a-order-detail-payment-detail-box-dropdown {
    font-size: 12px;
    height: 28px;
    padding: 4px 12px;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 4px;
    color: var(--text);
    position: relative;
}

.m-a-order-detail-payment-detail-dropdown-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 30;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    position: absolute;
    top: 28px;
    left: 0;
    max-height: 120px;
    overflow-y: scroll;
}

.m-a-order-detail-payment-detail-dropdown-item {
    min-height: 28px;
    align-content: center;
}

.m-a-order-detail-payment-detail-box-dropdown.no-border {
    border: none;
}

.m-a-order-detail-payment-detail-box-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    gap: 4px;
}

.m-a-order-detail-payment-detail-box-desc {
    width: 100%;
    font-size: 12px;
    text-align: start;
}

.bold {
    font-size: 14px;
    margin-top: 4px;
}

.m-a-order-detail-payment-detail-box-img {
    width: 60%;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.m-a-order-detail-pay-delivery-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.m-a-order-detail-pay-delivery-box-left {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /*padding: 20px 0;*/
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: space-between;*/
    font-size: 13px;
    color: #FFAF34;
}
.m-a-order-detail-pay-delivery-img {
    width: 80px;
}


.m-a-order-detail-pay-delivery-box-left.selected {
    border: 2px solid var(--primary);
}

.m-a-order-detail-pay-delivery-box-left.strong {
    color: var(--primary);
}

.m-a-order-detail-pay-delivery-box-right {
    font-size: 12px;
}



.m-a-order-detail-invoice-attachment {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    min-height: 64px;
    max-height: 64px;
    gap: 8px;
    box-sizing: border-box;
    padding: 12px;
    box-shadow: 0 0 1px rgba(0, 0, 0, .5);
}
.m-a-order-detail-invoice-attachment-file {
    flex: 1;
    height: 100%;
    width: 80%;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 12px;
    color: var(--text);
    border-radius: 4px;
}

.m-a-order-detail-invoice-attachment-button {
    height: 100%;
    width: 20%;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 10px 8px;
    font-size: 12px;
    border-radius: 4px;
}

.m-a-order-detail-invoice-attachment-button-input {
    visibility: hidden;
}


.m-a-order-detail-order-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.m-a-order-detail-order-box {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, .1);
}


.m-a-order-detail-order-box-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.m-a-order-detail-order-box-img-box {
    width: 100px;
    height: 100px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.m-a-order-detail-order-box-img {
    width: 48px;
    opacity: .4;
}


.m-a-order-detail-order-box-title {
    font-size: 14px;
}


.m-a-order-detail-order-table {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.m-a-order-detail-order-table-row-new {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    gap: 8px;
}
.m-a-order-detail-order-table-label-new {
    width: 30%;
    height: 100%;
    font-size: 11px;
    background-color: var(--table-label);
    padding-top: 10px;
    text-align: center;
    /*align-content: center;*/
}
.m-a-order-detail-order-table-label-new-box {
    background-color: var(--table-label);
}
.m-a-order-detail-order-table-value {
    width: 70%;
    font-size: 12px;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 0 12px;
    align-content: center;
}
.m-a-order-detail-order-table-value-st {
    width: 70%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    border: none;
}
.m-a-order-detail-order-table-value-inside {
    width: 100%;
    padding: 0;
    height: 32px;
    border: none;
    outline: none;
}

.m-a-order-detail-order-table-value.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-order-detail-order-table-value.no-border {
    border: none;
    padding: 0;
}

.m-a-order-detail-chat-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .1);
}
.m-a-order-detail-chat-frame {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}

.m-a-order-detail-chat-message-frame {
    width: 100%;
    overflow-y: scroll;
}


.m-a-order-detail-divider {
    width: 100%;
    min-height: 1px;
    background-color: rgba(0, 0, 0, .1);
}

.m-a-order-detail-table-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    /*gap: 12px;*/
}

.m-a-order-detail-table-row-label {
    width: 30%;
    box-sizing: border-box;
    padding: 12px;
    background-color: #FFAF34;
    color: white;
    font-size: 12px;
    text-align: start;
    white-space: nowrap;
    align-content: center;
}

.m-a-order-detail-table-row-value {
    width: 70%;
    font-size: 13px;
    padding: 12px 2px 12px 7px;
}

.m-a-order-detail-input-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.m-a-order-detail-input-title {
    position: absolute;
    top: -8px;
    left: 12px;
    background-color: white;
    font-size: 12px;
    padding: 0 4px;
}

.m-a-order-detail-input {
    width: 100%;
    height: 40px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 0 12px;
}
.m-a-order-detail-input:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, .3);
}
.m-a-order-detail-input-password {
    position: absolute;
    right: 12px;
    top: 6px;
    z-index: 20;
}

.m-a-user-order-input-pw-icon {
    width: 20px;
}


.m-a-order-detail-error-message-box {
    width: 100%;
    font-size: 12px;
    color: var(--red);
    text-align: start;
    padding-left: 12px;
    margin-left: 12px;
    margin-top: -8px;
}

.m-a-order-detail-error-message-box.no-margin-left {
    margin-left: -12px;
}

.m-a-order-detail-section-payment-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.m-a-order-detail-section-payment-row-text {
    font-size: 12px;
}

.m-a-order-detail-ordNum-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    position: relative;
}

.m-a-order-detail-ordNum-frame {
    display: flex;
    align-content: center;
    margin-top: 20px;
}

.m-a-order-detail-ordNum-icon {
    width: 26px;
    height: 26px;
    margin-right: 7px;
}

.m-a-order-detail-ordNum-text {
    font-size: 14px;
}

.m-a-order-detail-ordNum-delete-bt {
    width: 60px;
    height: 35px;
    border: solid 1px lightgray;
    border-radius: 4px;
}

.m-a-order-detail-shipco-logo-frame {
    display: flex;
    justify-content: center;
    height: 60%;
}

.m-a-order-detail-shipco-price-frame {
    display: flex;
    align-content: flex-start;
    justify-content: center;
    height: 40%;
}
