.blurContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background-color: var(--blurContainer-background-color);
}
.alertFrame {
    position: fixed;
    border-radius: 10px;
    width: 500px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--alertFrame-background-color);
    box-shadow: var(--alertFrame-box-shadow);
}
@media (max-width: 720px) {
    .alertFrame {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        min-height: 40%;
        /*height: 480px;*/
        max-height: 480px;
        padding: 0 15px;
    }
}
.alertImgCon {
    /*display: flex;*/
    height: 120px;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
.alertImgBox {
    height: 120px;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-top: 3px;
}
