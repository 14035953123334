#m-a-dashboard-pg-container {
    display: flex;
    flex-direction: column;
}
#m-a-dashboard-pg-container a {
    color: #5b5b5b;
}
.m-a-dash-progress-card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.m-a-p-card-frame {
    width: 28%;
}
.m-a-dash-top-imgBox {
    width: 55px;
    height: 55px;
    overflow: hidden;
    margin-bottom: 10px;
}
.m-a-dash-top-imgBox-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-style: none;
}
.m-a-p-text {
    display: block;
    /*flex-direction: row;*/
    /*justify-content: center;*/
    font-size: 14px;
    /*padding-top: 7px;*/
}
.m-a-p-t-border {
    border: var(--m-a-p-t-border-border);
    margin: 2px 0 2px 0;
}
.m-a-dash-o-progress-card {
    display: flex;
    flex-direction: column;
}
.m-a-o-p-text {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.m-a-o-p-text > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 14px;
}
.m-a-p-text-value {
    color: var(--btorage-color);
}
.m-a-order-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.m-a-order-info {
    margin-bottom: 10px;
}
.m-a-order-info div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px 0 2px 0;
}
.m-a-order-dt-info {
    /*border: solid 1px lawngreen;*/
}
.m-a-order-bt-frame {
    padding: 5px 0 5px 0;
}
.m-a-order-bt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.m-a-order-status {
    width: 75%;
    height: 30px;
    border-radius: 4px;
    border: none;
    background-color: var(--m-a-body-container-background-color);
}
.m-a-order-status-B {
    width: 100%;
    height: 30px;
    border-radius: 4px;
    border: none;
    background-color: var(--m-a-body-container-background-color);
}
.m-a-order-prod-type-p {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7px 0 7px 0;
    border-bottom: solid 1px #c9c9c9;
}
.m-a-order-prod-type-p div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.m-a-order-prod-type {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 7px 0 7px 0;
    border-bottom: solid 1px #c9c9c9;
}
.m-a-order-prod-type-cont {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #c9c9c9;
    font-size: 14px;
    padding: 7px 0 7px 0;
}
.m-a-order-p-c-frame-h {
    display: flex;
    flex-direction: row;
    justify-content: start;
}
.m-a-prod-cont {
    display: flex;
    flex-direction: column;
}
.m-a-value-badge {
    display: inline-block;
    position: relative;
    width: 35px;
    height: 20px;
    border-radius: 4px;
    background-color: var(--m-a-value-badge-background-color);
    color: #fff;
    font-weight: bold;
}
.m-a-dash-select-frame {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.m-a-d-s-f-gbn-1, .m-a-d-s-f-gbn-2 {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    /*justify-content: space-between;*/
    padding: 3px 0 3px 0;
}
.m-a-alarm-bt-count-frame {
    margin-left: 10px;
}

/*공통 셀렉트팝업*/
.m-a-common-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--m-a-common-popup-container-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* 팝업을 최상단에 표시 */
}
.m-a-common-popup-frame {
    width: 75%;
    max-width: 400px;
    background: var(--m-a-000-and-fff-background-color);
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transform: translateY(-20px);
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    animation: selectSlideIn 0.3s forwards;
}
@keyframes selectSlideIn {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
.m-a-common-popup-header-frame {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f2f5;
}
.m-a-common-popup-header-text {
    font-size: 18px;
    font-weight: bold;
}
.m-a-common-popup-body-frame {
    padding: 20px 40px;
    max-height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.m-a-common-popup-body-data {
    padding: 12px;
    margin: 3px 0;
    background: white;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease, transform 0.2s ease;
    color: dimgray;
}
.m-a-common-popup-body-data:hover {
    background: #FFF2DA;
    color: #FFAF34;
    transform: translateY(-2px);
}
.m-a-common-popup-footer-frame {
    padding: 16px;
    display: flex;
    justify-content: center;
}
.m-a-main-slider {
    width: 100%;
    overflow: hidden;
    position: relative;
    outline: none;
    border: none;
    background: none;
    border-radius: 10px;
}
.m-a-slider-wrapper {
    display: flex;
    transition: transform 0.3s ease;
    outline: none;
    border: none;
    background: none;
    /*border-radius: 10px;*/
}

.m-a-card-slider {
    width: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
}
.m-a-indicators {
    position: absolute;
    bottom: 9px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
}
.m-a-indicators-top {
    border-bottom: var(--dark-task-border-bottom);
}
.m-a-indicator {
    width: 8px;
    height: 8px;
    background-color: var(--m-a-indicator-background-color);
    border-radius: 50%;
    transition: background-color 0.5s;
}
.m-a-indicator.active {
    width: 22px;
    border-radius: 10px;
    background-color: var(--m-a-indicator-active-background-color);
}
.user-count-frame {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.user-count-frame > div {
    width: 25%;
}

.user-count-frame > div > div {
    height: 60px;
}

.user-count-frame-2row-frame {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    height: 50%;
    padding: 10px;
    text-align: center;
}

/*관리자 공통 스위치버튼*/
.switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px; /* ✅ 낮은 높이 유지 */
}

.switch input {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D3D3D3;
    transition: 0.4s;
    border-radius: 30px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding: 0 10px;
}

.slider.round {
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px; /* ✅ 원 크기 조정 */
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

/* ✅ CLOSE(OFF) 상태에서 원이 왼쪽에 위치 */
input:not(:checked) + .slider:before {
    transform: translateX(0);
}

/* ✅ OPEN(ON) 상태에서 원을 우측과 좀 더 밀착 */
input:checked + .slider:before {
    transform: translateX(50px); /* ✅ 기존 44px → 48px로 조정하여 우측과 밀착 */
}

/* ✅ OFF일 때 텍스트 오른쪽 정렬 */
.slider.off {
    justify-content: flex-end;
}

/* ✅ ON일 때 텍스트 왼쪽 정렬 */
.slider.on {
    justify-content: flex-start;
    padding-left: 12px; /* ✅ OPEN 상태일 때 텍스트 위치 미세 조정 */
}

/* ✅ 체크된 상태 */
input:checked + .slider {
    background-color: #FFBE48;
}


