.m-a-header {
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    position: relative;
    margin-bottom: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.m-a-header-back-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    opacity: .75;
}

.m-a-header-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
