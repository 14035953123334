.m-a-user-pay-wait-page-container {
    box-sizing: border-box;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    padding: 40px 24px 60px 24px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-user-pay-wait-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 20px;
}

.m-a-user-pay-wait-icon {
    width: 48px;
}

.m-a-user-pay-wait-title {
    font-size: 16px;
}

.m-a-user-pay-wait-title-sm {
    font-size: 13px;
    margin-top: -12px;
}

.m-a-user-pay-wait-divider {
    width: 100%;
    min-height: 1px;
    background: rgba(0, 0, 0, .1);
}

.m-a-user-pay-wait-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-user-pay-wait-label {
    font-size: 14px;
}

.m-a-user-pay-wait-value {
    font-size: 14px;
}

.m-a-user-pay-wait-value.bold {
}

.m-a-user-pay-wait-value.primary {
    color: var(--primary);
}

.m-a-user-pay-wait-value.pale {
    color: rgba(0, 0, 0, .6);
    font-size: 12px;
}

.m-a-user-pay-wait-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.m-a-user-pay-wait-button {
    width: 50%;
    height: 40px;
    font-size: 14px;
    text-align: center;
    align-content: center;
    color: white;
    background-color: #CCCCCC;
    border-radius: 4px;
}

.m-a-user-pay-wait-button.primary {
    background-color: var(--primary);
}
