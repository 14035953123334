.ord-tab {
    width: 100%;
    border-bottom: solid 1px #dee2e6;
    /*border-bottom: solid 1px blue;*/
    /*background-color: red;*/
}
.tab-task, .tab-temp {
    display: block;
    border-left: solid 1px #dee2e6;
    border-right: solid 1px #dee2e6;
    border-bottom: solid 1px #dee2e6;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.ord-main-tb-head {
    position: sticky;
    z-index: 2;
    top: 0;
}
.ord-main-tb-head li, .ord-main-tb-body li {
    display: flex;
    flex-direction: column;
    text-align: center;
    /*align-items: center;*/
}
.ord-main-tb-head b {
    font-size: 13px;
    line-height: 24px;
}
.ord-main-tb-head-line {
    height: 120px;
    font-family: Pretendard-Regular, sans-serif;
}
.ord-main-tb-head-line th {
    vertical-align: middle;
    border: none;
    background-color: #f1f1f5;
}

.ord-main-tb-body {
    height: 170px;
    font-family: Pretendard-Regular, sans-serif;
}
.ord-main-tb-body a {
    font-size: 12px;
}
.ord-main-tb-body-line td {
    vertical-align: middle;
}

.cont-dt {
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    margin-top: 2px;
    margin-bottom: 6px;
}
.cont-dt a {
    font-size: 12px;
    font-weight: bold;
}
.dt-option, .dt-req, .dt-memo {
    display: flex;
    flex-direction: row;
}
.order-memo-input:hover {
    background-color: #ffffff;
}
.order-memo-input {
    width: 80.5%;
    border: 1px solid #9d9d9d;
    background-color: #f3f3f3;
    height: 22px;
    border-radius: 2px;
    font-size: 12px;
}

.order-dt-open {
    display: none;
}
.order-dt-close {
    /*display: block;*/
    display: table-row;
}
#ord-dt-tb-wrap {
    padding-left: 28px;
}

.tb-head-cont-dt li, .tb-head-td-title li {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tb-head-title-line li b {
    font-size: 12px;
    line-height: 22px;
}

.tb-head-title-line th, .tb-head-cont-line td {
    vertical-align: middle;
}
.tb-head-title-line th {
    border-top: var(--dark-task-border-top);
    border-right: var(--dark-task-border-right);
    border-left: var(--dark-task-border-left);
}
.pay-insert-btn {
    background-color: rgba(255, 120, 0, 0.1);
}

.ord-dt-bt-span {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
}
.ord-list-btn {
    width: 70px;
    margin: 3px 0 3px 0
}

.tb-head-cont-line {
    height: 120px;
}
.tb-head-cont-line-l {
    border-left: solid 1px #dee2e6;
}
.tb-head-cont-line-r {
    border-right: solid 1px #dee2e6;
}
.tb-head-cont {
    height: 150px;
}
.tb-head-cont td, .tb-head-cont-dt td {
    border-right: var(--dark-task-border-right);
    border-left: var(--dark-task-border-left);
    border-top: var(--dark-task-border-top);
    border-bottom: var(--dark-task-border-bottom);
}
.tb-head-cont-line li a {
    font-size: 12px;
}
.tb-head-cont-line li b {
    font-size: 12px;
}

.ord-prod-img {
    width: 65px;
    height: 65px;
    border: solid 1px #afafaf;
    background-color: #ffffff;
}
.ord-prod-img-file {
    display: inline-block;
    margin-top: 8px;
    width: 45px;
    height: 45px;
    opacity: 0.2;
}
.cont-dt-bottom {
    display: block;
    height: 40px;
    border-bottom: var(--dark-task-border-bottom);
    border-top: none;
    border-left: var(--dark-task-border-left);
    border-right: var(--dark-task-border-right);
    margin-top: -16px;
    padding-top: 6px;
}
.dt-bottom-sp-l {
    /*margin-left: 5px;*/
    margin-top: 15px;
}
.dt-bottom-sp-r {
    float: right;
    margin-right: 5px;
}
.dt-bottom-sp-left {
    float: left;
    margin-left: 10px;
}
.up-icon {
    font-size: 15px;
}
.prod-textarea-memo {
    font-size: 12px;
    resize: none;
    background-color: var(--m-a-000-and-fff-background-color);
    color: var(--contents-color);
}
.dt-click {
    margin-bottom: 3px;
}
.ord-accordion-icon {
    margin-top: 3px;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    background-image: url('https://cdn-icons-png.flaticon.com/512/2626/2626997.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: var(--img-color-filter);
    transition: transform 0.5s;
}
.admin-ord-ctg {
    margin-top: 10px;
    margin-bottom: 15px;
}
.admin-ord-ctg-wrap {
    display: flex;
    border: var(--table-color-border);
    padding: 8px;
    border-radius: 4px;
    margin-top: 20px;
    background-color: var(--main-contents-background-color);
}

.admin-ord-reg-wrap {
    display: flex;
    padding: 7px;
    justify-content: space-between;
    background-color: #DFDFE37F;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.admin-ord-reg-wrap:hover {
    background-color: #e4e4e7;
}
