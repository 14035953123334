.Receipt-title {
    border-bottom: var(--double-border-bottom);
    border-width: 7px thin;
    margin: 1rem 1rem 1.3rem 1rem;
    padding-bottom: 15px;
}
.RecList-thead-line li, .recList-tbody-line li {
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
}
.RecList-thead-line th, .recList-tbody-line td {
    vertical-align: middle;
    text-align: center;
}
.RecList-thead-line {
    background-color: var(--table-title-background-color);
}
.recList-tbody-line {
    background-color: var(--contents-box-dt-background-color);
}
.RecList-thead-line b {
    font-size: 13px;
    cursor: default;
}
.recList-tbody-line a {
    font-size: 12px;
}
.recList-tbody-line:hover {
    background-color: var(--main-td-hover-background);
}
.dt-box-cont div div p {
    font-size: 13px;
}
