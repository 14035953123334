#m-a-Info-modi-pg-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}
.m-a-modi-pg-frame {
    display: flex;
    flex-direction: column;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.m-a-m-f-profileInfo {
    width: 100%;
    text-align: center;
}
.m-a-m-f-profileInfo > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0 10px 0;
}
