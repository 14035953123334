.m-a-user-info-modi-pg-container {
    box-sizing: border-box;
    padding: 0 24px 20px 24px;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-user-info-modi-pg-user-profile {
    width: 120px;
    height: 120px;
    min-height: 120px;
    border-radius: 60px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}

.m-a-user-info-modi-pg-user-profile-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.m-a-user-info-modi-pg-user-profile-button {
    padding: 8px 12px;
    background: var(--primary);
    font-size: 12px;
    border-radius: 4px;
    color: white;
}

.m-a-user-info-modi-pg-user-profile-button.gray {
    background: #CCCCCC;
}

.m-a-user-info-modi-pg-password-change-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.m-a-user-info-modi-pg-password-change-close-frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.m-a-user-info-modi-pg-password-change-close-icon {
    width: 24px;
    height: 24px;
}

.m-a-user-info-modi-pg-input-frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    box-sizing: border-box;
    padding-right: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.m-a-user-info-modi-pg-show-icon {
    width: 20px;
    height: 20px;
    opacity: .6;
}

.m-a-user-info-modi-pg-error {
    width: 100%;
    text-align: start;
    font-size: 12px;
    color: var(--strong);
    margin-top: -8px;
}

.m-a-user-info-modi-pg-col {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-info-modi-pg-label {
    width: 80px;
    min-width: 80px;
    text-align: end;
    font-size: 12px;
    background: white;
    color: var(--primary);
    white-space: nowrap;
}

.m-a-user-info-modi-pg-box {
    box-sizing: border-box;
    padding: 0 12px;
    width: 100%;
    height: 40px;
    min-height: 40px;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    display: flex;
    align-items: center;

&:focus {
     border: 1px solid rgba(0, 0, 0, .3);
     outline: none;
 }
}

.user-modi-without-border {
    border: none;

&:focus {
     border: none;
 }
}

.m-a-user-info-modi-pg-box::placeholder {
    font-size: 12px;
}

.m-a-user-info-button-transparent {
    box-sizing: border-box;
    padding: 0 12px;
    width: 100%;
    height: 40px;
    min-height: 40px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 4px;
    font-size: 12px;
}


.m-a-user-info-modi-pg-password-change-icon {
    width: 16px;
    height: 16px;
    opacity: .6;
}

.m-a-user-info-modi-pg-language-frame {
    box-sizing: border-box;
    padding: 0 12px;
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .1);
}


.m-a-user-info-modi-pg-language {
    font-size: 12px;
}

.m-a-user-info-modi-pg-select-icon {
    width: 8px;
    opacity: .6;
}

.m-a-user-info-modi-pg-select-icon.reversed {
    transform: rotate(180deg);
}

.m-a-user-info-modi-pg-language-input-box {
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 12px;
}


.m-a-user-info-modi-pg-language-input {
    flex: 1;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 12px;
}

.m-a-user-info-modi-pg-language-input-close {
    width: 8px;
}

.m-a-user-info-modi-pg-language-select {
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
    max-height: 160px;
    overflow-y: scroll;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    z-index: 20;
}

.m-a-user-info-modi-pg-language-select-item {
    width: 100%;
    padding: 8px 12px;
    font-size: 12px;
}

.m-a-user-info-modi-pg-language-select-item.selected {
    background: var(--primary);
    color: white;
}


.m-a-user-info-modi-pg-mail-receiving-frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.m-a-user-modi-pg-mail-receiving-label {
    font-size: 12px;
}

.m-a-user-info-modi-pg-mail-receiving-toggle {
    width: 34px;
    height: 24px;
}

.m-a-user-info-modi-pg-mail-receiving-text {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-info-modi-pg-exit-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.m-a-user-info-modi-pg-exit {
    font-size: 12px;
    color: #969696;
}


.m-a-user-info-modi-pg-save {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    border-radius: 4px;
    border: none;
}


.m-a-user-info-modi-pg-pop-up-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.m-a-user-info-modi-pg-pop-up-text {
    width: 100%;
    text-align: start;
    font-size: 12px;
}

.m-a-user-info-modi-pg-pop-up-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
}

.m-a-user-info-modi-pg-pop-up-button {
    padding: 12px 28px;
    font-size: 12px;
    border-radius: 4px;
}

.cancel {
    border: 1px solid rgba(0, 0, 0, .1);
}

.submit {
    background-color: var(--primary);
    color: white;
    border-radius: 4px;
}

.m-a-user-info-modi-pg-back {
    box-sizing: border-box;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    position: relative;
    margin-top: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.m-a-user-info-modi-pg-back-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: .6;
}

.m-a-user-info-modi-pg-back-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
}

.m-a-user-info-modi-pg-user-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    position: relative;
}

.m-a-user-info-modi-pg-user-profile-delete {
    position: absolute;
    background-color: var(--primary);
    right: 0;
    bottom: 0;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-a-user-info-modi-pg-user-name {
    font-size: 20px;
}

.m-a-user-info-modi-divider {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, .1);
    margin: 4px 0;
}
