.u-delivery-price-apy-weight-field {
    height: 25px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    font-size: 13px;
}
.u-delivery-price-apy-weight-value {
    color: #FFAF34;
    font-weight: bold;
}