/*.m-a-user-review-write-pg-container {*/
/*    box-sizing: border-box;*/
/*    padding: 0 24px 20px 24px;*/
/*    width: 100vw;*/
/*    height: calc(var(--vh, 1vh) * 100);*/
/*    overflow-y: scroll;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    gap: 12px;*/

/*    .m-a-user-review-write-sub-title {*/
/*        width: 100%;*/
/*        display: flex;*/
/*        flex-direction: row;*/
/*        align-items: center;*/
/*        gap: 8px;*/

/*        .m-a-user-review-write-sub-title-icon {*/
/*            width: 20px;*/
/*            height: 20px;*/
/*        }*/

/*        .m-a-user-review-write-sub-title-text {*/
/*            font-size: 14px;*/
/*            font-weight: 500;*/
/*        }*/
/*    }*/

/*    .m-a-user-review-write-divider {*/
/*        width: 100%;*/
/*        height: 1px;*/
/*        background-color: rgba(0, 0, 0, .1);*/
/*    }*/

/*    .m-a-user-review-write-error {*/
/*        width: 100%;*/
/*        text-align: start;*/
/*        font-size: 12px;*/
/*        color: var(--red);*/
/*        margin: -8px 0;*/
/*    }*/

/*    .m-a-user-review-write-input-frame {*/
/*        box-sizing: border-box;*/
/*        width: 100%;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        gap: 12px;*/

/*        .m-a-user-review-write-select-frame {*/
/*            box-sizing: border-box;*/
/*            padding: 0 12px;*/
/*            width: 100%;*/
/*            height: 40px;*/
/*            min-height: 40px;*/
/*            display: flex;*/
/*            flex-direction: row;*/
/*            align-items: center;*/
/*            justify-content: space-between;*/
/*            border: 1px solid rgba(0, 0, 0, .1);*/
/*            border-radius: 4px;*/
/*            font-size: 12px;*/
/*            position: relative;*/

/*            .m-a-user-review-write-expand-icon {*/
/*                width: 8px;*/
/*                height: 8px;*/
/*                opacity: .4;*/
/*            }*/

/*            .m-a-user-review-write-select-expanded {*/
/*                width: 200px;*/
/*                position: absolute;*/
/*                height: 200px;*/
/*                max-height: 200px;*/
/*                overflow-y: scroll;*/
/*                background-color: white;*/
/*                top: 40px;*/
/*                left: 0;*/
/*                box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);*/
/*                z-index: 30;*/

/*                .m-a-user-review-write-select-item {*/
/*                    font-size: 12px;*/
/*                    padding: 8px 12px;*/

/*                    &:hover {*/
/*                        background: var(--primary);*/
/*                        color: white;*/
/*                    }*/
/*                }*/
/*            }*/

/*            .m-a-user-review-write-select-expanded.right {*/
/*                width: 100%;*/
/*                height: fit-content;*/
/*            }*/
/*        }*/

/*        .m-a-user-review-write-input {*/
/*            width: 100%;*/
/*            display: flex;*/
/*            height: 40px;*/
/*            min-height: 40px;*/
/*            box-sizing: border-box;*/
/*            padding: 0 12px;*/
/*            border: 1px solid rgba(0, 0, 0, .1);*/
/*            border-radius: 4px;*/
/*            font-size: 12px;*/

/*            &:focus {*/
/*                border: 1px solid rgba(0, 0, 0, .3);*/
/*                outline: none;*/
/*            }*/
/*        }*/

/*        .m-a-user-review-write-input::placeholder {*/
/*            font-size: 12px;*/
/*        }*/

/*        .content {*/
/*            min-height: 200px;*/
/*            text-align: start;*/
/*            align-self: start;*/
/*            align-content: start;*/
/*            padding: 12px;*/
/*        }*/

/*        .m-a-user-review-img-container {*/
/*            width: 100%;*/

/*        }*/

/*        .m-a-user-review-write-img-frame {*/
/*            width: 100%;*/
/*            display: flex;*/
/*            flex-direction: column;*/
/*            align-items: center;*/
/*            box-sizing: border-box;*/
/*            padding: 12px;*/
/*            border: 1px solid rgba(0, 0, 0, .1);*/
/*            border-radius: 4px;*/
/*            position: relative;*/

/*            .m-a-user-review-write-uploaded-img {*/
/*                width: 100%;*/
/*            }*/

/*            .m-a-user-review-write-uploaded-img-info {*/
/*                width: 100%;*/
/*                display: flex;*/
/*                flex-direction: row;*/
/*                align-items: center;*/
/*                justify-content: flex-end;*/

/*                .m-a-user-review-write-comment-title {*/
/*                    display: flex;*/
/*                    flex-direction: row;*/
/*                    align-items: center;*/
/*                    gap: 8px;*/

/*                    .m-a-user-review-write-comment-title-icon {*/
/*                        width: 28px;*/
/*                        height: 28px;*/
/*                    }*/

/*                    .m-a-user-review-write-comment-title-text {*/
/*                        font-size: 12px;*/
/*                        font-weight: 600;*/
/*                    }*/

/*                    .m-a-user-review-write-comment-title-length {*/
/*                        font-size: 12px;*/
/*                        font-weight: 600;*/
/*                        color: var(--primary);*/
/*                    }*/
/*                }*/
/*            }*/

/*            .m-a-user-review-write-uploaded-img-delete {*/
/*                position: absolute;*/
/*                right: 12px;*/
/*                bottom: 12px;*/
/*                width: 32px;*/
/*                height: 32px;*/
/*                background-color: var(--secondary);*/
/*                display: flex;*/
/*                align-items: center;*/
/*                justify-content: center;*/
/*                border-radius: 4px;*/
/*                box-shadow: 0 0 10px rgba(0, 0, 0, .3);*/
/*                z-index: 10;*/

/*                .m-a-user-review-write-uploaded-img-delete-icon {*/
/*                    width: 20px;*/
/*                    height: 20px;*/
/*                }*/
/*            }*/
/*        }*/

/*        .m-a-user-review-write-upload {*/
/*            width: 100%;*/
/*            height: 160px;*/
/*            border-radius: 4px;*/
/*            display: flex;*/
/*            flex-direction: column;*/
/*            align-items: center;*/
/*            justify-content: center;*/
/*            gap: 8px;*/

/*            .m-a-user-review-write-upload-icon {*/
/*                width: 40px;*/
/*                opacity: .2;*/
/*            }*/

/*            .m-a-user-review-write-upload-text {*/
/*                color: rgba(0, 0, 0, .2);*/
/*                font-size: 14px;*/
/*                font-weight: 600;*/
/*            }*/
/*        }*/
/*    }*/

/*    .m-a-user-review-write-button {*/
/*        font-size: 12px;*/
/*        color: white;*/
/*        padding: 8px 20px;*/
/*        background-color: var(--primary);*/
/*        border-radius: 4px;*/
/*    }*/

/*    .col-2 {*/
/*        flex-direction: row;*/
/*        display: grid;*/
/*        grid-template-columns: repeat(2, 1fr);*/
/*        gap: 12px;*/
/*    }*/
/*}*/

.m-a-user-review-write-pg-container {
    box-sizing: border-box;
    padding: 0 24px 20px 24px;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-sub-title-text {
    font-size: 14px;
    font-weight: 500;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
}

.m-a-user-review-write-pg-container .m-a-user-review-write-error {
    width: 100%;
    text-align: start;
    font-size: 12px;
    color: var(--red);
    margin: -8px 0;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-input-frame {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-select-frame {
    box-sizing: border-box;
    padding: 0 12px;
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    font-size: 12px;
    position: relative;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-expand-icon {
    width: 8px;
    height: 8px;
    opacity: .4;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-select-expanded {
    width: 200px;
    position: absolute;
    height: 200px;
    max-height: 200px;
    overflow-y: scroll;
    background-color: white;
    top: 40px;
    left: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    z-index: 30;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-select-item {
    font-size: 12px;
    padding: 8px 12px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-select-item:hover {
    background: var(--primary);
    color: white;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-select-expanded.right {
    width: 100%;
    height: fit-content;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-input {
    width: 100%;
    display: flex;
    height: 40px;
    min-height: 40px;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    font-size: 12px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-input:focus {
    border: 1px solid rgba(0, 0, 0, .3);
    outline: none;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-input::placeholder {
    font-size: 12px;
}

.m-a-user-review-write-pg-container .content {
    min-height: 200px;
    text-align: start;
    align-self: start;
    align-content: start;
    padding: 12px;
}

.m-a-user-review-write-pg-container .m-a-user-review-img-container {
    width: 100%;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-img-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    position: relative;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-uploaded-img {
    width: 100%;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-uploaded-img-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-comment-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-comment-title-icon {
    width: 28px;
    height: 28px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-comment-title-text {
    font-size: 12px;
    font-weight: 600;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-comment-title-length {
    font-size: 12px;
    font-weight: 600;
    color: var(--primary);
}

.m-a-user-review-write-pg-container .m-a-user-review-write-uploaded-img-delete {
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 32px;
    height: 32px;
    background-color: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    z-index: 10;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-uploaded-img-delete-icon {
    width: 20px;
    height: 20px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-upload {
    width: 100%;
    height: 160px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-upload-icon {
    width: 40px;
    opacity: .2;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-upload-text {
    color: rgba(0, 0, 0, .2);
    font-size: 14px;
    font-weight: 600;
}

.m-a-user-review-write-pg-container .m-a-user-review-write-button {
    font-size: 12px;
    color: white;
    padding: 8px 20px;
    background-color: var(--primary);
    border-radius: 4px;
}

.m-a-user-review-write-pg-container .col-2 {
    flex-direction: row;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}
