.m-a-alarm-list-container {
    min-height: 500px;
}

.m-a-alarm-list-content-frame {
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
}

/* 공통 grid 레이아웃 (헤더, 바디 동일) */
.m-a-alarm-list-content-header,
.m-a-alarm-list-content-body {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr 1fr; /* 열 비율 */
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px 10px;
    gap: 5px;
}

/* 헤더 스타일 */
.m-a-alarm-list-content-header {
    background-color: #F2F2F2;
    font-weight: bold;
}

/* 바디 스타일 */
.m-a-alarm-list-content-body {
    background-color: white;
}

/* 기본 셀 스타일 (row 구조) */
.m-a-alarm-list-header-title,
.m-a-alarm-list-content-text {
    display: flex;
    flex-direction: column; /* 기본 가로 */
    align-items: center;
    text-align: start;
    justify-content: flex-start;
    padding: 5px;
    min-width: 0; /* 긴 텍스트 대응 */
    overflow-wrap: break-word;
}

/* 주문번호와 성함만 column으로 (세로) */
.m-a-alarm-list-header-title.two-lines,
.m-a-alarm-list-content-text.two-lines {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.txt_line {
    width: 90px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.m-a-alarm-list-last-for-price {
    color: darkorange;
}
.m-a-alarm-list-currency {
    margin-left: 2px;
}