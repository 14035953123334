/**************************************** Common Interface ↓ ****************************************/

/* region 공용 All */
.gap1 {gap:1px;}
.gap2 {gap:2px;}
.gap3 {gap:3px;}
.gap4 {gap:4px;}
.gap5 {gap:5px;}
.gap6 {gap:6px;}
.gap7 {gap:7px;}
.gap8 {gap:8px;}
.gap9 {gap:9px;}
.gap10 {gap:10px;}
.gap11 {gap:11px;}
.gap12 {gap:12px;}
.gap13 {gap:13px;}
.gap14 {gap:14px;}
.gap15 {gap:15px;}
.gap16 {gap:16px;}
.gap17 {gap:17px;}
.gap18 {gap:18px;}
.gap19 {gap:19px;}
.gap20 {gap:20px;}
.gap21 {gap:21px;}
.gap22 {gap:22px;}
.gap23 {gap:23px;}
.gap24 {gap:24px;}
.gap25 {gap:25px;}
.gap26 {gap:26px;}
.gap27 {gap:27px;}
.gap28 {gap:28px;}
.gap29 {gap:29px;}
.gap30 {gap:30px;}

.pd1 {padding: 1px;}
.pd2 {padding: 2px;}
.pd3 {padding: 3px;}
.pd5 {padding: 5px;}
.pd7 {padding: 7px;}
.pd8 {padding: 8px;}
.pd9 {padding: 9px;}
.pd10 {padding: 10px;}
.pd11 {padding: 11px;}
.pd12 {padding: 12px;}
.pd13 {padding: 13px;}
.pd14 {padding: 14px;}
.pd15 {padding: 15px;}
.pd20 {padding: 20px;}
.pd25 {padding: 25px;}
.pd30 {padding: 30px;}
.pd35 {padding: 35px;}
.pd40 {padding: 40px;}
.p-t-1 {padding-top: 1px;}
.p-t-2 {padding-top: 2px;}
.p-t-3 {padding-top: 3px;}
.p-t-4 {padding-top: 4px;}
.p-t-5 {padding-top: 5px;}
.p-t-6 {padding-top: 6px;}
.p-t-7 {padding-top: 7px;}
.p-t-8 {padding-top: 8px;}
.p-t-9 {padding-top: 9px;}
.p-t-10 {padding-top: 10px;}
.p-t-11 {padding-top: 11px;}
.p-t-12 {padding-top: 12px;}
.p-t-13 {padding-top: 13px;}
.p-t-14 {padding-top: 14px;}
.p-t-15 {padding-top: 15px;}
.p-t-16 {padding-top: 16px;}
.p-t-17 {padding-top: 17px;}
.p-t-18 {padding-top: 18px;}
.p-t-19 {padding-top: 19px;}
.p-t-20 {padding-top: 20px;}
.p-t-25 {padding-top: 25px;}
.p-t-30 {padding-top: 30px;}
.p-t-40 {padding-top: 40px;}
.p-t-50 {padding-top: 50px;}
.p-t-60 {padding-top: 60px;}
.p-t-65 {padding-top: 65px;}
.p-t-70 {padding-top: 70px;}
.p-r-1 {padding-right: 1px;}
.p-r-2 {padding-right: 2px;}
.p-r-3 {padding-right: 3px;}
.p-r-4 {padding-right: 4px;}
.p-r-5 {padding-right: 5px;}
.p-r-6 {padding-right: 6px;}
.p-r-7 {padding-right: 7px;}
.p-r-8 {padding-right: 8px;}
.p-r-9 {padding-right: 9px;}
.p-r-10 {padding-right: 10px;}
.p-r-11 {padding-right: 11px;}
.p-r-12 {padding-right: 12px;}
.p-r-13 {padding-right: 13px;}
.p-r-14 {padding-right: 14px;}
.p-r-15 {padding-right: 15px;}
.p-r-16 {padding-right: 16px;}
.p-r-17 {padding-right: 17px;}
.p-r-18 {padding-right: 18px;}
.p-r-19 {padding-right: 19px;}
.p-r-20 {padding-right: 20px;}
.p-r-25 {padding-right: 25px;}
.p-r-30 {padding-right: 30px;}
.p-r-35 {padding-right: 35px;}
.p-r-40 {padding-right: 40px;}
.p-r-45 {padding-right: 45px;}
.p-r-50 {padding-right: 50px;}
.p-r-55 {padding-right: 55px;}
.p-b-1 {padding-bottom: 1px;}
.p-b-2 {padding-bottom: 2px;}
.p-b-3 {padding-bottom: 3px;}
.p-b-4 {padding-bottom: 4px;}
.p-b-5 {padding-bottom: 5px;}
.p-b-6 {padding-bottom: 6px;}
.p-b-7 {padding-bottom: 7px;}
.p-b-8 {padding-bottom: 8px;}
.p-b-9 {padding-bottom: 9px;}
.p-b-10 {padding-bottom: 10px;}
.p-b-11 {padding-bottom: 11px;}
.p-b-12 {padding-bottom: 12px;}
.p-b-13 {padding-bottom: 13px;}
.p-b-14 {padding-bottom: 14px;}
.p-b-15 {padding-bottom: 15px;}
.p-b-20 {padding-bottom: 20px;}
.p-b-25 {padding-bottom: 25px;}
.p-b-30 {padding-bottom: 30px;}
.p-b-40 {padding-bottom: 40px;}
.p-b-41 {padding-bottom: 41px;}
.p-b-42 {padding-bottom: 42px;}
.p-b-43 {padding-bottom: 43px;}
.p-b-49 {padding-bottom: 49px;}
.p-b-50 {padding-bottom: 50px;}
.p-b-54 {padding-bottom: 54px;}
.p-l-1 {padding-left: 1px;}
.p-l-2 {padding-left: 2px;}
.p-l-3 {padding-left: 3px;}
.p-l-4 {padding-left: 4px;}
.p-l-5 {padding-left: 5px;}
.p-l-6 {padding-left: 6px;}
.p-l-7 {padding-left: 7px;}
.p-l-8 {padding-left: 8px;}
.p-l-9 {padding-left: 9px;}
.p-l-10 {padding-left: 10px;}
.p-l-11 {padding-left: 11px;}
.p-l-12 {padding-left: 12px;}
.p-l-13 {padding-left: 13px;}
.p-l-14 {padding-left: 14px;}
.p-l-15 {padding-left: 15px;}
.p-l-16 {padding-left: 16px;}
.p-l-17 {padding-left: 17px;}
.p-l-18 {padding-left: 18px;}
.p-l-19 {padding-left: 19px;}
.p-l-20 {padding-left: 20px;}
.p-l-25 {padding-left: 25px;}
.p-l-26 {padding-left: 26px;}
.p-l-27 {padding-left: 27px;}
.p-l-28 {padding-left: 28px;}
.p-l-29 {padding-left: 29px;}
.p-l-30 {padding-left: 30px;}
.p-l-31 {padding-left: 31px;}
.p-l-32 {padding-left: 32px;}
.p-l-33 {padding-left: 33px;}
.p-l-34 {padding-left: 34px;}
.p-l-35 {padding-left: 35px;}
.p-l-40 {padding-left: 40px;}
.p-l-43 {padding-left: 43px;}
.p-l-44 {padding-left: 44px;}
.p-l-45 {padding-left: 45px;}
.p-l-50 {padding-left: 50px;}
.p-l-55 {padding-left: 55px;}
.p-l-60 {padding-left: 60px;}

.m-0-a {margin: 0 auto;}
.m-0 {margin: 0;}
.m-t-1 {margin-top: 1px;}
.m-t-2 {margin-top: 2px;}
.m-t-3 {margin-top: 3px;}
.m-t-4 {margin-top: 4px;}
.m-t-5 {margin-top: 5px;}
.m-t-6 {margin-top: 6px;}
.m-t-7 {margin-top: 7px;}
.m-t-8 {margin-top: 8px;}
.m-t-9 {margin-top: 9px;}
.m-t-10 {margin-top: 10px;}
.m-t-12 {margin-top: 12px;}
.m-t-13 {margin-top: 13px;}
.m-t-14 {margin-top: 14px;}
.m-t-15 {margin-top: 15px;}
.m-t-16 {margin-top: 16px;}
.m-t-17 {margin-top: 17px;}
.m-t-18 {margin-top: 18px;}
.m-t-19 {margin-top: 19px;}
.m-t-20 {margin-top: 20px;}
.m-t-25 {margin-top: 25px;}
.m-t-30 {margin-top: 30px;}
.m-t-33 {margin-top: 33px;}
.m-t-35 {margin-top: 35px;}
.m-t-40 {margin-top: 40px;}
.m-t-45 {margin-top: 45px;}
.m-t-50 {margin-top: 50px;}
.m-t-55 {margin-top: 55px;}
.m-t-60 {margin-top: 60px;}
.m-t-65 {margin-top: 65px;}
.m-t-70 {margin-top: 70px;}
.m-t-75 {margin-top: 75px;}
.m-t-80 {margin-top: 80px;}
.m-t-85 {margin-top: 85px;}
.m-t-90 {margin-top: 90px;}
.m-t-95 {margin-top: 95px;}
.m-t-100 {margin-top: 100px;}
.m-r-m-1 {margin-right: -1px;}
.m-r-m-2 {margin-right: -2px;}
.m-r-m-3 {margin-right: -3px;}
.m-r-m-4 {margin-right: -4px;}
.m-r-m-5 {margin-right: -5px;}
.m-r-m-6 {margin-right: -6px;}
.m-r-m-7 {margin-right: -7px;}
.m-r-1 {margin-right: 1px;}
.m-r-2 {margin-right: 2px;}
.m-r-3 {margin-right: 3px;}
.m-r-4 {margin-right: 4px;}
.m-r-5 {margin-right: 5px;}
.m-r-6 {margin-right: 6px;}
.m-r-7 {margin-right: 7px;}
.m-r-8 {margin-right: 8px;}
.m-r-9 {margin-right: 9px;}
.m-r-10 {margin-right: 10px;}
.m-r-11 {margin-right: 11px;}
.m-r-12 {margin-right: 12px;}
.m-r-15 {margin-right: 15px;}
.m-r-20 {margin-right: 20px;}
.m-r-25 {margin-right: 25px;}
.m-r-30 {margin-right: 30px;}
.m-r-35 {margin-right: 35px;}
.m-r-40 {margin-right: 40px;}
.m-r-45 {margin-right: 45px;}
.m-r-50 {margin-right: 50px;}
.m-b-1 {margin-bottom: 1px;}
.m-b-2 {margin-bottom: 2px;}
.m-b-3 {margin-bottom: 3px;}
.m-b-4 {margin-bottom: 4px;}
.m-b-5 {margin-bottom: 5px;}
.m-b-6 {margin-bottom: 6px;}
.m-b-7 {margin-bottom: 7px;}
.m-b-8 {margin-bottom: 8px;}
.m-b-9 {margin-bottom: 9px;}
.m-b-10 {margin-bottom: 10px;}
.m-b-11 {margin-bottom: 11px;}
.m-b-12 {margin-bottom: 12px;}
.m-b-13 {margin-bottom: 13px;}
.m-b-14 {margin-bottom: 14px;}
.m-b-15 {margin-bottom: 15px;}
.m-b-16 {margin-bottom: 16px;}
.m-b-17 {margin-bottom: 17px;}
.m-b-18 {margin-bottom: 18px;}
.m-b-19 {margin-bottom: 19px;}
.m-b-20 {margin-bottom: 20px;}
.m-b-21 {margin-bottom: 21px;}
.m-b-22 {margin-bottom: 22px;}
.m-b-23 {margin-bottom: 23px;}
.m-b-24 {margin-bottom: 24px;}
.m-b-25 {margin-bottom: 25px;}
.m-b-30 {margin-bottom: 30px;}
.m-b-35 {margin-bottom: 35px;}
.m-b-40 {margin-bottom: 40px;}
.m-b-45 {margin-bottom: 45px;}
.m-b-50 {margin-bottom: 50px;}
.m-b-60 {margin-bottom: 60px;}
.m-b-70 {margin-bottom: 70px;}
.m-b-80 {margin-bottom: 80px;}
.m-b-90 {margin-bottom: 90px;}
.m-b-100 {margin-bottom: 100px;}
.m-b-110 {margin-bottom: 110px;}
.m-b-120 {margin-bottom: 120px;}
.m-l-m-1 {margin-left: -1px;}
.m-l-m-2 {margin-left: -2px;}
.m-l-m-3 {margin-left: -3px;}
.m-l-m-4 {margin-left: -4px;}
.m-l-m-5 {margin-left: -5px;}
.m-l-m-6 {margin-left: -6px;}
.m-l-m-7 {margin-left: -7px;}
.m-l-1 {margin-left: 1px;}
.m-l-2 {margin-left: 2px;}
.m-l-3 {margin-left: 3px;}
.m-l-4 {margin-left: 4px;}
.m-l-5 {margin-left: 5px;}
.m-l-6 {margin-left: 6px;}
.m-l-7 {margin-left: 7px;}
.m-l-8 {margin-left: 8px;}
.m-l-9 {margin-left: 9px;}
.m-l-10 {margin-left: 10px;}
.m-l-12 {margin-left: 12px;}
.m-l-15 {margin-left: 15px;}
.m-l-20 {margin-left: 20px;}
.m-l-22 {margin-left: 22px;}
.m-l-23 {margin-left: 23px;}
.m-l-25 {margin-left: 25px;}
.m-l-30 {margin-left: 30px;}
.m-l-35 {margin-left: 35px;}
.m-l-40 {margin-left: 40px;}
.m-l-45 {margin-left: 45px;}
.m-l-48 {margin-left: 48px;}
.m-l-50 {margin-left: 50px;}
.m-l-55 {margin-left: 55px;}
.m-l-60 {margin-left: 60px;}
.m-l-70 {margin-left: 70px;}
.m-l-80 {margin-left: 80px;}

#btorage-hover:hover {color: #FF9924;}
.btorage-hover:hover {color: #FF9924;}
.btorage-hover-background-grey:hover {background-color: #EAEAEA;}
.icon-hover:hover {color: #FF9924;}
.btorage-active:active {color: #FF9924;}
.btorage-hover-exp:hover {transform: scale(1.2);}
.btn-click-event:active {transform: scale(0.92);}
.bt-clicked {color: #FF9924;}
.c-def {cursor: default;}
.c-point {cursor: pointer;}

.font-b {font-weight: var(--font-b-font-weight)}
.font-c-btorage {color:var(--btorage-color);}
.font-c-btorage-w {color:var(--btorage-w-color);}
.font-c-success {color: var(--success-color);}
.font-c-cancel {color: var(--cancel-color);}
.font-c-accent {color: var(--accent-color);}
.font-c-progress {color: var(--progress-color);}
.font-c-progress-b {color: var(--progress-color-b);}
.font-c-white {color: var(--font-c-white-color);}
.font-c-fff {color: #fff;}
.font-c-default {color: var(--contents-color);}
.font-c-darkgrey {color: darkgrey;}
.font-c-white2 {color: white;}
.font-c-balck {color: black;}
.font-c-lightgrey {color: #a6a6a6;}
.font-c-darkorange {color: darkorange; font-weight: bold}
.font-s-8 {font-size: 8px;}
.font-s-9 {font-size: 9px;}
.font-s-10 {font-size: 10px;}
.font-s-11 {font-size: 11px;}
.font-s-12 {font-size: 12px;}
.font-s-13 {font-size: 13px;}
.font-s-14 {font-size: 14px;}
.font-s-15 {font-size: 15px;}
.font-s-16 {font-size: 16px;}
.font-s-17 {font-size: 17px;}
.font-s-18 {font-size: 18px;}
.font-s-19 {font-size: 19px;}
.font-s-20 {font-size: 20px;}
.font-s-21 {font-size: 21px;}
.font-s-22 {font-size: 22px;}
.font-s-23 {font-size: 23px;}
.font-s-24 {font-size: 24px;}
.font-s-25 {font-size: 25px;}
.font-s-26 {font-size: 26px;}
.font-s-27 {font-size: 27px;}
.font-s-28 {font-size: 28px;}
.font-s-29 {font-size: 29px;}
.font-s-30 {font-size: 30px;}
.font-s-31 {font-size: 31px;}
.font-s-32 {font-size: 32px;}
.font-s-33 {font-size: 33px;}
.font-s-34 {font-size: 34px;}
.font-s-35 {font-size: 35px;}
.font-s-36 {font-size: 36px;}
.font-s-37 {font-size: 37px;}
.font-s-38 {font-size: 38px;}
.font-s-39 {font-size: 39px;}
.font-s-40 {font-size: 40px;}
.font-s-42 {font-size: 42px;}
.font-s-45 {font-size: 45px;}
.grayscale-img {filter: grayscale(100%); opacity: 0.5;}
.text-next-line {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

.font-t-head {
    font-family: LeferiPoint-BlackA, sans-serif;
}
.font-t-title {
    font-family: LINESeedKR-Bd, sans-serif;
    color: var(--contents-color);
}
.font-t-cont {
    font-family: Pretendard-Regular, sans-serif;
    /*color: var(--contents-color);*/
}
.text-active:active {
    color: #FFAF34;
}

.border-box-sizing {
    box-sizing: border-box;
}
.ws-pre-line {
    white-space: pre-line;
}

.min-wd-10 {min-width: 10px;}
.min-wd-15 {min-width: 15px;}
.min-wd-20 {min-width: 20px;}
.min-wd-25 {min-width: 25px;}
.min-wd-30 {min-width: 30px;}
.min-wd-35 {min-width: 35px;}
.min-wd-40 {min-width: 40px;}
.min-wd-45 {min-width: 45px;}
.min-wd-50 {min-width: 50px;}
.min-wd-55 {min-width: 55px;}
.min-wd-60 {min-width: 60px;}
.min-wd-65 {min-width: 65px;}
.min-wd-70 {min-width: 70px;}
.min-wd-75 {min-width: 75px;}
.min-wd-80 {min-width: 80px;}
.min-wd-85 {min-width: 85px;}
.min-wd-90 {min-width: 90px;}
.min-wd-95 {min-width: 95px;}
.min-wd-100 {min-width: 100px;}
.min-wd-105 {min-width: 105px;}
.min-wd-110 {min-width: 110px;}
.min-wd-115 {min-width: 115px;}
.min-wd-120 {min-width: 120px;}
.min-wd-125 {min-width: 125px;}
.min-wd-130 {min-width: 130px;}
.min-wd-135 {min-width: 135px;}
.min-wd-140 {min-width: 140px;}
.min-wd-145 {min-width: 145px;}
.min-wd-150 {min-width: 150px;}
.min-wd-200 {min-width: 200px;}

.max-wd-10 {max-width: 10px;}
.max-wd-15 {max-width: 15px;}
.max-wd-20 {max-width: 20px;}
.max-wd-25 {max-width: 25px;}
.max-wd-30 {max-width: 30px;}
.max-wd-35 {max-width: 35px;}
.max-wd-40 {max-width: 40px;}
.max-wd-45 {max-width: 45px;}
.max-wd-50 {max-width: 50px;}
.max-wd-55 {max-width: 55px;}
.max-wd-60 {max-width: 60px;}
.max-wd-65 {max-width: 65px;}
.max-wd-70 {max-width: 70px;}
.max-wd-75 {max-width: 75px;}
.max-wd-80 {max-width: 80px;}
.max-wd-85 {max-width: 85px;}
.max-wd-90 {max-width: 90px;}
.max-wd-95 {max-width: 95px;}
.max-wd-100 {max-width: 100px;}
.max-wd-105 {max-width: 105px;}
.max-wd-110 {max-width: 110px;}
.max-wd-115 {max-width: 115px;}
.max-wd-120 {max-width: 120px;}
.max-wd-125 {max-width: 125px;}
.max-wd-130 {max-width: 130px;}
.max-wd-135 {max-width: 135px;}
.max-wd-140 {max-width: 140px;}
.max-wd-145 {max-width: 145px;}
.max-wd-150 {max-width: 150px;}
.max-wd-230 {max-width: 230px;}
.max-wd-250 {max-width: 250px;}
.max-wd-270 {max-width: 270px;}

.min-wd-20pc {min-width: 20%;}
.min-wd-25pc {min-width: 25%;}
.min-wd-30pc {min-width: 30%;}
.min-wd-35pc {min-width: 35%;}
.min-wd-65pc {min-width: 65%;}
.min-wd-75pc {min-width: 75%;}

.max-wd-20pc {max-width: 20%;}
.max-wd-25pc {max-width: 25%;}
.max-wd-30pc {max-width: 30%;}
.max-wd-35pc {max-width: 35%;}
.max-wd-65pc {max-width: 65%;}
.max-wd-70pc {max-width: 70%;}
.max-wd-75pc {max-width: 75%;}
.max-wd-80pc {max-width: 80%;}
.max-wd-85pc {max-width: 80%;}
.max-wd-90pc {max-width: 80%;}

.wd-10 {width: 10px;}
.wd-11 {width: 11px;}
.wd-12 {width: 12px;}
.wd-13 {width: 13px;}
.wd-14 {width: 14px;}
.wd-15 {width: 15px;}
.wd-16 {width: 16px;}
.wd-17 {width: 17px;}
.wd-18 {width: 18px;}
.wd-19 {width: 19px;}
.wd-20 {width: 20px;}
.wd-21 {width: 21px;}
.wd-22 {width: 22px;}
.wd-23 {width: 23px;}
.wd-24 {width: 24px;}
.wd-25 {width: 25px;}
.wd-26 {width: 26px;}
.wd-27 {width: 27px;}
.wd-28 {width: 28px;}
.wd-29 {width: 29px;}
.wd-30 {width: 30px;}
.wd-31 {width: 31px;}
.wd-32 {width: 32px;}
.wd-33 {width: 33px;}
.wd-34 {width: 34px;}
.wd-35 {width: 35px;}
.wd-36 {width: 36px;}
.wd-37 {width: 37px;}
.wd-38 {width: 38px;}
.wd-39 {width: 39px;}
.wd-40 {width: 40px;}
.wd-41 {width: 41px;}
.wd-42 {width: 42px;}
.wd-43 {width: 43px;}
.wd-44 {width: 44px;}
.wd-45 {width: 45px;}
.wd-46 {width: 46px;}
.wd-47 {width: 47px;}
.wd-48 {width: 48px;}
.wd-49 {width: 49px;}
.wd-50 {width: 50px;}
.wd-55 {width: 55px;}
.wd-60 {width: 60px;}
.wd-65 {width: 65px;}
.wd-70 {width: 70px;}
.wd-75 {width: 75px;}
.wd-80 {width: 80px;}
.wd-85 {width: 85px;}
.wd-90 {width: 90px;}
.wd-95 {width: 95px;}
.wd-100 {width: 100px;}
.wd-105 {width: 105px;}
.wd-110 {width: 110px;}
.wd-115 {width: 115px;}
.wd-120 {width: 120px;}
.wd-125 {width: 125px;}
.wd-130 {width: 130px;}
.wd-135 {width: 135px;}
.wd-140 {width: 140px;}
.wd-142 {width: 142px;}
.wd-145 {width: 145px;}
.wd-150 {width: 150px;}
.wd-155 {width: 155px;}
.wd-160 {width: 160px;}
.wd-165 {width: 165px;}
.wd-170 {width: 170px;}
.wd-175 {width: 175px;}
.wd-180 {width: 180px;}
.wd-190 {width: 190px;}
.wd-200 {width: 200px;}
.wd-210 {width: 210px;}
.wd-220 {width: 220px;}
.wd-225 {width: 225px;}
.wd-226 {width: 226px;}
.wd-227 {width: 227px;}
.wd-230 {width: 230px;}
.wd-240 {width: 240px;}
.wd-250 {width: 250px;}
.wd-260 {width: 260px;}
.wd-270 {width: 270px;}
.wd-280 {width: 280px;}
.wd-290 {width: 290px;}
.wd-300 {width: 300px;}
.wd-310 {width: 310px;}
.wd-320 {width: 320px;}
.wd-330 {width: 330px;}
.wd-350 {width: 350px;}
.wd-400 {width: 400px;}
.wd-430 {width: 430px;}
.wd-450 {width: 450px;}
.wd-500 {width: 500px;}
.wd-600 {width: 600px;}
.wd-700 {width: 700px;}
.wd-1280 {width: 1280px;}

.wd-auto {width: auto;}
.wd-3pc {width: 3%;}
.wd-4pc {width: 4%;}
.wd-5pc {width: 5%;}
.wd-6pc {width: 6%;}
.wd-7pc {width: 7%;}
.wd-8pc {width: 8%;}
.wd-9pc {width: 9%;}
.wd-10pc {width: 10%;}
.wd-11pc {width: 11%;}
.wd-12pc {width: 12%;}
.wd-13pc {width: 13%;}
.wd-14pc {width: 14%;}
.wd-15pc {width: 15%;}
.wd-16pc {width: 16%;}
.wd-17pc {width: 17%;}
.wd-18pc {width: 18%;}
.wd-19pc {width: 19%;}
.wd-20pc {width: 20%;}
.wd-21pc {width: 21%;}
.wd-22pc {width: 22%;}
.wd-23pc {width: 23%;}
.wd-24pc {width: 24%;}
.wd-25pc {width: 25%;}
.wd-26pc {width: 26%;}
.wd-27pc {width: 27%;}
.wd-28pc {width: 28%;}
.wd-29pc {width: 29%;}
.wd-30pc {width: 30%;}
.wd-31pc {width: 31%;}
.wd-32pc {width: 32%;}
.wd-33pc {width: 33%;}
.wd-34pc {width: 34%;}
.wd-35pc {width: 35%;}
.wd-36pc {width: 36%;}
.wd-37pc {width: 37%;}
.wd-38pc {width: 38%;}
.wd-39pc {width: 39%;}
.wd-40pc {width: 40%;}
.wd-45pc {width: 45%;}
.wd-46pc {width: 46%;}
.wd-47pc {width: 47%;}
.wd-48pc {width: 48%;}
.wd-49pc {width: 49%;}
.wd-495pc {width: 49.5%;}
.wd-50pc {width: 50%;}
.wd-55pc {width: 55%;}
.wd-56pc {width: 56%;}
.wd-60pc {width: 60%;}
.wd-65pc {width: 65%;}
.wd-66pc {width: 66%;}
.wd-67pc {width: 67%;}
.wd-68pc {width: 68%;}
.wd-69pc {width: 69%;}
.wd-70pc {width: 70%;}
.wd-71pc {width: 71%;}
.wd-72pc {width: 72%;}
.wd-75pc {width: 75%;}
.wd-76pc {width: 76%;}
.wd-77pc {width: 77%;}
.wd-78pc {width: 78%;}
.wd-79pc {width: 79%;}
.wd-80pc {width: 80%;}
.wd-81pc {width: 81%;}
.wd-82pc {width: 82%;}
.wd-83pc {width: 83%;}
.wd-84pc {width: 84%;}
.wd-85pc {width: 85%;}
.wd-86pc {width: 86%;}
.wd-87pc {width: 87%;}
.wd-88pc {width: 88%;}
.wd-90pc {width: 90%;}
.wd-91pc {width: 91%;}
.wd-92pc {width: 92%;}
.wd-93pc {width: 93%;}
.wd-94pc {width: 94%;}
.wd-95pc {width: 95%;}
.wd-100pc {width: 100%;}



.min-ht-20 {min-height: 20px;}
.min-ht-25 {min-height: 25px;}
.min-ht-30 {min-height: 30px;}
.min-ht-35 {min-height: 35px;}
.min-ht-40 {min-height: 40px;}
.min-ht-45 {min-height: 45px;}
.min-ht-50 {min-height: 50px;}

.max-ht-40 {max-height: 40px;}
.max-ht-45 {max-height: 45px;}
.max-ht-50 {max-height: 50px;}
.max-ht-55 {max-height: 55px;}
.max-ht-60 {max-height: 60px;}
.max-ht-70 {max-height: 70px;}
.max-ht-80 {max-height: 80px;}
.max-ht-90 {max-height: 90px;}
.max-ht-100 {max-height: 100px;}
.max-ht-110 {max-height: 110px;}
.max-ht-120 {max-height: 120px;}
.max-ht-130 {max-height: 130px;}
.max-ht-140 {max-height: 140px;}
.max-ht-150 {max-height: 150px;}

.ht-auto {height: auto;}
.ht-1 {height: 1px;}
.ht-2 {height: 2px;}
.ht-3 {height: 3px;}
.ht-4 {height: 4px;}
.ht-5 {height: 5px;}
.ht-6 {height: 6px;}
.ht-7 {height: 7px;}
.ht-8 {height: 8px;}
.ht-9 {height: 9px;}
.ht-10 {height: 10px;}
.ht-11 {height: 11px;}
.ht-12 {height: 12px;}
.ht-13 {height: 13px;}
.ht-14 {height: 14px;}
.ht-15 {height: 15px;}
.ht-16 {height: 16px;}
.ht-17 {height: 17px;}
.ht-18 {height: 18px;}
.ht-19 {height: 19px;}
.ht-20 {height: 20px;}
.ht-21 {height: 21px;}
.ht-22 {height: 22px;}
.ht-23 {height: 23px;}
.ht-24 {height: 24px;}
.ht-25 {height: 25px;}
.ht-26 {height: 26px;}
.ht-27 {height: 27px;}
.ht-28 {height: 28px;}
.ht-29 {height: 29px;}
.ht-30 {height: 30px;}
.ht-31 {height: 31px;}
.ht-32 {height: 32px;}
.ht-33 {height: 33px;}
.ht-34 {height: 34px;}
.ht-35 {height: 35px;}
.ht-36 {height: 36px;}
.ht-37 {height: 37px;}
.ht-38 {height: 38px;}
.ht-39 {height: 39px;}
.ht-40 {height: 40px;}
.ht-41 {height: 41px;}
.ht-42 {height: 42px;}
.ht-43 {height: 43px;}
.ht-44 {height: 44px;}
.ht-45 {height: 45px;}
.ht-50 {height: 50px;}
.ht-55 {height: 55px;}
.ht-60 {height: 60px;}
.ht-65 {height: 65px;}
.ht-70 {height: 70px;}
.ht-75 {height: 75px;}
.ht-80 {height: 80px;}
.ht-85 {height: 85px;}
.ht-90 {height: 90px;}
.ht-95 {height: 95px;}
.ht-100 {height: 100px;}
.ht-110 {height: 110px;}
.ht-120 {height: 120px;}
.ht-130 {height: 130px;}
.ht-140 {height: 140px;}
.ht-150 {height: 150px;}
.ht-160 {height: 160px;}
.ht-170 {height: 170px;}
.ht-180 {height: 180px;}
.ht-190 {height: 190px;}
.ht-200 {height: 200px;}
.ht-250 {height: 250px;}
.ht-260 {height: 260px;}
.ht-270 {height: 270px;}
.ht-300 {height: 300px;}
.ht-310 {height: 310px;}
.ht-320 {height: 320px;}
.ht-330 {height: 330px;}
.ht-340 {height: 340px;}
.ht-350 {height: 350px;}
.ht-400 {height: 400px;}
.ht-450 {height: 450px;}
.ht-500 {height: 500px;}
.ht-550 {height: 550px;}
.ht-600 {height: 600px;}
.ht-650 {height: 650px;}
.ht-700 {height: 700px;}

.ht-5pc {height: 5%;}
.ht-10pc {height: 10%;}
.ht-15pc {height: 15%;}
.ht-20pc {height: 20%;}
.ht-25pc {height: 25%;}
.ht-30pc {height: 30%;}
.ht-35pc {height: 35%;}
.ht-40pc {height: 40%;}
.ht-45pc {height: 45%;}
.ht-50pc {height: 50%;}
.ht-55pc {height: 55%;}
.ht-60pc {height: 60%;}
.ht-65pc {height: 65%;}
.ht-70pc {height: 70%;}
.ht-75pc {height: 75%;}
.ht-80pc {height: 80%;}
.ht-85pc {height: 85%;}
.ht-86pc {height: 86%;}
.ht-87pc {height: 87%;}
.ht-88pc {height: 88%;}
.ht-89pc {height: 89%;}
.ht-90pc {height: 90%;}
.ht-91pc {height: 91%;}
.ht-92pc {height: 92%;}
.ht-93pc {height: 93%;}
.ht-94pc {height: 94%;}
.ht-95pc {height: 95%;}
.ht-100pc {height: 100%;}
.ht-100vh {height: 100vh;}

.bt-background {background-color: #FFAF34}
.bt-background-fff {background-color: #FFFFFF}
.bt-background-grey {background-color: #eeeeee
}
.display-block {display: block;}
.display-flex {display: flex;}
.display-inline {display: inline-block;}
.flex-row {flex-direction: row;}
.flex-column {flex-direction: column;}
.flex-wrap {flex-wrap: wrap;}
.just-cont-sb { justify-content: space-between; }
.just-cont-fstart { justify-content: flex-start; }
.just-cont-ar { justify-content: space-around; }
.just-cont-center { justify-content: center; }
.just-cont-fend { justify-content: flex-end; }
.align-center {align-items: center;}
.align-center-c {align-content: center;}
.text-center {text-align: center;}
.text-right {text-align: right;}
.text-left {text-align: left;}
.text-just {text-align: justify;}
.text-underline:hover {text-decoration: underline;}
.vertical-align {vertical-align: middle;}
.position-absolute {position: absolute;}
.position-relative {position: relative;}
.position-fixed {position: fixed;}
.position-static {position: static;}

.border-c {border: var(--dark-task-border);}
.border-c-chat {border: var(--dark-task-chat-border);}
.border-c-l {border-left: var(--dark-task-border-left);}
.border-c-r {border-right: var(--dark-task-border-right);}
.border-c-b {border-bottom: var(--dark-task-border-bottom);}
.border-c-t {border-top: var(--dark-task-border-top);}
.border-rd1 {border-radius: 1px;}
.border-rd2 {border-radius: 2px;}
.border-rd3 {border-radius: 3px;}
.border-rd4 {border-radius: 4px;}
.border-rd5 {border-radius: 5px;}
.border-rd6 {border-radius: 6px;}
.border-rd7 {border-radius: 7px;}
.border-rd8 {border-radius: 8px;}
.border-rd9 {border-radius: 9px;}
.border-rd10 {border-radius: 10px;}
.border-50pc {border-radius: 50%;}
.border-bt {border: solid 1px #EAEAEA;}
.border-bt:focus {border: 2px solid #FFAF34;}
.border-bottom-bt {border-bottom: solid 1px #EAEAEA;}
.border-top-bt {border-top: solid 1px #EAEAEA;}
.border-bt-none {border: none;}
.border-thin {
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
}

.dragdisable {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}
.dragenable {
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text
}

.com-input::placeholder {color: #b9b9b9;}
.com-input::-webkit-input-placeholder {color:#b9b9b9;}
.com-input:-ms-input-placeholder {color:#b9b9b9;}

/* endregion 공용 */

/* region 이모지 */

.em-emoji-picker {
    --border-radius: 11px;
    --category-icon-size: 20px;
    --color-border-over: rgba(218, 218, 218);
    --color-border: rgb(218, 218, 218);
    --font-size: 13px;
    --rgb-accent: 255, 120, 0;
    --rgb-background: var(--back-black-and-white-background-color);
    --rgb-color: var(--imoji-rgb-color);
    --shadow: 5px 5px 12px -8px #8a8a8a;
    border: var(--table-color-border);

    min-height: 200px;
    max-height: 600px;
}

/* endregion 이모지 */

/* region 리액트 부트스트랩*/

.nav-tabs >* .nav-link, .nav-tabs >* .nav-link .active  {
    border-bottom: none;
}
.nav-tabs >* .nav-link:hover {
    border: none;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs >* .nav-link:hover {
    color: var(--tab-color);
    border-bottom: var(--tab-border-bottom);
    border-left: var(--tab-border-left);
    border-right: var(--tab-border-right);
    border-top: var(--tab-border-top);
}
.btn.btn-primary {
    background-color: #FFAF34;
    border: none;
}
.btn.btn-primary:hover {
    background-color: rgba(255, 77, 0);
}
.btn.btn-primary:active {
    background-color: #ffb400;
}

/* endregion리액트 부트스트랩*/

/*region 공용 스크롤바*/

body::-webkit-scrollbar {
    width: 1rem;
}
body::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 5px;
    background-clip: padding-box;
    border: 0.18rem solid transparent;
}
body::-webkit-scrollbar-thumb:hover {
    background-color: #858585;
}
body::-webkit-scrollbar-track {
    width: 15px;
    background-color: #f3f3f3;
}

/* endregion 공용 스크롤바*/

/* region 팝업스크롤*/

.pop-scroll::-webkit-scrollbar {
    width: 1rem;
}
.pop-scroll::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
    border-radius: 4px;
    background-clip: padding-box;
    border: 0.18rem solid transparent;
}
.pop-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #b7b7b7;
}
.pop-scroll::-webkit-scrollbar-track {
    width: 15px;
    background-color: #f3f3f3;
}

/* endregion 팝업스크롤*/

/*region 서브스크롤바 요소*/

.sub-scroll::-webkit-scrollbar {
    width: 0.9rem;
}
/*스크롤 막대*/
.sub-scroll::-webkit-scrollbar-thumb {
    background-color: #afafaf;
    border-radius: 10px;
    background-clip: padding-box;
    border: 0.248rem solid transparent;
}
.sub-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #cecece;
}
/*스크롤 막대 외부*/
.sub-scroll::-webkit-scrollbar-track {
    width: 15px;
    background-color: #ffffff;
}

/* endregion 스크롤바 요소*/

/* region TOP버튼 */

.TopBtn-bk {
    position: fixed;
    z-index: 9999;
    bottom: 70px;
    right: 11px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: rgba(171, 171, 171, 0.3);
    transition: .3s;
}
.TopBtn-up {
    display: block;
    width: 50px;
    height: 50px;
    text-align: center;
    border: none;
}
.TopBtn-bk:hover {
    background-color: rgba(171, 171, 171, 0.55);
}
/* endregion TOP버튼 */

/**************************************** Common Interface ↑ ****************************************/


















/**************************************** User Interface ↓ ****************************************/

/* region 계정 공통*/
.user-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-input, .user-textarea {
    outline: none;
    border: none;
}
.user-input-container {
    border:solid 1px #EAEAEA;
    border-radius: 4px;
}
.user-input:focus {
    border-color: #FFAF34;
}
.user-input-container:focus-within {
    border: solid 2px #FFAF34;
}
.user-input::placeholder {
    color: darkgray;
}
.acc-icon:focus-within {
    color: rgba(255,120,0,0.78);
    border-color: #ffab2c;
    box-shadow: 0 0 0 2px #ffc47d;
}
.acc-lg-icon {
    width: 15%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-top: 5px;
}
.acc-lg-icon i {
    font-size: 28px;
}
.AccPgLogo {
    /*width: 45%;*/
    /*margin: auto;*/
    height: 65px;
    /*display: flex;*/
    /*overflow: hidden;*/
    justify-content: center;
    /*margin-bottom: 80px;*/
    /*padding: 18px 23px;*/
    /*border: solid 1px blue;*/
}
.AccPgLogo a{
    display: flex;
    overflow: hidden;
    justify-content: center;
}
.acc-input-form {
    position: relative;
    width: 100%;
}
.acc-submit button {
    width: 100%;
    height: 100%;
    background-color: #FFAF34;
    border: none;
}
.acc-submit button:hover {
    background-color: #FF9924;
    border: none;
}
.acc-submit b:focus {
    background-color: #FF9924;
    border: none;
    --bs-btn-focus-shadow-rgb: 255, 120, 88
}
.acc-submit button:after {
    background-color: #FF9924;
}
#accSubmitBt:active {
    background-color: #FF9924;
    border: none;
}
.acc-agree {
    height: 50px;
    width: 90%;
    display: flex;
    float: left;
}
.acc-agree input {
    border-style: dotted;
    cursor: pointer;
    width: 20px;
    height: 35px;
    accent-color: rgba(255, 95, 0, 0.91);
    margin-top: 4px;
}
.acc-agree span {
    font-size: 13px;
    color: rgba(255, 95, 0, 0.91);
}
.acc-agree-a {
    justify-content: center;
    font-size: 28px;
    padding-bottom: 10px;
}
.acc-agree-LinkBox {
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.acc-agree-Text {
    font-size: 13px;
    color: grey;
}
.acc-agree label {
    cursor: pointer;
    margin-left: 10px;
    color: dimgrey;
    width: 100%;
    height: 30px;
    margin-top: 8px;
}
.acc-bottom-section span {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 30px;
    overflow: hidden;
}
.acc-bottom-section ul {
    overflow: hidden;
    margin:0;
    padding:0;
    display: inline;
    width: 400px;
}
.acc-bottom-section li, .acc-bottom-section-l li {
    display: inline-block;
    list-style: none;
    position: relative;
}
.acc-bottom-section li>a, .acc-bottom-section-l li>a {
    padding: 2px 15px;
    display: flex;
    text-align: center;
    color: dimgrey;
    text-decoration: none;
    font-size: 14px;
}
.acc-bottom-section li>a:hover, .acc-bottom-section-l li>a:hover {
    color: orangered;
}
.acc-bottom-section li>a:focus, .acc-bottom-section-l li>a:focus {
    color: orangered;
}
.acc-bottom-section li>a::before, .acc-bottom-section-l li>a::before {
    content:"";
    width: 1px;
    height: 15px;
    border-left: 1px solid lightgrey;
    position: absolute;
    top: 7px;
    left: -1px;
}

.customContent input:focus {
    outline: 1px solid orange !important;
}

/* endregion 계정 공통*/

/*리액트 부트스트랩 셀렉트(커스텀)*/
.ReviewPgCountrySelect {
    text-align: center;
    width: 270px;
    height: 60px;
    font-size: 18px;
}
.ReviewPgCountrySelect:focus {
    border-color: #FFAF34;
    box-shadow: 0 0 0 3px #ffc47d;
}
/*여기서부터 리뷰페이지 검색창 디자인(JoinLabel포함/Frame에 acc-icon 넣어야 박스쉐도우 적용)*/
.JoinLabel {
    pointer-events:none;
    position:absolute;
    left:0;
    bottom:0;
    padding:0 6px;
    color:#999;
    transition:all .2s;
    font-size:14px;
    font-weight:normal;
    background:white;
    transform:translate(10px, -18px);
}
.ReviewPgSearchFrame {
    border: solid 1px #EAEAEA;
    height: 55px;
    border-radius: 4px;
    display: flex;
}
.ReviewPgInputForm {
    position: relative;
    margin-left: 2px;
}
.ReviewInput {
    font-size: 16px;
    /*padding: 15px 17px;*/
    box-sizing:border-box;
    outline: none;
    border: none;
    height: 100%;
    width: 95%;
}
.ReviewInput:not(:placeholder-shown).ReviewInput:not(:focus) + .JoinLabel {
    transform: scale(.75) translateY(-60px) translateX(5px);
    color: dimgrey;
}
.ReviewInput:focus + .JoinLabel {
    transform: scale(.75) translateY(-60px) translateX(5px);
    color: #FFAF34;
}
.ReviewPgSearchBtt {
    width: 20%;
    margin-left: 10px;
}
.ReviewPgSearchBtt a{
    font-size: 30px;
}

/*여기서부터 부트스트랩 메인섹션 핸들러버튼*/
.MainReviewButton1 {
    border-style: solid;
    border-radius: 8px;
    border-color: rgb(255,120,0,0.78);
    color: rgb(255,120,0,0.78);
    padding: 15px;
    margin: 0 5px;
}
.MainReviewButton1:hover {
    color: white;
    background: rgb(255,120,0,0.78);
    border-color: white;
}
.MainReviewButton1:focus {
    color: white;
    background: rgb(255,120,0,0.78);
    border-color: white;
    --bs-btn-focus-shadow-rgb: 255, 110, 10;
}
/*여기서부터 직접 구현한 리뷰페이지 Write버튼*/
.ReviewPgWriteBtn {
    border: solid 1px #FFB124;
    border-radius: 7px;
    color: #FFB124;
}
.ReviewPgWriteBtn:hover {
    color: white;
    background: #FFAF34;
    border-color: white;
    transition: 0.3s;
}
.ReviewPgWriteBtn:focus {
    background: #FFAF34;
    border-color: white;
    box-shadow: 0 0 0 4px #FFB124;
    color: white;
    transition: 0.3s;
}

/* OrderPg button1 */
.OrderPgBt {
    border: solid 1px #FFAF34;
    width: 75px;
    height: 38px;
    font-size: 14px;
    border-radius: 4px;
    color: #FFAF34;
}
.OrderPgBt:hover {
    color: white;
    background: #FFB124;
    border-color: white;
    transition: 0.3s;
}
.OrderPgBt:focus {
    background: #FFB124;
    border-color: white;
    box-shadow: 0 0 0 4px #FFB124;
    color: white;
    transition: 0.3s;
}

/* OrderPg button2 */
.OrderPgBt2 {
    border: solid 1px #EAEAEA;
    width: 75px;
    height: 38px;
    font-size: 12px;
    border-radius: 4px;
    color: darkgray;
}

.OrderPgBt3 {
    border: solid 1px #EAEAEA;
    font-size: 12px;
    border-radius: 4px;
    /*color: darkgray;*/
}
.OrderPgBt2:hover, .OrderPgBt3:hover {
    color: white;
    background: lightgray;
    border-color: white;
    transition: 0.3s;
}
.OrderPgBt2:focus {
    background: lightgray;
    border-color: white;
    box-shadow: 0 0 0 4px lightgray;
    color: white;
    transition: 0.3s;
}

/* More Button1 */
.moreBt1 {
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    color: darkgray;
}
.moreBt1:hover {
    color: white;
    background: lightgray;
    border-color: white;
    transition: 0.3s;
}
.moreBt1:focus {
    background: lightgray;
    border-color: white;
    box-shadow: 0 0 0 4px lightgray;
    color: white;
    transition: 0.3s;
}

/*공통 커스텀 그레이색상 버튼*/
.grayBt {
    border:solid;
    border-width: 1px thin;
    border-color: #EAEAEA;
    border-radius: 7px;
    color: darkgray;
}
.grayBt:hover {
    color: white;
    background: darkgray;
    border-color: darkgray;
    transition: 0.3s;
}
.grayBt:focus {
    background: darkgray;
    border-color: darkgray;
    box-shadow: 0 0 0 4px darkgray;
    color: darkgray;
    transition: 0.3s;
}

/*여기서부터 공용 인풋 시작(사이즈는 커스텀)*/
.acc-input-icon:focus-within {
    color: rgba(255,120,0,0.78);
    border-color: #ffab2c;
    box-shadow: 0 0 0 2px #ffc47d;
}
.acc-input-lg-icon {
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.acc-input-lg-icon i {
    font-size: 28px;
}
.acc-input-container {
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    display: flex;
}
.acc-input-inform {
    box-sizing:border-box;
    outline: none;
    border: none;
    border-radius: 4px;
}
.acc-input-inform:not(:placeholder-shown).acc-input-inform:not(:focus) + .inputLabel {
    transform: scale(.75) translateY(-47px) translateX(-10px);
    color: dimgrey;
}
.acc-input-inform:focus {
    border-color: #FFAF34;
}
.acc-input-inform:focus + .inputLabel {
    transform: scale(.75) translateY(-47px) translateX(-10px);
    color: #FFAF34;
    background-color: white;
}
.inputLabel {
    transition:all .2s;
    pointer-events:none;
    position:absolute;
    left:0;
    bottom:0;
    padding:0 6px;
    color:#999;
    font-size:14px;
    font-weight:normal;
    background:white;
    transform:translate(5px, -12px);
}

/*여기까지 공용 인풋 [종료]*/

/*여기서부터 공용 체크박스 [시작]*/

.acc-ckbox-frame {
    width: 33.33%;
    display: flex;
    padding-top: 2px;
}
.acc-ckbox-frame label {
    cursor: pointer;
    margin-left: 10px;
    color: dimgrey;
    margin-bottom: 5px;
    height: 30px;
}
.acc-ckbox-frame input {
    cursor: pointer;
    width: 20px;
    height: 20px;
    accent-color: rgba(255,95,0,0.91);
    margin-top: 4px;
}

/*여기까지 공용 체크박스 [종료]*/

/*여기서부터 공용 커스텀 셀렉트박스 [시작]*/
.selectParentBt {
    border: solid 1px #e1e1e1;
    border-radius: 4px;
}
.selectParentBt:focus-within {
    border: 2px solid #FFAF34;
}

.selectChildBt {
    background-color: white;
    color: dimgrey;
}
.selectChildBt:hover {
    background-color: #FFAF34;
    color: white;
}
.selectChildBt img {
    filter: grayscale(100%) brightness(200%) contrast(5%);
}
.selectChildBt:hover img {
    filter: brightness(0) invert(1);
}
.selectChildBt p {
    color: black;
    font-weight: lighter;
}
.selectChildBt:hover p {
    color: white;
}

.selectChildColorBt {
    background-color: white;
    color: dimgrey;
}
.selectChildColorBt:hover {
    background-color: #FFAF34;
    color: white;
}
.selectChildColorBt:hover img {
    filter: brightness(0) invert(1);
}
.selectChildColorBt p {
    color: black;
    font-weight: lighter;
}
.selectChildColorBt:hover p {
    color: white;
}

/*여기까지 공용 커스텀 셀렉트박스 [종료]*/

/**************************************** User Interface ↑ ****************************************/

















/**************************************** Admin Interface ↓ ****************************************/

.dark-border-bottom {border-bottom: var(--dark-task-border-bottom);}
.dark-border-bottom-2px {border-bottom: var(--dark-task-2px-border-bottom);}
/* 차트 */
.Admin-Card-wrap {
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2.3rem;
}
.Admin-Chart {
    width: 1585px;
    margin-left: auto;

    margin-right: auto;
    padding: 5px;
    border-radius: 9px;
    background-color: #ffffff;
}
.Admin-Table {
    text-align: center;
    font-size: 14px;
    padding: 30px 20px 20px 20px;
    border-radius: 9px;
    background-color: #ffffff;
}
.Admin-Table thead {
    font-size: 15px;
}
.table-bt-wrap {
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.table-bt {
    width: 80px;
    margin-left: 7px;
    font-size: 12px;
    box-shadow: 3px 5px 8px 1px rgba(200, 200, 200, 0.2);
    background: #f6f6f6;
    border: 1px solid #dee2e6;
    border-radius: 3px;
}
.table-bt:hover {
    background: #e5e7ea;
}
.bt-date {
    width: 55px;
}
.bt-year-f {
    margin-left: 15px;
}
.bt-date-f {
    margin-left: 15px;
}
.table-title {
    text-align: left;
    padding-left: 8px;
    margin-top: 25px;
}
/* 차트 */

.ad-ymd-input[type="date"]::-webkit-datetime-edit-text,
.ad-ymd-input[type="date"]::-webkit-datetime-edit-month-field,
.ad-ymd-input[type="date"]::-webkit-datetime-edit-day-field,
.ad-ymd-input[type="date"]::-webkit-datetime-edit-year-field {
    color: var(--ad-ymd-input-color);
}
.ad-ymd-input[type="date"] {
    position: relative;
    padding-right: 20px;
    background: var(--input-date-background);
}
.ad-ymd-input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent;
}

.admin-wrap {
    display: block;
    padding: 0.7rem 2.3rem 2.3rem 2.3rem;
}
.admin-wrap-card {
    background-color: #ffffff;
    border-radius: 9px;
    padding: 15px;
}
.admin-btn-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.admin-btn-wrap-l {
    display: block;
    text-align: left;
}
.admin-btn-wrap-r {
    display: block;
    text-align: right;
}
.admin-input-line {
    border-radius: 4px;
}
.admin-input-line-B {

}
.admin-input-line:focus {
    border: solid 2px #FFAF34;
}
.admin-input-line-basic {
    height: 25px;
    font-size: 13px;
}
.admin-input-line-basic:focus {
    outline: solid 1px #FFAF34;
    border-radius: 2px;
}
.admin-btn-basic {
    font-size: 12px;
    /*margin: 3px;*/
    box-shadow: rgba(63, 71, 77, 0.15) 0 1px 5px;
    /*background-color: #ececec;*/
    border: var(--dark-task-border);
    border-radius: 4px;
}
.admin-btn-basic:hover, .admin-btn-basic-B:hover {
    background: #d5d5d5;
}
.admin-btn-basic-B {
    box-shadow: rgba(63, 71, 77, 0.15) 0 1px 5px;
    border: var(--dark-task-border);
    border-radius: 4px;
}
.admin-btn-short-B {
    margin: 2px;
    width: 40px;
    height: 21px;
    font-family: Pretendard-Regular, sans-serif;
}
.admin-btn-short {
    margin: 2px;
    width: 68px;
    height: 21px;
    font-family: Pretendard-Regular, sans-serif;
}
.admin-btn-middle {
    margin: 2px;
    width: 90px;
    height: 22px;
    font-family: Pretendard-Regular, sans-serif;
}
.admin-btn-long {
    margin: 3px;
    width: 100px;
    height: 30px;
    font-family: Pretendard-Regular, sans-serif;
}
.admin-btn:hover {
    background-color: #dee2e6;
}
.admin-work-title {
    display: flex;
    flex-direction: row;
    padding: 1.1rem 2.3rem 0.7rem 1.8rem;
    cursor: default;
}
.admin-work-title p {
    font-family: LeferiPoint-BlackA, sans-serif;
    font-size: 19px;
}
.admin-work-title h5, .admin-work-title b, .admin-work-title strong {
    display: inline-block;
    padding-left: 8px;
    border-left: 7px solid #FFAF34;
    font-family: LeferiPoint-BlackA, sans-serif;
}
.admin-combo {
    border-radius: 4px;
    outline: none;
    appearance: none;
    background: var(--admin-combo-background);
    padding-left: 7px;
    color: var(--admin-combo-color);
}
.admin-combo:focus {
    border: solid 1px #FFAF34;
}
.admin-combo-new {
    border-radius: 3px;
    outline: none;
    appearance: none;
    padding-left: 7px;
    color: #5b5b5b;
}
.admin-combo-new:focus {
    border: solid 1px #FFAF34;
}
.admin-combo-not-font {
    border: solid 1px #bdbdbd;
    border-radius: 3px;
    outline: none;
    appearance: none;
}
.admin-combo::-ms-expand {
    display: none;
}
.admin-event-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 17px;
}
.admin-event-wrap-btn {
    display: flex;
    flex-direction: row;
}
.AdminEventBtn div {
    margin: 3px 5px 3px 5px;
}
#ex-btn:hover {
    background-color: #FFAF34;
    color: white;
}
.pro-edit-icon:hover {transform: scale(1.12);}
.pro-edit-icon:active {transform: scale(1);}

/*테이블*/

.div-tb {
    display: table;
    width: 100%;
    table-layout: fixed;
    border: var(--dark-task-border);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    /*border-bottom: none;*/
    border: none;
}
.div-tb-head {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: var(--div-tb-head-background-color);
    border-bottom: var(--dark-task-border-bottom);
    box-shadow: rgba(63, 71, 77, 0.15) 0 1px 1px;
    font-family: LINESeedKR-Bd, sans-serif;

    position: sticky;
    top: 0;
    /*z-index: 1;*/
}
.div-tb-head div {
    position: sticky;
}
.div-tb-head-B {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: rgba(255, 232, 182, 0.8);
    border-bottom: var(--dark-task-border-bottom);
    font-family: LINESeedKR-Bd, sans-serif;
}
.div-tb-head-C {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #f6f6f6;
    border-bottom: 1px solid #EAEAEA;
    font-family: LINESeedKR-Bd, sans-serif;
}
.div-tb-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-bottom: var(--dark-task-border-bottom);
    background-color: var(--contents-box-dt-background-color);
    transition: .25s;
    font-family: Pretendard-Regular, sans-serif;
}
.div-tb-body:hover {
    background-color: var(--div-tb-head-background-color);
}
.div-tb-head div, .div-tb-head-B div, .div-tb-head-C div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    /*border: solid 1px red;*/
}
.div-tb-body div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-family: Pretendard-Regular, sans-serif;
    /*border: solid 1px blue;*/
}
.div-tb-head div p, .div-tb-head-B div p {
    font-size: 14px;
    font-weight: bold;
    cursor: default;
    margin: 0;
}
.div-tb-head-C div p {
    font-size: 12px;
    font-weight: bold;
    cursor: default;
    margin: 0;
}
.div-tb-body div p {
    font-size: 14px;
    cursor: default;
    margin: 0 auto;
}

.div-tb-body div a {
    font-size: 14px;
    margin: 0 auto;
}


.admin-ver-tb {
    width: 100%;
    display: table;
    table-layout: fixed;
    font-size: 14px;
    border: var(--dark-task-border);
}
.ver-tb-body > tr > th {
    height: 50px;
    background-color: var(--div-tb-head-background-color);
    text-align: center;
    padding-top: 10px;
}
.ver-tb-body > tr > td:hover {
    background-color: var(--div-tb-head-background-color);
}
.ver-tb-body > tr > td {
    padding-top: 10px;
    padding-left: 7px;
    /*border: solid 1px red;*/
}



.admin-tb {
    table-layout: fixed;
    width: 100%;
}
.admin-tb-head-line th, .admin-tb-body-line td {
    vertical-align: middle;
}
.admin-tb-head {
    /*background-color: #f1f1f5;*/
    border: solid 1px #dee2e6;
}
.admin-tb-head b {
    font-size: 13px;
    cursor: default;
}
.admin-tb-head th {
    text-align: center;
}
.admin-tb-head-line {

}
.admin-tb-head-cont {

}
.admin-tb-body {
    border: solid 1px #dee2e6;
}
.admin-tb-body a {
    font-size: 12px;
}
.admin-tb-body td {
    text-align: center;
}
.admin-tb-body-line {

}
.at-bl-td td {
    text-align: left;
}
.admin-tb-body-line:hover {
    /*background-color: rgba(255, 168, 56, 1);*/
    /*font-weight: bold;*/
}
.admin-tb-body-cont {

}
.admin-tb-header-notice {
    font-size: 6px;
    margin-right: 6px;
    margin-bottom: 2px;
    color: #ff8f30;
}
/*텍스트자르기*/
.admin-tb-text-cut {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.m-a-text-cut {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
/*테이블*/

/*테이블 B-type*/

.admin-tb-head-B-line th, .admin-tb-body-B-line td {
    vertical-align: middle;
}
.admin-tb-head-B {
    /*background-color: #f1f1f5;*/
}
.admin-tb-head-B b {
    font-size: 14px;
    cursor: default;
}
.admin-tb-head-B th {
    text-align: center;
}
.admin-tb-body-B a {
    font-size: 13px;
}
.admin-tb-body-B td {
    text-align: center;
}
.admin-tb-body-B .col3 {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.admin-tb-body-B li{
    display: flex;
    flex-direction: row;
    text-align: center;
}

/*테이블 B-type*/

/*모달*/
.admin-moadl-ct {
    text-align: center;
    padding: 7px 10px 7px 10px;
}
.admin-modal-default > .regModal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.3);
}
.admin-modal-default > .regModal > section {
    display: flex;
    flex-direction: column;
    position: relative;
    /*width: 100%;*/
    /*max-width: 450px;*/
    width: 850px;
    height: 900px;
    margin: 0 auto;
    /*border-radius: 5px;*/
    background-color: #fff;
    animation: modal-show .3s;
    overflow: hidden;
}
.admin-modal-default > .regModal > section > header {
    position: relative;
    padding: 14px;
    background-color: #3c3f49;
}
.admin-modal-default > .regModal > section > main {
    padding: 15px;
    border-bottom: 1px solid #dee2e6;
    /*border-top: 1px solid #dee2e6;*/
    overflow-y:auto;
}
.admin-modal-default > .regModal > section > main::-webkit-scrollbar {
    width: 8px;
}
.admin-modal-default > .regModal > section > main::-webkit-scrollbar-thumb:hover {
    background-color: #3c3f49;
}
.admin-modal-default > .regModal > section > main::-webkit-scrollbar-thumb {
    background: rgba(78, 81, 91, 0.6);
    border-radius: 3px;
}
.admin-modal-default > .regModal > section > main::-webkit-scrollbar-track {
    background: rgba(60, 63, 73, 0.1);
}
.admin-modal-default > .regModal > section > footer {
    /*position: relative;*/
    text-align: center;
    padding: 10px 0 10px 0;
}
.admin-modal-default > .regModal.openModal {
    display: flex;
    align-items: center;
    animation: modal-bg-show .3s;
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -120px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
/*모달*/


/*페이지네이션*/

.AdminPagination {
    display: block;
    text-align: center;
}
.pagination {
    display: flex;
    justify-content: center;
}
ul.pagination li {
    width: 38px;
    height: 38px;
    text-align: center;
    padding-top: 6px;
    border-radius: 4px;
    cursor: pointer;
}
ul.pagination li a {
    text-decoration: none;
}
ul.pagination li.active a {
    color: #FFAF34;
}
ul.pagination li.active {
    border: solid 1px #FFAF34;
    font-weight: bold;
}
ul.pagination li:hover {
    background-color: #fff2da;
    transition: background-color .35s;
}

/*페이지네이션*/

.admin-profile-modi {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-style: none;
}

.admin-profile-modi-img {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-style: none;
    transition: 0.35s;
}

.profile-modi-icon {
    position: absolute;
    transition: 0.35s;
    opacity: 0;
}

.admin-profile-modi:hover .profile-modi-icon {
    opacity: 1;
}

.admin-profile-modi:hover .admin-profile-modi-img {
    filter: brightness(55%);
}

.admin-input-chkBox-s {
    width: 15px;
    height: 15px;
}

.admin-input-chkBox {
    width: 17px;
    height: 17px;
}
.m-a-admin-input-chkBox {
    width: 19px;
    height: 19px;
}
/**************************************** Admin Interface ↑ ****************************************/

/*공통 버튼 로딩애니메이션*/
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
}

.blackSpinner {
    border: 4px solid #FFECCA;
    border-top: 4px solid #FFAF34;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
}

.orangeSpinner {
    border: 4px solid #FFECCA;
    border-top: 4px solid #FFAF34;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    animation: spin 1s linear infinite;
}

.greySpinner {
    border: 4px solid lightgrey;
    border-top: 4px solid #EAEAEA;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

.smallSpinner {
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

/**************************************** Admin WebApp Interface ↓ ****************************************/
.m-a-common-header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 8;
    height: 50px;
    background-color: var(--m-a-000-and-fff-background-color);
}
.m-a-backIcon {
    position: absolute;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    filter: var(--m-a-backIcon-filter);
    opacity: .75;
}
.m-a-pg-header-text {
    flex-grow: 1;
    text-align: center;
    color: var(--contents-color);
}

.m-a-sign-container {
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    /*padding: 0 5% 0 5%;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.m-a-fixed-container {
    height: calc(100vh - 50px);
    width: 100%;
    flex-grow: 1;
    padding: 50px 15px 50px 15px;
    overflow-y: auto;
}
.m-a-fixed-container-b {
    height: calc(100vh - 50px);
    width: 100%;
    flex-grow: 1;
    padding: 50px 15px 100px 15px;
    overflow-y: auto;
}
.m-a-m-fixed-bt-frame {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.13);
    font-family: Pretendard-Regular, sans-serif;
}
.m-a-m-fixed-bt-frame-b {
    position: fixed;
    height: 100px;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--m-a-000-and-fff-background-color);
    border-top: var(--dark-task-border-top);
    font-family: Pretendard-Regular, sans-serif;
}

/*공통인풋(M)*/
.m-a-input-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    background-color: var(--m-a-000-and-fff-background-color);
}
.m-a-input:focus {
    outline: solid 1px #ff9100;
    border-radius: 2px;
}
.m-a-input-frame-mgb5 {
    margin-bottom: 7px;
}
.m-a-input {
    padding: 0 10px;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 4px;
    caret-color: #ff9100;
    color: var(--contents-color);
    background-color: var(--m-a-000-and-fff-background-color);
}

.m-a-input::placeholder {
    color: #adadad;
    opacity: 1;
}

.m-a-textarea-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    background-color: var(--m-a-000-and-fff-background-color);
}
.m-a-textarea-b {
    padding: 0 10px;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    resize: none;
    overflow: auto;
    color: var(--contents-color);
    caret-color: #ff9100;
    border-radius: 4px;
    background-color: var(--m-a-000-and-fff-background-color);
}
.m-a-textarea-b:focus {
    outline: solid 1px #ff9100;
    border-radius: 2px;
}
/*공통 textarea(no hidden)*/
.m-a-textarea {
    resize: none;
    overflow: auto;
    outline: none;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 6px;
}
.m-a-common-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-vertical-solid {
    content: '';
    display: inline-block;
    width: 1px;
    height: 13px;
    background-color: darkgray;
    margin: 0 10px;
    vertical-align: middle;
}
/*컬러버튼(Full/border-r-none)*/
.m-a-colorBt-radius-n {
    outline: none;
    border: none;
    background-color: #FFAF34;
}
/*컬러버튼(Full)*/
.m-a-colorBt {
    outline: none;
    border: none;
    background-color: #FFAF34;
    border-radius: 4px;
}
.m-a-colorBt-text {
    color: white;
    font-size: 15px;
}
.m-a-colorBt:active {
    background-color: #FFAF34;
}
/*컬러버튼(Border)*/
.m-a-colorBt-B {
    border: var(--btorage-color-border);
    border-radius: 4px;
    background: none;
    color: var(--btorage-color);
}
.m-a-colorBt-B:hover {
    color: #fff;
    background-color: #ffaf34;
}
.m-a-colorBt-B:active {
    background-color: #ff9419;
    color: #fff;
}
/*연그레이버튼*/
.m-a-gr-Bt-radius-n {
    outline: none;
    border: none;
    background-color: lightgray;
}
/*연그레이버튼*/
.m-a-gr-Bt {
    outline: none;
    border: none;
    background-color: lightgray;
    border-radius: 4px;
}
.m-a-gr-Bt-text {
    color: white;
    font-size: 15px;
}
.m-a-gr-Bt:active {
    background-color: darkgray;
}

/*화이트버튼*/
.m-a-commonBt {
    outline: none;
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    border-radius: 4px;
}
.m-a-commonBt-text {
    color: var(--contents-color);
    font-size: 14px;
}
.m-a-commonBt:active {
    background-color: lightgray;
    color: white;
}
.m-a-error-msg-container {
    display: flex;
    align-items: center;
    color: #FF6324;
}

/*파일관련팝업*/
.m-a-file-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-file-modal-frame {
    width: 370px;
    height: 450px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
.m-a-file-modal-hd-frame {
    border-bottom: solid;
    height: 15%;
    border-color: #EAEAEA;
    border-width: thin 1px;
    display: flex;
    padding: 10px 0;
}
.m-a-file-icon-container {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-file-icon {
    width: 40px;
    height: 40px;
}
.m-a-file-head-text {
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
}
.m-a-file-close {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-a-file-close-img {
    width: 35px;
    height: 35px;
    opacity: 0.6;
}
.m-a-file-content-container {
    height: 54%;
    padding: 10px;
}
.m-a-file-content-fileNm {
    width: 100%;
    height: 8%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.m-a-file-content-fileNm-text {
    color: dimgray;
    font-weight: lighter;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.m-a-file-content-file-exchange-frame {
    height: 8%;
}
.m-a-file-content-file-exchange-img {
    width: 35px;
    height: 35px;
}
.m-a-file-content-textarea-frame {
    height: 100%;
    width: 100%;
    overflow: auto;
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    border-radius: 6px;
    padding: 10px;
}
.m-a-file-content-textarea-frame:focus-within {
    border: solid 1px darkorange;
}
.m-a-file-content-textarea {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    color: dimgrey;
}
.m-a-file-bt-container {
    /*height: 15%;*/
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}
.m-a-combo {
    border-radius: 4px;
    outline: none;
    appearance: none;
    background: var(--admin-combo-background);
    padding-left: 5px;
    background-color: var(--m-a-000-and-fff-background-color);
    border: solid 1px #EAEAEA;
    color: var(--contents-color);
    font-family: Pretendard-Regular, sans-serif;
}
.m-a-combo:focus, .m-a-ymd-input:focus {
    border: solid 1px #FFAF34;
    padding-left: 7px;
}
.m-a-ymd-input {
    outline: none;
    appearance: none;
    /*padding-left: 3px;*/
    color: var(--contents-color);
    font-family: Pretendard-Regular, sans-serif;
    background-color: var(--m-a-000-and-fff-background-color);
    border-color: #EAEAEA;
    border-radius: 4px;
    /*color: var(--ad-ymd-input-color);*/
    background: var(--input-date-background);
}

.m-a-opc-03 {opacity: 0.3;}
.m-a-opc-04 {opacity: 0.4;}
.m-a-opc-05 {opacity: 0.5;}
.m-a-opc-06 {opacity: 0.6;}
.m-a-opc-07 {opacity: 0.7;}
.m-a-opc-08 {opacity: 0.8;}
.m-a-opc-09 {opacity: 0.9;}
.m-a-flex-jc-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.m-a-flex-jc-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.m-a-event-frame {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.m-a-header-event {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
    margin-bottom: 13px;
}
.m-a-header-event-search {
    display: inline-block;
    width: 100%;
}
.m-a-header-event-btn, .m-a-header-event-combo {
    display: flex;
    flex-direction: row;
}
.m-a-search {
    display: block;
    border: solid 1px #EAEAEA;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: var(--m-a-000-and-fff-background-color);
    padding-left: 5px;
    font-size: 15px;
    color: var(--contents-color);
    font-family: Pretendard-Regular, sans-serif;
    caret-color: #ff9100;
}
.m-a-all-none {
    background: none;
    border: none;
    outline: none;
}

.pop-dt-img-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.pop-dt-img-frame {
    position: relative;
    width: 650px;
    height: 600px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    padding: 35px;
}

.pop-dt-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pop-dt-close {
    position: absolute;
    top: 11px;
    right: 6px;
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 19px;
    color: #fff;
    font-weight: bold;
}

.admin-new-btn-color {
    background-color: #FFAF34;
    border-radius: 4px;
    outline: none;
    border: none;
    color: #fff;
}
.admin-new-btn-color:hover {
    background-color: #ff9830;
}
.bt-border-hover:hover {
    border-color: #FFAF34;
}











/*관리자 주황테두리 버튼*/
.admin-btn-A {
    font-family: Pretendard-Regular, sans-serif;
    background-color: transparent;
    border: var(--btorage-color-border);
    color: var(--btorage-color);
    border-radius: 4px;
    transition: 0.2s;
    font-weight: bold;
}
.admin-btn-A:hover{
    background-color: var(--btorage-background-color);
    color: #FFF;
    /*border: none;*/
}

/*관리자 기본 버튼A*/
.admin-btn-B {
    font-family: Pretendard-Regular, sans-serif;
    background-color: transparent;
    /*box-shadow: rgba(63, 71, 77, 0.1) 0 1px 1px;*/
    border: var(--dark-task-border);
    /*color: var(--contents-color);*/
    color: #b2b2b2;
    border-radius: 4px;
    transition: 0.2s;
}
.admin-btn-B:hover{
    color: #fff;
    background-color: #dedede;
    border: 1px solid #dedede;
}

/*관리자 주황채우기 버튼*/
.admin-btn-C {
    font-family: Pretendard-Regular, sans-serif;
    background-color: var(--btorage-background-color);
    border: none;
    color: #fff;
    border-radius: 4px;
    transition: 0.2s;
    font-weight: bold;
}
.admin-btn-C:hover{
    background-color: #ff782e;
}









.country-select-flag {
    background-color: #EFEFEF;
    border: none;
    color: #b2b2b2;
}
.country-select-data {
    border: solid 1px #EAEAEA;
    top: 100%;
    left: 0;
    box-sizing: border-box;
    z-index: 1000;
    /*display: block;*/
}
.country-select-data-child {
    max-height: 400px;
    overflow-y: auto;
}
.country-select-in-input {
    border: none;
    outline: none;
}
.order-rec-country-input {
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.order-rec-country-input:focus-within {
    outline: 2px solid #FFAF34;
    border: solid 1px #fff;
}




.dropdown-container {
    position: relative;
    width: 300px;
    font-family: Arial, sans-serif;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 4px;
}

.country-placeholder {
    color: #999;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1000;
}

.search-input {
    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #ccc;
}

.dropdown-list {
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.no-country {
    padding: 10px;
    color: #999;
}

.modalFrame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    border-radius: 8px;
}
.modalFrame-lang {
    width: 300px;
    height: auto;
    background-color: #fff;
}

@media (max-width: 720px) {
    .modalFrame-lang-price-err {
        width: 75%;
        height: auto;
    }
}

.modalFrame-lang-price-err {
    width: 350px;
    height: auto;
    background-color: #fff;
}
@media (max-width: 720px) {
    .modalFrame-lang-price-err {
        width: 85%;
        height: auto;
    }
}
.price-pop-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    align-items: center;
    border-radius: 8px;
    z-index: 20;
    width: 600px;
    height: 700px;
}
@media (max-width: 720px) {
    .price-pop-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 70%;
    }
}

.arrow-selected {
    background-color: #FFAF34;
}




/*보더BT색*/
.bt-btn-A {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 4px;
    transition: 0.3s;
    border: 1px solid #FFAF34;
    color: #FFAF34;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    min-width: 30px;
    /*max-width: 120px;*/
    min-height: 30px;
    max-height: 50px;
}
.bt-btn-A:hover {
    background-color: var(--btorage-background-color);
    color: #FFF;
}

/*백그라운드BT색*/
.bt-btn-B {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 4px;
    transition: 0.3s;
    background-color: #FFAF34;
    border: none;
    color: #FFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    min-width: 30px;
    /*max-width: 120px;*/
    min-height: 30px;
    max-height: 50px;
}
.bt-btn-B:hover {
    background-color: #ff8913;
    color: #FFF;
    border: none;
}

/*백그라운드BT색-B*/
.bt-btn-B-B {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 4px;
    transition: 0.3s;
    background-color: #ff8913;
    border: none;
    color: #FFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    min-width: 30px;
    /*max-width: 120px;*/
    min-height: 30px;
    max-height: 50px;
}
.bt-btn-B-B:hover {
    background-color: #FFAF34;
    color: #FFF;
    border: none;
}

/*보더흰색A*/
.bt-btn-C {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 4px;
    transition: 0.3s;
    border: 1px solid #e0e0e0;
    color: #6c6c6c;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    min-width: 30px;
    /*max-width: 120px;*/
    min-height: 30px;
    max-height: 50px;
}
.bt-btn-C:hover {
    background-color: #e0e0e0;
    color: #FFFFFF;
}

/*백그라운드흰색*/
.bt-btn-D {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 4px;
    transition: 0.3s;
    background-color: #e0e0e0;
    border: none;
    color: #6c6c6c;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    min-width: 30px;
    /*max-width: 120px;*/
    min-height: 30px;
    max-height: 50px;
}
.bt-btn-D-B {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 4px;
    transition: 0.3s;
    background-color: #e0e0e0;
    border: none;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    min-width: 30px;
    /*max-width: 120px;*/
    min-height: 30px;
    max-height: 50px;
}
.bt-btn-D:hover, .bt-btn-D-B:hover {
    background-color: #afafaf;
    color: #FFF;
    border: none;
}

/*보더흰색B*/
.bt-btn-E {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 4px;
    transition: 0.3s;
    border: 1px solid #e0e0e0;
    color: #6c6c6c;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    min-width: 30px;
    /*max-width: 120px;*/
    min-height: 30px;
    max-height: 50px;
}
.bt-btn-E:hover {
    background-color: rgba(224, 224, 224, 0.4);
}

/*백그라운드흰색*/
.bt-btn-D {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 4px;
    transition: 0.3s;
    background-color: #e0e0e0;
    border: none;
    color: #6c6c6c;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    min-width: 30px;
    /*max-width: 120px;*/
    min-height: 30px;
    max-height: 50px;
}
.bt-btn-D:hover {
    background-color: #afafaf;
    color: #FFF;
    border: none;
}
.bt-basic-input {
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    transition: 0.2s;
    color: #6c6c6c;
    outline: none;
    border-radius: 4px;
    min-width: 30px;
    min-height: 30px;
    max-height: 50px;
    padding: 0 8px;
}
.bt-basic-input:hover {
    border: 1px solid #FFAF34;
    border-radius: 4px;
    min-width: 30px;
    min-height: 30px;
    max-height: 50px;
}


.m-login-app-btn {
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0 1px 5px rgba(44, 44, 44, 0.2);
    border-radius: 11px;
    transition: box-shadow 0.3s ease, border 0.3s ease, width 0.3s ease;
    margin: auto;
    color: #ff8913;
    text-align: center;
}

/*.m-login-app-btn:hover {*/
/*    background-color: #FFAF34;*/
/*    color: #FFF;*/
/*    border: none;*/
/*}*/
.m-u-app-icon-container-B {
    width: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.m-u-app-icon-container-B img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.app-default-image-B {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.app-default-image-B.fade-out {
    opacity: 0;
    transform: translateY(30px);
    display: none;
}

.app-btn-text {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.app-btn-text.fade-out {
    opacity: 0;
}

.app-btn-text {
    opacity: 1;
    transition: opacity 0.3s ease;
}
.m-login-app-btn.expanded {
    width: 100%;
    border-radius: 4px;
    /*border: solid 1px #FFAF34;*/
    box-shadow: 0 0 5px 2px rgba(255, 137, 19, 0.7);
    animation: bounce 0.6s ease-out;
}

.m-login-app-btn.expanded:active {
    background-color: #FFAF34;
    color: #FFFFFF;
}

.app-btn-text {
    opacity: 0;
    transform: translateY(-25px);
    transition: opacity 0.25s ease, transform 0.45s ease;
}

.m-login-app-btn.expanded .app-btn-text {
    opacity: 1;
    transform: translateX(0);
}

@keyframes bounce {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(1.1);
    }
    70% {
        transform: scaleX(0.95);
    }
    100% {
        transform: scaleX(1);
    }
}

.bt-join-btn-A {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 10px;
    transition: 0.3s;
    border: none;
    outline: none;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
}
.bt-join-btn-A:hover {
    opacity: 0.7;
}

.bt-join-btn-B {
    font-family: Pretendard-Regular, sans-serif;
    border-radius: 10px;
    transition: 0.3s;
    border: 1px solid #e0e0e0;
    overflow: hidden;
    /*color: #5b5b5b;*/
    /*display: inline-flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    text-align: center;
    cursor: pointer;
    /*background-color: transparent;*/
}
.bt-join-btn-B:hover {
    background-color: #e0e0e0;
    color: #FFFFFF;
}


.user-detail-container {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.user-detail-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0 15px 0 15px;
    transition: box-shadow 0.3s ease;
}

.user-detail-card:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.user-detail-frame {
    margin-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
    font-family: LINESeedKR-Bd, sans-serif;
}

.detail-item {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
}


