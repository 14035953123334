.m-a-prod-price-detail-background {
    width: 100vw;
    height: 100vh;
    z-index: 60;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
}


.m-a-prod-price-detail-container {
    width: calc(100% - 48px);
    max-height: 80%;
    overflow-y: scroll;
    background: white;
    z-index: 50;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: relative;
    padding-top: 44px;
}


.m-a-prod-price-detail-header {
    width: 100%;
    min-height: 44px;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    position: absolute;
}
.m-a-prod-price-detail-header-new {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-prod-price-detail-header-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /*font-size: 14px;*/
    top: 12px;
}

.m-a-prod-price-detail-header-icon {
    position: absolute;
    width: 30px;
    right: 8px;
    top: 7px;
    opacity: .7;
}

.m-a-prod-price-detail-body {
    width: 100%;
    height: calc(100% - 44px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
}


.m-a-prod-price-detail-table-title {
    width: 100%;
    height: 40px;
    background: var(--background);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: black;
    box-sizing: border-box;
    padding: 0 12px;
}

.m-a-prod-price-detail-table-icon {
    width: 20px;
    opacity: .6;
}

.m-a-prod-price-detail-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: scroll;
    border: 1px solid rgba(0, 0, 0, .1);


}

.m-a-prod-price-detail-table-row {
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-prod-price-detail-table-row.label {
    height: 40px;
    font-size: 12px;
    color: black;
    align-content: center;
}

.m-a-prod-price-detail-table-item {
    width: 100px;
    min-width: 100px;
    height: 36px;
    min-height: 36px;
    /*align-content: center;*/
    padding-top: 9px;
    font-size: 12px;
    text-align: center;
}
.m-a-prod-price-detail-table-item-prodNm {
    width: 100px;
    min-width: 100px;
    height: 36px;
    min-height: 36px;
    /*align-content: center;*/
    padding-top: 9px;
    font-size: 12px;
    text-align: left;
}
.m-a-prod-price-detail-table-item.sm {
    width: 40px;
    min-width: 40px;
}

.m-a-prod-price-detail-table-item.lg {
    width: 200px;
    min-width: 200px;
}

.m-a-prod-price-detail-info-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: scroll;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 12px;
    gap: 8px;

}

.m-a-prod-price-detail-info-icon {
    width: 32px;
    margin-top: 12px;
}

.m-a-prod-price-detail-info-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.m-a-prod-price-detail-info-text {
    font-size: 12px;
}

.m-a-prod-price-detail-info-text.bold {
    font-size: 14px;
    color: black;
}

.m-a-prod-price-detail-info-text.primary {
    color: var(--primary);
}

.prod-price-dt-margin-top {
    margin-top: 12px;
}


.m-a-prod-price-detail-divider {
    width: 100%;
    min-height: 1px;
    background: rgba(0, 0, 0, .1);
}

.m-a-prod-price-detail-divider-bold {
    width: 100%;
    min-height: 4px;
    background: rgba(0, 0, 0, .1);
    margin: 32px 0;
}
