.bookContainer {
    z-index: 21;
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.4);
}

.bookFrame-new-m {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    /*display: flex;*/
    /*justify-content: center;*/
    align-items: center;
    border-radius: 8px;
    width: 420px;
    height: 650px;
}
@media (max-width: 720px) {
    .bookFrame-new-m {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
        height: 75%;
    }
}

.bookFrame-new-img-m {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    /*display: flex;*/
    /*justify-content: center;*/
    align-items: center;
    border-radius: 8px;
    width: 650px;
    height: 550px;
}
@media (max-width: 720px) {
    .bookFrame-new-img-m {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
        height: 75%;
    }
}

.bookFrame {
    position: absolute;
    top: calc(25vh - 100px);
    left: calc(50vw - 200px);
    background-color: white;
    /*display: flex;*/
    /*justify-content: center;*/
    align-items: center;
    border-radius: 8px;
    width: 420px;
    height: 650px;
}
.bookFrameHeader {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.bookFrameBody {
    height: 80%;
    width: 100%;
    display: block;
    background-color: #F2F2F2;
    overflow-y: auto;
    padding: 15px;
}
.headerText {
    font-size: 17px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.headerClose {
    cursor: pointer;
    position: absolute;
    right: 15px;
}
.bookContentOuterCard {
    /*width: 350px;*/
    /*margin: 16px 20px 8px 20px;*/
    /*height: 580px;*/
    height: auto;
}
.bookContentInnerCard {
    height: 55%;
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    background-color: white;

}
.cardName {
    height: 15%;
    margin-bottom: 5px;
}
.cardRecinfo {
    width: 100%;
    /*height: 28px;*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break:break-all;
}
.cardZipAndTelnum {
    width: 100%;
    /*height: 35px;*/
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break:break-all
}
.cardZip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break:break-all
}
.cardTelnum {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break:break-all
}

.cardButtonFrame {
    width: 100%;
    height: 40px;
    display: flex;
}
.defaultDeliMark {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /*font-size: 13px;*/
    height: 40px;
    border-style: solid;
    border-width: thin 1px;
    padding: 0 10px;
    border-radius: 6px;
    /*color: darkorange;*/
    /*font-weight: bold;*/
}
