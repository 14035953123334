.HeartIcon {
    overflow: hidden;
    justify-content: center;
    border-color: blue;
    display: flex;
    float: left;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-left: 70px;
    padding-bottom: 10px;
}
.FinalHeartIcon img{
    border-color: lightcoral;
    height: 100%;
    width: 30px;
    float: left;
}
.HeartClickEmpty {
    /*border-style: solid;*/
    height: 100%;
    width: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}
.HeartCount {
    width: 62px;
    margin-top: 2px;
    margin-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}