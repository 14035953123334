.admin-chat-wrap {
    display: block;
    /*margin: 0.7rem 2.3rem 2.3rem 2.3rem;*/
}
.admin-chat-header {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
    padding: 10px;
    border-bottom : var(--dark-task-border-bottom);
    margin-top: auto;
}
.admin-chat-main {
    display: flex;
    flex-direction: row;
    height: 708px;
}

.admin-chat-user, .admin-chat-ord, .admin-chat-cont-ripple, .chat-textarea, .ord-chat-macro-cont {
    overflow-y: auto;
}
.admin-chat-user::-webkit-scrollbar
, .admin-chat-ord::-webkit-scrollbar
, .admin-chat-cont-ripple::-webkit-scrollbar
, .chat-textarea::-webkit-scrollbar
, .ord-chat-macro-cont::-webkit-scrollbar {
    width: 8px;
}
.admin-chat-user::-webkit-scrollbar-thumb
, .admin-chat-ord::-webkit-scrollbar-thumb
, .admin-chat-cont-ripple::-webkit-scrollbar-thumb
, .chat-textarea::-webkit-scrollbar-thumb
, .ord-chat-macro-cont::-webkit-scrollbar-thumb {
    background-color: rgba(162, 167, 176, 0.8);
    border-radius: 2px;
}
.admin-chat-user::-webkit-scrollbar-thumb:hover
, .admin-chat-ord::-webkit-scrollbar-thumb:hover
, .admin-chat-cont-ripple::-webkit-scrollbar-thumb:hover
, .chat-textarea::-webkit-scrollbar-thumb:hover
, .ord-chat-macro-cont::-webkit-scrollbar-thumb:hover {
    background-color: rgba(133, 133, 133, 0.9);
}
.admin-chat-user {
    width: 540px;
    padding: 0;
}
.admin-chat-ord {
    width: 450px;
    border-left: var(--dark-task-border-left);
    border-right: var(--dark-task-border-right);
}
.admin-chat-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.admin-chat-cont p {
    /*font-size: 14px;*/
}
.admin-chat-cont-ripple {
    display: block;
    padding: 4px;
    height: 670px;
    /*border-bottom: var(--dark-task-border-bottom);*/
}
.admin-chat-cont-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.chat-cont-input {
    display: block;
    width: 82%;
    border-right: var(--dark-task-border-right);
    /*border-bottom: var(--dark-task-border-bottom);*/
}
.chat-textarea {
    display: block;
    width: 100%;
    min-height: 38px;
    max-height: 150px;
    outline: none;
    border: none;
    font-size: 14px;
    resize: none;
    padding-top: 7px;
    padding-left: 7px;
    background-color: #f8f8f8;
}
.chat-textarea::placeholder {color: #d7d7d7}
.chat-enter {
    display: flex;
    width: 18%;
    flex-direction: row;
    justify-content: right;
    margin-top: 4px;
    padding-right: 8px;
}
.chat-enter .chat-event-icon {
    font-size: 30px;
    cursor: pointer;
    margin: 0 6px 0 6px;
}
.chat-enter .chat-event-icon-mark {
    font-size: 25px;
    cursor: pointer;
    margin: 1px 6px 0 6px;
}
.admin-chat-user > aside > ul > li:hover, .admin-chat-ord > ul > li:hover {
    background-color: var(--ord-chat-background-color);
}
.admin-chat-user-rad > aside > ul > li:hover {
    background-color: var(--ord-chat-background-color);
    border-top-left-radius: 7px;
}
.admin-chat-user-rad {
    /*border-left: var(--dark-task-border-left);*/
    border-right: var(--dark-task-border-right);
}
.admin-chat-user > aside > ul > li > div {
    display: flex;
    flex-direction: row;
    height: 75px;
    justify-content: space-around;
}
.admin-chat-profile {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    border-radius: 50%;
    margin-top: 13px;
    margin-left: 9px;
}
.admin-chat-username {
    width: 195px;
    padding-top: 11px;
    /*margin-left: 10px;*/
    padding-Left: 5px;
}
.admin-chat-username > span > b {
    font-size: 13px;
}
.admin-chat-username > p {
    position: relative;
    /*margin-left: 6px;*/
    margin-top: 2px;
    font-size: 12px;
    padding: 3px 8px 3px 3px;
    /*background-color: #ffeeb7;*/
    /*border-radius: 3px;*/
}
.admin-chat-username > p:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    /*border-right-color: #ffeeb7;*/
    border-left: 0;
    border-top: 0;
    margin-top: -8px;
    margin-left: -8px;
}
.admin-chat-count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65px;
    text-align: right;
}
.last-four-digits {
    color: red; /* Change to your preferred color */
}
.admin-chat-ord > ul > li > div {
    display: flex;
    flex-direction: column;
    height: 75px;
}
.admin-chat-ordno {
    font-size: 15px;
    /*margin-top: auto;*/
    padding-top: 24px;
    font-weight: bold;
}

.chat-gear-icon:hover {
    color: #ff7800;
}
.send-btn-air {
    transition: .5s;
}
.send-btn-air:hover {
    animation-duration: 3s;
    animation-name: sendcolor;
    animation-iteration-count: infinite;
    transform: rotate(45deg);
}
@keyframes sendcolor {
    0% { color: #FFAF34; }
    15% { color: var(--contents-color); }
    30% { color: #FFAF34; }
    45% { color: var(--contents-color); }
    60% { color: #FFAF34; }
    75% { color: var(--contents-color); }
    90% { color: #FFAF34; }
    100% { color: var(--contents-color); }
}
.ord-chat-macro-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 44px;
    right: 8px;
    width: 450px;
    height: 580px;
    border: var(--table-color-border);
    box-shadow: 5px 5px 12px -8px #8a8a8a;
    border-radius: 10px;
    background-color: var(--back-black-and-white-nonrgb-background-color);
    z-index: 10;
    /*justify-content: space-around;*/
}
.ord-chat-macro-lang {
    border-bottom: var(--dark-task-border-bottom);
    padding: 10px;
}
.ord-chat-macro-lang select {
    width: 100%;
    height: 2.3rem;
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    padding-left: 7px;
    color: var(--admin-combo-color);
    border: var(--table-color-border);
    background: var(--contents-box-background-color);
}
.ord-chat-macro-lang select:focus {
    border: solid 1px #FFAF34;
}
.ord-chat-macro-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 10px;
    /*margin-top: 10px;*/
}
.chat-macro-cont-dt{
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin: 10px 0 10px 0;
    padding-bottom: 16px;
    border-bottom: var(--dark-task-border-bottom);
}
.admin-chat-rocket-img {
    filter: var(--admin-chat-rocket-img-filter);
}
.chat-badge {
    font-size: 9px;
    font-weight: bold;
    width: 16px;
    height: 16px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: var(--cancel-background-color);
}

.focused {
    background-color: var(--ord-chat-f-background-color);
}
.unfocused {
    background-color: var(--ord-chat-f-background-color);
}


.newDtContentText textarea:focus{
    outline: none !important;
    border:1px solid lightgray !important;
}

.nonUserBt:hover {
    background-color: #F6F6F6;
}
.nonUserBt:focus-within {
    background-color: #EEEEEE;
}

.smallMenu {
    opacity: 0.7;
}
.smallMenu:active {
    opacity: 0.2;
}
.smallMenu:focus {
    outline: none;
}

.macroConBt {
    background-color: white;
    color:dimgrey;
}
.macroConBt:hover {
    background-color: #E2E2E2;
}
.macroConBt:focus-within {
    background-color: #B9B9B9;
    color: white;
}
.periodBt {
    background-color: white;
}
.periodBt:hover {
    background-color: #F6F6F6;
}
.periodBt:focus-within {
background-color: #EEEEEE;
}
