.social-frame {
    border-radius: 8px;
    border: var(--dark-task-border);
    padding: 15px;
}
.social-total-count {
    align-items: center;
    padding-bottom: 2px;
}
.social-gbn {
    height: 4px;
    background-color: rgba(218, 218, 218, 0.6);
}
.social-cont-count {
    padding-top: 10px;
}
.social-cont-count > div > p:not(:last-child) {
    margin-bottom: 10px;
}
.social-cont-count > div > p {
    font-size: 18px;
}
.traffic-chart-frame {

}
.traffic-count-frame {
    border-top: 4px solid rgba(218, 218, 218, 0.6);
    padding-top: 60px;
}
