.m-a-user-notice-pg-container {
    width: 100vw;
    overflow-x: hidden;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 20px 24px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.m-a-user-notice-pg-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}

.m-a-user-notice-pg-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}

.m-a-user-notice-pg-header-icon {
    width: 32px;
}

.m-a-user-notice-pg-header-icon.expand {
    transform: rotate(180deg);
}

.m-a-user-notice-pg-header-right {
    width: 140px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 8px 12px;
    position: relative;
}

.m-a-user-notice-pg-header-select-icon {
    width: 8px;
    opacity: .4;
}

.m-a-user-notice-pg-header-select-list {
    position: absolute;
    top: 40px;
    left: 0;
    width: 140px;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}

.m-a-user-notice-pg-header-select-list-item {
    width: 100%;
    padding: 6px 12px;
}

.m-a-user-notice-pg-list-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-a-user-notice-pg-header-select-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.m-a-user-notice-pg-header-select-list-item {
    background: white;
    width: 200px;
    height: 60px;
    text-align: center;
    align-content: center;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}

.m-a-user-notice-pg-header-select-close {
    width: 200px;
    height: 60px;
    background-color: #e1a420;
    color: white;
    text-align: center;
    align-content: center;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}

.m-a-user-notice-pg-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    border: 1px solid rgba(0, 0, 0, .1);
}

.m-a-user-notice-pg-table-row {
    width: 600px;
    display: flex;
    flex-direction: row;
    height: 40px;
    padding: 11px 0;
}

.m-a-user-notice-pg-table-row-item {
    width: 100px;
    height: 100%;
    align-content: center;
    text-align: center;
    font-size: 12px;

}

.m-a-user-notice-pg-table-row-item.label {
}

.m-a-user-notice-pg-table-row-item.lg {
    width: 200px;
}

.m-a-user-notice-fixed {
    color: var(--primary);
}

.m-a-user-notice-pg-table-row-item.bold {
    opacity: .6;
}

.m-a-user-notice-pg-table-row.label {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-user-notice-pg-divider {
    width: 100%;
    min-height: 1px;
    background-color: rgba(0, 0, 0, .1);
}
