.m-a-find-pw-pg-container {
    box-sizing: border-box;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 20px 24px;
    overflow-y: scroll;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.m-a-find-pw-pg-logo {
    width: 100px;
    margin: 12px 0;
}

.m-a-find-pw-pg-frame {
    box-sizing: border-box;
    padding: 0 24px;
    margin-bottom: 60px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}


.m-a-find-pw-pg-error-text {
    font-size: 12px;
    width: 100%;
    text-align: start;
    color: var(--strong);
    margin-top: -4px;
}

.m-a-find-pw-pg-input-frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
}

.m-a-find-pw-pg-show-icon {
    width: 20px;
    height: 20px;
    opacity: .6;
}

.m-a-find-pw-pg-input {
    width: 100%;
    height: 48px;
    outline: none;
    border: none;
    font-size: 12px;
    border-radius: 4px;
}

.m-a-find-pw-pg-input.border {
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.m-a-find-pw-pg-button {
    width: 100%;
    height: 48px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    font-size: 12px;
    color: white;
}

.m-a-find-pw-pg-loading-bar {
    border: 2px solid white;
    border-top: 2px solid var(--secondary);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

.m-a-find-pw-pg-button.disabled {
    border: none;
    opacity: .5;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
