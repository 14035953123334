.m-a-join-pg-container {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    padding: 0 10% 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 0;
    justify-content: center;
    overflow: hidden;
}

.m-a-join-pg-logo {
    width: 135px;
    /*margin: 12px 0;*/
}

.m-a-join-pg-email-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-join-pg-email-address-box {
    width: 50%;
    height: 45px;
    display: flex;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: transparent;


&:focus {
     box-shadow: 0 0 0 1px var(--primary);
     border: none;
 }
}

.m-a-join-pg-email-address-text {
    font-size: 12px;
}

.m-a-join-pg-email-address-expand-icon {
    width: 10px;
    height: 8px;
    opacity: .4;
}

.m-a-join-pg-email-address-list {
    width: 100%;
    height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 45px;
    left: 0;
    background-color: white;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
    z-index: 30;
}

.m-a-join-pg-email-address-list-item {
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    font-size: 12px;
    text-align: start;

&:hover {
     background-color: var(--primary);
 }
}

.m-a-join-pg-email-address-list-item.selected {
    background-color: var(--primary);
}

.m-a-join-pg-email-address-switch-icon {
    width: 8px;
    height: 8px;
}

.m-a-join-pg-input-frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
}


.m-a-join-pg-show-icon {
    width: 20px;
    height: 20px;
    opacity: .6;
}

.m-a-join-pg-email-input {
    outline: none;
    border: none;
    font-size: 12px;
    border-radius: 4px;
}
.m-a-join-pg-input {
    width: 100%;
    height: 45px;
    outline: none;
    border: none;
    font-size: 12px;
    border-radius: 4px;
}

.m-a-join-pg-input::placeholder {
    font-size: 12px;
}

.m-a-join-pg-error {
    width: 100%;
    text-align: start;
    color: var(--red);
    font-size: 12px;
}

.border {
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.half {
    width: 50%;
    height: 45px;
}

.m-a-join-pg-button {
    width: 100%;
    height: 45px;
    background-color: #ff8913;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 20px;
    border: none;
    font-size: 13px;
    color: white;
}

.m-a-join-pg-button.disabled {
    border: none;
    opacity: .5;
}

.m-a-join-pg-cert-frame {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-join-pg-cert-input-box {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 0;
}

.m-a-join-pg-cert-input {
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
    padding: 0 12px;
    font-size: 12px;

&:focus {
     outline: 1px solid var(--primary);
 }
}

.m-a-join-pg-cert-valid-time {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 12px;
    white-space: nowrap;
}

.m-a-join-pg-cert-submit-button {
    font-size: 12px;
    background-color: #ff8913;
    color: white;
    width: 120px;
    height: 45px;
    align-content: center;
    text-align: center;
    border-radius: 4px;
}

.m-a-join-pg-language-frame {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 4px;
}

.m-a-join-pg-language {
    font-size: 12px;
}

.m-a-join-pg-select-icon {
    width: 10px;
    height: 8px;
    opacity: .4;
}

.m-a-join-pg-select-icon.reversed {
    transform: rotate(180deg);
}

.m-a-join-pg-language-select-input {
    flex: 1;
    height: 45px;
    border: none;
    outline: none;
    font-size: 12px;
}

.m-a-join-pg-language-select-switch-icon {
    width: 11px;
    height: 11px;
}

.m-a-join-pg-language-select {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 45px;
    left: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    z-index: 30;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
    /*border-radius: 4px;*/
    overflow-y: auto;
}

.m-a-join-pg-language-select-item {
    width: 100%;
    box-sizing: border-box;
    padding: 14px 24px;
    font-size: 12px;
}

.m-a-join-pg-language-select-item.selected {
    background-color: var(--primary);
    color: white;
}

.m-a-join-pg-agreement-frame {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
}


.m-a-join-pg-agreement-checkbox {
    width: 32px;
    background: red;
}

.m-a-join-pg-checkbox-documented {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-join-pg-checkbox-frame {
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-join-pg-checkbox {
    width: 20px;
    height: 20px;
}

.m-a-join-pg-checkbox-text {
    font-size: 12px;
}

.m-a-join-pg-checkbox-text.mandatory {
    display: inline-block;
    color: var(--primary);
    margin-left: 4px;
}

.m-a-join-pg-checkbox-text.optional {
    display: inline-block;
    color: var(--text);
    margin-left: 4px;
}

.m-a-join-pg-document-icon {
    width: 20px;
    height: 20px;
    opacity: .6;
}

.m-a-join-pg-aggrement-desc {
    font-size: 12px;
    color: var(--primary);
}

.m-a-join-pg-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin: 8px 0;
}

.m-a-join-pg-terms-frame {
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 4px;
}


.m-a-join-pg-terms {
    font-size: 12px;
    line-height: 1.2;
}
