.BannerContainer .swiper-pagination {
    display: block;
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 1002;
}

.BannerContainer .swiper-pagination-bullets {
    background: none;
    top: 610px;
    width: 100%;
}
.BannerContainer .swiper-pagination-bullets-dynamic {
    overflow: hidden;
}
.BannerContainer .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(0.43);
    position: relative;
}
.BannerContainer .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1);
}
.BannerContainer .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1);
}
.BannerContainer .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
}
.BannerContainer .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.43);
}
.BannerContainer .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
}
.BannerContainer .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.43);
}
.BannerContainer .swiper-pagination-bullet {
    width: 23px;
    height: 10px;
    display: inline-block;
    border-radius: 8px;
    /*border-radius: 50%;*/
    background: rgba(100, 100, 100, 0.45);
}
.BannerContainer .swiper-pagination-bullet-active {
    background-color: #e5e5e5;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
}
.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}
.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform,
    200ms left;
}
.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform,
    200ms right;
}

.BannerContainer {
    overflow: hidden;
    width: 100%;
    height: 650px;
    /*aspect-ratio: 16 / 9;*/
}
.BannerContainer .swiper-button-prev, .BannerContainer .swiper-button-next {
    position: absolute;
    top: 55%;
    transform: translateY(-45%);
    width: 35px !important;
    height: 43px !important;
    background-color: rgba(100, 100, 100, 0.35);
    border-radius: 8px;
    color: #e0e0e0 !important;
    z-index: 10;
    border: none;
}

.BannerContainer .swiper-button-next:hover, .BannerContainer .swiper-button-prev:hover {
    background-color: rgba(100, 100, 100, 0.5);
    color: #ffffff !important;
}
.BannerContainer .swiper-button-prev:after, .BannerContainer .swiper-button-next:after {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
}

/*여기서부터 메인배너 행 섹션*/
/*.BannerContainer img {*/
/*    border-radius: 6px;*/
/*    width: 1280px;*/
/*    height: 600px;*/
/*}*/
.MainHeatherContainer {
    display: flex;
    align-items: center;
    height: 90px;
}
.NoticeAndFaqContainer {
    border: solid 1px #EAEAEA;
    border-radius: 4px;
    min-height: 80px;
    padding: 20px 30px;
}
.NoticeAndFaqTextContainer {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.NoticeAndFaqText {
    margin: 0;
    font-weight: lighter;
    color: #2c2c2c;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}
.NoticeAndFaqNewText {
    margin: 0 0 0 5px;
    color: orangered;
    font-size: 12px;
}
.NoticeAndFaqBt {
    width: 60px;
    height: 30px;
    outline: none;
    border: solid;
    border-width: thin 1px;
    border-color: #EAEAEA;
    border-radius: 4px;
}
/*여기서부터 메인 포토리뷰 섹션*/
.ReviewMainTitle1 {
    height: 45px;
    display: flex;
    justify-content : center;
    text-transform: uppercase;
    background-image: linear-gradient(
            -225deg,
            lightblue 0%,
            blueviolet 29%,
            pink 67%,
            yellowgreen 100%
    );
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    font-size: 30px;
}
@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
.ReviewMainTitle2 {
    display: flex;
    justify-content : center;
    border-top: 4px double #7689fd;
    margin: 15px;
}
.ReviewMainTitle2:after {
    content: '\002665';
    display: inline-block;
    position: relative;
    top: -15px;
    padding: 0 10px;
    background: white;
    color: lightcoral;
    font-size: 18px;
}
.MainReviewButtonContainer {
    height: 90px;
    text-align: center;
    margin-bottom: 15px;
}
.MoreButton {
    text-align: center;
    margin-top: 20px;
}
/*여기까지 메인 포토리뷰 섹션*/


/*여기서부터 나머지는 전부 메인 배송대행 물류센터 섹션*/
/*배송대행 물류센터 카드 시작*/
.CenterAddressCard {
    height: 625px;
    width: 48%;
    background-color: #FFF9EF;
    padding: 40px;
    /*margin-right: 20px;*/
    /*float: left;*/
}
.CenterImage {
    text-align: center;
    height: 200px;
    overflow: hidden;
    size: 200px;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
}
.DeliveryCenterTitle {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 5px;
}
/*배송대행 물류센터 카드 종료*/

/*배송 조회 카드 시작*/
.ParcelLookupCard {
    height: 625px;
    width: 48%;
    background-color: #FFF9EF;
    padding: 40px;
    margin-left: 20px;
    float: right;
}
.ParcelImage {
    text-align: center;
    height: 200px;
    overflow: hidden;
    size: 200px;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
}
.ParcelTitle {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 5px;
}
.ParcelText {
    margin-top: 18px;
}
.ParcelForm {
    /*height: 150px;*/
    background: white;
    margin-top: 40px;
    padding: 20px;
}
.LookupOverseasTitle {
    height: 30px;
    margin-left: 5px;
    font-size: 16px;
}
.LookupOverseasForm {
    height: 40px;
    display: flex;
}
.OverseasDeliverySelect {
    width: 149px;
    margin-right: 2px;
}
.TrackingInputForm {
    border: 1px solid lightgrey;
    display: flex;
    width: 316px;
    margin-left: 4px;
    border-radius: 5px;
}
.TrackingInput {
    margin-left: 2px;
    width: 310px;
    display: flex;
}
.TrackingInput input {
    width: 314px;
    border: none;
    outline: none;
}
.OverseasSearchIconContainer {
    position: absolute;
    margin-left: 280px;
}
.SearchIconContainer {
    width: 20%;
    height: 100%;
    margin-left: 20px;
}
.OverseasSearchIcon {
    display: flex;
    justify-content: center;
    overflow: hidden;
    font-size: 30px;
    align-items: center;
    margin-top: 6px;
    float: right;
}
.OverseasSearchIconContainer a:hover{
    color: #ff6200;
}
.LookupDomesticTitle {
    height: 30px;
    margin: 8px 0 0 5px;
    font-size: 16px;
}
.LookupDomesticForm {
    height: 40px;
    display: flex;
}
.DomesticDeliverySelect {
    width: 150px;
    margin-right: 2px;
}
.DomesticSearchIconContainer {
    width: 20%;
    height: 100%;
    margin-left: 20px;
}
.DomesticSearchIcon {
    display: flex;
    justify-content: center;
    overflow: hidden;
    font-size: 30px;
    align-items: center;
    margin-top: 6px;
    float: right;
}
.DomesticSearchIconContainer a:hover{
    color: #ff6200;
}

/*배송 조회 카드 종료*/

/* 결제수단 카드 시작*/

.CenterAddrbtn {
    width: 130px;
    height: 45px;
    /*border: solid 1px rgba(255, 120, 0, 0.78);*/
    border: none;
    border-radius: 7px;
    /*color: rgba(255, 120, 0, 0.78);*/
    color: white;
    font-weight: bold;
    background-color: #FFAF34;
}

.CenterAddrbtn:hover {
    background-color: #FF9924;
    color: white;
    transition: 0.3s;
}
/*.CenterAddrbtn:focus {*/
/*    background-color: rgba(255, 120, 0, 0.78);*/
/*    color: white;*/
/*    --bs-btn-focus-shadow-rgb: 255, 120, 88*/
/*}*/

.PayTypeCard {
    height: 625px;
    width: 48%;
    background-color: #FFF9EF;
    padding: 40px;
    margin-right: 20px;
    float: left;
    margin-top: 90px;
}
.PayTypeImgContainer {
    height: 220px;
    background-color: white;
    border-radius: 10px;
}
.PayTypeTitle {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 5px;
}
.PayTypeInformation {
    margin-top: 18px;
}
.PayTypeImage {
    height: 49.9%;
    width: 20%;
    float: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 30px;
}
.StripeTypeImage {
    height: 49.9%;
    width: 20%;
    float: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 40px 0;
}

/*결제수단 카드 종료*/

/*배송사종류 카드 시작*/
.DeliveryTypeCard {
    height: 625px;
    width: 48%;
    background-color: #FFF9EF;
    padding: 40px;
    margin-left: 20px;
    float: right;
    margin-top: 90px;
}
.DeliveryImgContainer {
    height: 220px;
    background-color: white;
    border-radius: 10px;
}
.FedexLogoImage {
    height: 49.9%;
    width: 33.3%;
    float: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 35px;
}
.SmallPacketLogoImage {
    height: 49.9%;
    width: 33.3%;
    float: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 20px;
}
.EmsLogoImage {
    height: 49.9%;
    width: 33.3%;
    float: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 35px;
}
.UpsLogoImage {
    height: 49.9%;
    width: 33.3%;
    float: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 20px 10px;
}
.SeaLogoImage {
    height: 49.9%;
    width: 33.3%;
    float: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 20px;
}
.KpacketLogoImage {
    height: 49.9%;
    width: 33.3%;
    float: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 10px;
}
.DeliveryTitle {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 5px;
}

/* 섹션 공지사항 아코디언 */
.noticeAccordion {
    border: 1px solid;
    border-radius: 4px;
    margin: 16px 0;
}
.noticeAccordion-header {
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    border-style: solid;
    border-width: thin 1px;
    border-color: #FFAF34;
    border-radius: 4px;
    color: darkorange;
}
.noticeAccordion-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background-image: url('https://cdn-icons-png.flaticon.com/128/4563/4563982.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.4s;
}
.noticeAccordion-header.expanded .noticeAccordion-icon {
    transform: rotate(360deg);
}
.noticeAccordion-body {
    display: none;
    border-style: solid;
    border-top: none;
    border-width: thin 1px;
    border-color: lightgrey;
    border-radius: 4px;
}
.noticeAccordion-body.expanded {
    display: block;
}

/* 섹션 FAQ 아코디언 */
.faqAccordion {
    border: 1px solid;
    border-radius: 4px;
    margin: 16px 0;
}
.faqAccordion-header {
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    border-style: solid;
    border-width: thin 1px;
    border-color: #FFAF34;
    border-radius: 4px;
    color: darkorange;
}
.faqAccordion-icon {
    width: 21px;
    height: 21px;
    margin-right: 8px;
    background-image: url('https://cdn-icons-png.flaticon.com/128/9149/9149364.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.4s;
}
.faqAccordion-header.expanded .faqAccordion-icon {
    transform: rotate(360deg);
}
.faqAccordion-body {
    display: none;
    border-style: solid;
    border-top: none;
    border-width: thin 1px;
    border-color: lightgrey;
    border-radius: 4px;
}
.faqAccordion-body.expanded {
    display: block;
}

* {
    padding: 0;
    margin: 0;
}

.wrapper .slide_container {
    overflow: hidden;
    width: 1280px; /* 배너의 폭 */
    margin: auto;
}

.wrapper .slide_container .slide {
    display: flex;
    align-items: center;
    position: relative;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 15s; /* 슬라이드 속도 */
}

.wrapper .slide_container .slide.original {
    animation-name: slideOriginal;
}

.wrapper .slide_container .slide.clone {
    animation-name: slideClone;
}

.wrapper .slide_container .slide.stop {
    animation-play-state: paused;
}

.wrapper .slide_container .slide .bannerImage {
    width: 1280px;
    height: 600px;
}

@keyframes slideOriginal {
    0% { transform: translateX(0%); }
    50% { transform: translateX(-100%); }
    50.1% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
}

@keyframes slideClone {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-200%); }
}

.bnLeftImg {
    opacity: 0.3;
}
.bnLeftImg:hover {
    opacity: 0.5;
}
.bnRightImg {
    opacity: 0.3;
}
.bnRightImg:hover {
    opacity: 0.5;
}

.userGuideContFrame {
    border-top: solid 1px #EAEAEA;
    border-bottom: solid 1px #EAEAEA;
    /*border-radius: 4px;*/
    min-height: 100px;
}

