.m-a-non-user-chat-pg-container {
    display: flex;
    flex-direction: column;
    /*height: 100vh;*/
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
}

.m-a-chat-header {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    padding: 12px;
    background-color: var(--chat-header);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-chat-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.m-a-non-user-chat-header-text {
    font-size: 14px;
    font-weight: 600;
    color: darkorange;
}

.m-a-chat-header-icon {
    width: 24px;
    height: 24px;
}

.m-a-chat-header-icon-close {
    width: 35px;
    height: 35px;
    opacity: 0.6;
}

.m-a-non-user-chat-message-frame {
    width: 100%;
}

.m-a-non-user-chat-body-container {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    background-color: #FFF5E4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.m-a-non-user-chat-footer-container {
    background-color: #F6F6F6;
    border-radius: 4px;
    padding: 10px;
}

.m-a-non-user-chat-default-action {
    width: 160px;
    margin-top: 20px;
    background-color: var(--background);
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 4px;
}


.m-a-non-user-chat-default-detail-header {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}


.m-a-non-user-chat-default-detail-back {
    width: 12px;
}

.m-a-non-user-chat-default-text {
    width: 150px;
    height: 30px;
    background-color: white;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    align-content: center;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}

.m-a-non-user-chat-date {
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: black;
    margin: 12px 0;
}

.m-a-non-chat-user-chat-frame {
    width: 100%;
}

.m-a-non-chat-user-chat-frame.consultant {
}

.m-a-non-user-chat-user-text-frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.m-a-non-user-chat-consultant-profile-img {
    width: 32px;
}

.m-a-non-user-chat-user-text-box {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;
    margin-bottom: 8px;
}

.m-a-non-user-chat-unread {
    font-size: 12px;
    font-weight: 600;
    color: var(--primary);
}

.m-a-non-user-chat-photo {
    width: 60%;
    max-width: 300px;
}

.m-a-non-user-chat-file-frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--primary);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px;
    gap: 12px;
}


.m-a-non-user-chat-file-icon {
    width: 32px;
}

.m-a-non-user-chat-file-name {
    font-size: 16px;
    font-weight: 500;
    color: white;
}


.m-a-non-user-chat-expired {
    width: 48px;
    margin-right: 12px;
    opacity: .6;
}

.m-a-non-user-chat-message-expired-message {
    font-size: 12px;
    margin-right: 12px;
    opacity: .8;
}

.m-a-non-user-chat-user-text-box.consultant {
    align-items: flex-end;
    position: relative;
}


.m-a-non-user-chat-consultant-text-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 4px;
    margin-bottom: 12px;
}


.m-a-non-user-chat-consultant-profile {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.m-a-non-user-chat-message {
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    color: black;
    font-size: 14px;
}

.m-a-non-user-chat-time {
    font-size: 12px;
    font-weight: 400;
}

.m-a-non-user-chat-input-frame {
    position: fixed;
    width: 100vw;
    height: 60px;
    bottom: 0;
    left: 0;
    background-color: var(--background);
    box-sizing: border-box;
    padding: 8px;
    z-index: 30;
}


.m-a-non-user-chat-input-box {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0 8px;
    gap: 8px;
}


.m-a-non-user-chat-input-icon {
    width: 24px;
    height: 24px;
}

.m-a-non-user-chat-input {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding: 0 4px;
    border: none;
    outline: none;
    font-size: 14px;

&:focus {
     outline: none;
 }
}

.m-a-chat-img-pop-up {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.m-a-chat-img-pop-up-photo {
    width: 100%;
}

.m-a-chat-img-pop-up-file-name {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin: 8px 0;
    font-weight: 500;
    color: var(--text);
}

.m-a-chat-img-pop-up-download {
    width: 36px;
    height: 36px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}


.m-a-chat-img-pop-up-download-icon {
    width: 24px;
    height: 24px;
}

.m-a-chat-send-img-pop-up {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -12px;
    gap: 20px;
}


.m-a-chat-send-img-pop-up-frame {
    width: 100%;
    padding: 20px;
    background-color: var(--chat-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}


.m-a-chat-send-img-pop-up-photo {
    width: 80%;
}

.m-a-chat-send-img-pop-up-filename {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary);
}

.m-a-chat-send-img-pop-up-button {
    padding: 12px 40px;
    background-color: var(--primary);
    border-radius: 4px;
    color: white;
    font-size: 14px;
}

.m-chat-data-deadline-notice-container {
    padding: 20px 20px 0 20px;
}
.m-chat-data-deadline-notice-border-frame {
    padding: 2px;
    border-radius: 10px;
    opacity: 0.8;
    background: linear-gradient(to right, orange 33.33%, yellow 66.66%, green 100%);
    position: relative;
}
.m-chat-data-deadline-notice-message-frame {
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    position: relative;
    text-align: center;
    z-index: 1;
}
