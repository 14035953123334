.reviewImageCard {
    float: left;
    width: 33.3333%;
    height: 350px;
    padding: 0 10px;
}
.ReviewImageCard a {
    color: black;
}
.ReviewTitle:hover {
    color: #505bf0;
}
.reviewImage {
    overflow: hidden;
    display: flex;
    justify-content: center;
    opacity: 1;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    border-radius: 12px;
}
.reviewImage:hover {
    opacity: 0.7;
    transform: scale(1.01);
}
.ReviewTitleCon {
    /*height: 40px;*/
    vertical-align: middle;
}
.ReviewTitle {
    float: left;
    width: 80%;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
}
.NewIconBox {
    float: right;
    width: 20%;
    height: 80%;
}
.NewIcon {
    text-align: center;
    background-color: #eef3fd;
    border: #7689fd solid 1px;
    border-radius: 5px;
    color: #505bf0;
    font-size: 5px;
    width: 30px;
    margin-left: 40px;
    margin-top: 8px;
    position: relative;
    animation-name: ActionIcon;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
@keyframes ActionIcon {
    0% {right: 3px;}
    50% {right: 10px;}
    100% {right: 3px;}
}
.ReviewTimeCon {
    height: 40px;
}
.ReviewTime {
    float: left;
    width: 50%;
    font-size: 14px;
}
.HeartIconContainer {
    float: right;
    width: 50%;
    text-align: center;
    height: 100%;
}
