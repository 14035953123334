.m-a-user-my-pg-container {
    box-sizing: border-box;
    max-width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 24px 20px 24px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}


.m-a-user-my-page-user-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
}

.m-a-user-my-page-user-profile-img-box {
    width: 60px;
    height: 60px;
    position: relative;
}

.m-a-user-my-page-profile-unread-cnt {
    position: absolute;
    background: var(--primary);
    color: white;
    padding: 0 4px;
    font-size: 12px;
    border-radius: 4px;
    top: 0;
    right: 0;
}

.m-a-user-my-page-profile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.m-a-user-my-page-user-info-frame {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.m-a-user-my-page-user-info-name {
    font-size: 16px;
    color: black;
}

.m-a-user-my-page-user-info-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin: 8px 0;
}

.m-a-user-my-page-user-info-button-text {
    font-size: 13px;
    cursor: pointer;
}

.m-a-user-my-page-user-info-vertical-divider {
    width: 1px;
    height: 16px;
    background-color: rgba(0, 0, 0, .4);
}

.m-a-user-my-page-user-info-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
}

.m-a-user-my-page-user-info-row-label {
    font-size: 12px;
}

.m-a-user-my-page-user-info-row-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 12px;
}

.m-a-user-my-page-user-info-row-value-strong {
    display: inline-block;
    color: var(--primary);
}


.m-a-user-my-page-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-my-page-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-user-my-page-sub-title-text {
    font-size: 14px;
}

.m-a-user-my-page-order-status-frame {
    width: 100%;
    /*height: auto;*/
    min-height: 380px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.m-a-user-my-page-order-status {
    box-sizing: border-box;
    padding: 12px;
    height: 360px;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, .1);
    gap: 8px;
}

.m-a-user-my-page-order-icon {
    width: 60px;
    height: 60px;
    opacity: .7;
}

.m-a-user-my-page-order-title {
    font-size: 14px;
}

.m-a-user-my-page-order-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-user-my-page-order-row-label {
    font-size: 13px;
    color: #5b5b5b;
}

.m-a-user-my-page-order-row-value {
    font-size: 13px;
    /*color: var(--primary);*/
}

.m-a-user-my-page-process-icon {
    width: 20px;
    height: 20px;
    margin-top: 100px;
    opacity: .2;
}

.m-a-user-my-page-order-sheet-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.m-a-user-my-page-order-sheet {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.m-a-user-my-page-order-sheet-status {
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .05);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #7e9cc9;
}

.m-a-user-my-page-order-sheet-item {
    display: flex;
    flex-direction: row;
    /*gap: 4px;*/
    font-size: 12px;
    /*margin-top: -4px;*/
}

.m-a-user-my-page-order-sheet-receipt {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    position: relative;
}

.m-a-user-my-page-order-sheet-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-user-my-page-order-sheet-row-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.m-a-user-my-page-order-sheet-row-title {
    font-size: 14px;
    color: black;
}


.m-a-user-my-page-order-receipt-label {
    font-size: 12px;
}

.m-a-user-my-page-order-receipt-value {
    font-size: 13px;
    color: black;
}

.m-a-user-my-page-order-receipt-value.primary {
    color: var(--primary);
}

.m-a-user-my-page-order-sheet-row-icon {
    width: 16px;
    height: 16px;
}

.m-a-user-my-page-order-sheet-row-icon.lg {
    width: 28px;
    height: 28px;
}

.m-a-user-my-page-order-sheet-text {
    font-size: 12px;
    color: black;
}

.m-a-user-my-page-guide-popup {
    position: absolute;
    width: 80%;
    right: 20px;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 12px;
    gap: 12px;
}

.m-a-user-my-page-guide-popup-header {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.m-a-user-my-page-guide-popup-close {
    position: absolute;
    width: 20px;
    top: 0;
    right: 0;
}

.m-a-user-my-page-guide-popup-content {
    font-size: 12px;
    line-height: 1.4;
}

.m-a-user-my-page-order-sheet-filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.m-a-user-my-page-order-sheet-filter-button {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    font-size: 12px;
    position: relative;
}

.m-a-user-my-page-order-sheet-filter-icon {
    width: 8px;
    opacity: .4;
}

.full {
    width: 100%;
}

.m-a-user-my-page-order-sheet-filter-list {
    width: 100%;
    max-height: 180px;
    overflow-y: scroll;
    position: absolute;
    top: 40px;
    left: 0;
    background: white;
    z-index: 30;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}

.m-a-user-my-page-order-sheet-filter-item {
    width: 100%;
    padding: 8px 12px;
    font-size: 12px;
}

.m-a-user-my-page-order-sheet-keyword {
    flex: 1;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 12px;

&:focus {
     border: 1px solid var(--primary);
 }
}

.m-a-user-my-page-order-sheet-search {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-my-page-payment-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.m-a-user-my-page-payment-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.m-a-user-my-page-payment-box-item {
    width: 50%;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    align-content: center;
    font-size: 14px;
}

.m-a-user-my-page-payment-table-container {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.m-a-user-my-page-payment-table {
    min-width: 1150px;
    height: 40px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.m-a-user-my-page-payment-table-item {
    grid-column: span 1;
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.m-a-user-my-page-payment-history-forPrice-container {
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.m-a-user-my-page-payment-history-forPrice-text {
    color: darkorange;
    font-weight: bold;
}

.m-a-user-my-pg-button {
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}

/*.label {*/
/*    background-color: var(--background);*/
/*    font-weight: 400;*/
/*}*/


.m-a-user-my-page-usage-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.m-a-user-my-page-usage-period {
    width: 100%;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    align-content: center;
    font-size: 14px;
}

.m-a-user-my-page-usage-sub-title {
    width: 100%;
    text-align: start;
    font-size: 14px;
    color: black;
}

.m-a-user-my-page-usage-table-frame {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .1);
}

.m-a-user-my-page-usage-table {
    min-width: 700px;
    height: 32px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
}

.m-a-user-my-page-usage-table-item {
    font-size: 11px;
    grid-column: span 2;
    align-content: center;
    text-align: center;
}

.m-a-user-my-page-usage-table-item-col-3 {
    font-size: 11px;
    grid-column: span 3;
    align-content: center;
    text-align: center;
}

.m-a-user-my-page-usage-table.label {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.m-a-user-my-page-usage-table.label.m-a-user-my-page-usage-table-item {
    color: black;
}


.m-a-user-my-pg-col-6 {
    grid-template-columns: repeat(6, 1fr);
}

.m-a-user-my-pg-coupon {
    min-width: 400px;
}

.m-a-user-my-page-no-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 12px;
    font-size: 14px;
    color: var(--pale);

}

.m-a-user-my-page-no-data-img {
    width: 60px;
    opacity: .3;
}


.m-a-user-my-page-divider {
    width: 100%;
    min-height: 1px;
    background-color: var(--background);
}

.m-a-user-my-page-divider-bold {
    /*width: 100%;*/
    width: 100vw;
    min-height: 4px;
    background-color: var(--background);
    margin-bottom: 20px;
}

.bold {
    width: 100vw;
    height: 4px;
    margin-bottom: 20px;
}

.m-a-user-my-pg-margin {
    margin: 20px 0;
}

.m-a-user-my-page-total-payment {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.m-a-total-payment-table-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
}
