.m-a-user-review-pg-container {
    box-sizing: border-box;
    padding: 0 24px 20px 24px;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.m-a-user-review-pg-sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.m-a-user-review-pg-sub-title-icon {
    width: 20px;
    height: 20px;
}

.m-a-user-review-pg-sub-title-text {
    font-size: 15px;
}

.m-a-user-review-pg-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
    margin-bottom: 12px;
}

.m-a-user-review-pg-top-frame {
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.m-a-user-review-pg-top-input {
    flex: 1;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 12px;

&:focus {
     border: 1px solid var(--primary);
 }
}

.m-a-user-review-pg-top-input::placeholder {
    font-size: 13px;
}

.m-a-user-review-pg-top-button {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 24px;
    background-color: var(--primary);
    border-radius: 4px;
    color: white;
    font-size: 15px;
}

.m-a-user-review-pg-review-frame {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 20px;
}

.m-a-user-review-pg-review-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.m-a-user-review-pg-review-img {
    width: 100%;
    min-height: 150px;
    max-height: 300px;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
}

.m-a-user-review-pg-review-sub {
    font-size: 11px;
}

.m-a-user-review-pg-review-title {
    font-size: 13px;
    color: black;
    margin-top: -8px;
    margin-bottom: -4px;
}

.m-a-user-review-pg-review-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.m-a-user-review-pg-review-date {
    font-size: 11px;
    color: var(--pale);
}

.m-a-user-review-pg-review-new {
    font-size: 12px;
    color: var(--primary);
}

.m-a-user-review-pg-more {
    font-size: 12px;
    color: var(--primary);
    padding: 6px 20px;
    border: 1px solid var(--primary);
    border-radius: 4px;
    cursor: pointer;
    margin-top: 12px;
}
