.footerContactBt {
    width: 100px;
    height: 45px;
    background-color: #E2E2E2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.footerContactBt p {
    color: #A5A5A5;
    font-size: 13px;
    margin: 0;
}
.footerContactBt:hover {
    background-color: darkgrey;
    color: white;
}
.footerContactBt:hover p {
    color: white;
}
.footerContactBt:focus-within {
    background-color: dimgray;
}

.footerContactBt:focus-within p {
    color: white;
}
.footerCenterMenu {
    width: 100%;
    cursor: pointer;
}
.footerCenterMenu:hover {
    transition: 0.6s;
    color: black;
}
.footer-sns-icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.5;
}
.footer-sns-icon:hover {
    opacity: 1;
}
.footer-sns-icon:active {transform: scale(0.9);}
