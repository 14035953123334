.login-main {
    width: 100%;
    margin: 0 auto;
    /*background: url("../assets/img/top_bg.png") no-repeat;!*center center no-repeat;*!*/
    /*background-size: cover;*/
}
.login-sub {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
}
.wrap-login100 {
    width: 500px;
    /*background-color: #fff;*/
    border-radius: 5px;
    overflow: hidden;
}
/*---------[ Form ]*/
.login100-form {
    width: 100%;
}
.login100-form-title {
    display: block;
    font-size: 35px;
    font-weight: bold;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 70px;
}
.join-reset {
    display: flex;
    margin: 30px 0 150px 0;
    justify-content: center;
}
/*-------------[ Input ]*/
.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #d9d9d9;
}
.label-input100 {
    font-size: 14px;
    color: #333333;
    line-height: 1.5;
    padding-left: 7px;
}
.input100 {
    font-size: 15px;
    color: #333333;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 55px;
    background: transparent;
    padding: 0 7px 0 43px;
}

/*---------------------------------------------*/
.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}
.focus-input100::after {
    content: attr(data-symbol);
    color: #adadad;
    font-size: 22px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: calc(100% - 20px);
    bottom: 0;
    left: 0;
    padding-left: 13px;
    padding-top: 3px;
}
.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: #ffc89c;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.input100:focus + .focus-input100::before {
    width: 100%;
}

.has-val.input100 + .focus-input100::before {
    width: 100%;
}

.input100:focus + .focus-input100::after {
    color: #76bcfe;
}

.has-val.input100 + .focus-input100::after {
    color: #76bcfe;
}

.ad-join-i-con {
    position: relative;
    font-size: 28px;
    margin: 12px 0 0 6px;
}



.login-sub-form-btn {
    margin-top: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 7px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.login100-form-bg-btn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #FFAF34;
    background: -webkit-linear-gradient(right, #FFAF34, #ffc573, #FFAF34, #ffc573);
    /*background: -o-linear-gradient(right, #f5cbd5, #e3d3ec, #f5cbd5, #e3d3ec);*/
    /*background: -moz-linear-gradient(right, #f5cbd5, #e3d3ec, #f5cbd5, #e3d3ec);*/
    /*background: linear-gradient(right, #f5cbd5, #e3d3ec, #f5cbd5, #e3d3ec);*/
    top: 0;
    left: -100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
}

.wrap-login100-form-btn:hover .login100-form-bg-btn {
    left: 0;
}

.ad-login-btn {
    position: relative;
    color: white;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    width: 100%;
    height: 50px;
    text-align: center;
    background-color: var(--btorage-background-color);
    overflow: hidden;
}
.ad-login-btn:after {
    position: absolute;
    content: '';
    top: calc(50% - 50px);
    left: -50px;
    width: 60px;
    height: 100px;
    background-color: white;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
}
.ad-login-btn:hover:after {
    background-color: rgba(255, 255, 255, 0.7);
    left: calc(100% + 50px);
    opacity: 1;
}
